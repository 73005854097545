import React, { useEffect, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

const ExecutionPriceCheckboxes = ({ aaChecked, bbChecked, spChecked, handleExecutionPriceBoxChange }) => {
    const [aaState, setAAState] = useState(aaChecked);
    const [bbState, setBBState] = useState(bbChecked);
    const [spState, setSPState] = useState(spChecked);

    const handleAAChange = (isChecked) => {
        setAAState(isChecked);
        handleExecutionPriceBoxChange(isChecked, 'aaCheckBox');
    };

    const handleBBChange = (isChecked) => {
        setBBState(isChecked);
        handleExecutionPriceBoxChange(isChecked, 'bbCheckBox');
    };

    const handleSPChange = (isChecked) => {
        setSPState(isChecked);
        handleExecutionPriceBoxChange(isChecked, 'spCheckBox');
    };

    useEffect(() => {
        setAAState(aaChecked);
    }, [aaChecked]);

    useEffect(() => {
        setBBState(bbChecked);
    }, [bbChecked]);

    useEffect(() => {
        setSPState(spChecked);
    }, [spChecked]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={aaState}
                        onCheckedChange={handleAAChange}
                        style={{
                            backgroundColor: aaState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{color: 'white', width: '8px', height: '8px'}} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.875rem' }}>Ask</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={bbState}
                        onCheckedChange={handleBBChange}
                        style={{
                            backgroundColor: bbState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{color: 'white', width: '8px', height: '8px'}} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.875rem' }}>Bid</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox.Root
                        checked={spState}
                        onCheckedChange={handleSPChange}
                        style={{
                            backgroundColor: spState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{color: 'white', width: '8px', height: '8px'}} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>Mid</label>
                </div>
            </div>
        </div>
    );
};

export default ExecutionPriceCheckboxes;