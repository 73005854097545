import React from "react";
import { Helmet } from "react-helmet";

const ReadingUnusualOptionsFlow = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Helmet>
                <title>Sweeps, Blocks and Splits - Knowing the Difference</title>
                <meta name="description" content="Sweeps, Blocks and Splits - Knowing the Difference between these option trade types" />
            </Helmet>
            <div style={{ width: '50%', textAlign: 'left' }}>

                <h1 style={{ marginBottom: '0px',fontSize: '1.9rem', fontWeight: 550 }}>How to Read Unusual Options Flow</h1>
                <p style={{ color:'whitesmoke', marginBottom:'20px',marginTop: '8px' }} >A guide on how to read unusual options flow on the Bullflow platform</p>
                <h4 style={{ color: 'lightgray', marginTop: '0px' }} >BullFlow.io  -  January 17, 2023</h4>
                <img
                    src="https://bullflow.io/flow_screenshot.png"
                    alt="Reading unusual options flow"
                    style={{ height: '350px', maxWidth: '100%' }}
                />
                <p style={{ fontSize: '0.8rem', color: '#888' }}>Source: Bullflow.io</p>

                <p style={{ lineHeight: '1.5', marginTop:'10px' }}>Welcome to our guide on how to interpret unusual options flow feeds on trading dashboards. This platform is designed to assist traders and investors in gaining a competitive edge by identifying and comprehending the significance of unusual options activity as it happens in real-time. Below is a step-by-step guide to help you navigate through the information provided:</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Time and Ticker</h2>
                <p style={{ lineHeight: '1.5' }}>Each row starts with a <span className="highlight">timestamp</span> indicating when the options trade was executed, followed by the <span className="highlight">ticker symbol</span> of the underlying stock.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>P/C (Put/Call)</h2>
                <p style={{ lineHeight: '1.5' }}>This indicates the type of option that was traded: <span className="highlight">"P"</span> for put options, or <span className="highlight">"C"</span> for call options.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Expires</h2>
                <p style={{ lineHeight: '1.5' }}>The <span className="highlight">expiration date</span> of the option contract tells you how much time is left until the option expires. Shorter expirations may indicate more immediate sentiment, while longer dates might suggest a longer-term view.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Type</h2>
                <p style={{ lineHeight: '1.5' }}>This refers to the trade size and nature, such as:</p>
                <ul>
                    <li style={{ lineHeight: '1.5' }}><span style={{fontWeight:'bold'}}>"Block"</span> - These are large, privately negotiated trades that often involve institutional investors. Blocks usually indicate significant transactions that could move the market.</li>
                    <li style={{ lineHeight: '1.5' }}><span style={{fontWeight:'bold'}}>"Sweep"</span> - These are orders that are broken down and executed across multiple exchanges. Sweeps indicate urgency on the part of the buyer or seller to get the trade filled at the best available prices, regardless of the exchange.</li>
                    <li style={{ lineHeight: '1.5' }}><span style={{fontWeight:'bold'}}>"Split"</span> - This type of order divides a large trade into smaller ones to disguise the overall order size and minimize the market impact. Traders use splits to execute large orders without significantly affecting the price.</li>
                </ul>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Side</h2>
                <p style={{ lineHeight: '1.5' }}>Trade side indicates where the trade was executed relative to the bid, ask, spread. Understanding this can help you identify whether the sentiment of the trade is bullish or bearish. For example: a trade placed At or Above the Asking price indicates that the trade was a buy side trade. Because the buyer was willing to pay a premium to purchase the contract. On the other hand we have Bid and Below Bid, which indicate a sell side trade. The seller may have been looking to close the position quickly, and willing to sell for cheaper.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Spot</h2>
                <p style={{ lineHeight: '1.5' }}>Here we have the current <span className="highlight">spot price</span> of the underlying security when the option trade was placed. It's important for comparing against the strike price.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Size</h2>
                <p style={{ lineHeight: '1.5' }}>This number indicates the <span className="highlight">volume of contracts</span> traded, with larger sizes often being more significant or 'unusual'.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Strike</h2>
                <p style={{ lineHeight: '1.5' }}>The <span className="highlight">strike price</span> is the price at which the option can be exercised. It's key for gauging the trade's optimism or pessimism.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Price</h2>
                <p style={{ lineHeight: '1.5' }}>This is the <span className="highlight">premium paid per option contract</span>, which can be multiplied by 100 to find the total cost for the option lot.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>OTM% (Out-of-the-Money Percentage)</h2>
                <p style={{ lineHeight: '1.5' }}>This shows how far the strike price is from the current spot price, as a percentage. A higher percentage means the option is further from being in-the-money. A negative OTM% means the option is in-the-money.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Value</h2>
                <p style={{ lineHeight: '1.5' }}>The total <span className="highlight">monetary value</span> of the trade, which can be a strong signal of conviction if unusually high.</p>

                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>SigScore (Significance Score)</h2>
                <p style={{ lineHeight: '1.5' }}>Our proprietary <span className="highlight">significance score</span> rates the potential impact of the trade, with higher scores indicating more noteworthy activity. The sig score algorithm takes these factors into account: Price-to-value ratio, OTM%, days until expiration and trade side.</p>



                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Interpreting the Dashboard</h2>
                <p style={{ lineHeight: '1.5' }}>Understanding the data is crucial for making informed trading decisions. For example, a large block of Call options with a near-term expiration and high SigScore might suggest a potential upcoming positive event for the stock. Conversely, a high volume of Puts with a low strike price could indicate market speculation on a downturn.</p>

                <p style={{ lineHeight: '1.5' }}>Remember that while unusual options activity can provide insights, it should be one of many tools in your trading strategy. Always consider the broader market context, news, and other indicators before making any decisions.</p>



                <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>Conclusion</h2>
                <p style={{ lineHeight: '1.5' }}>Bullflow.io's dashboard gives you a powerful lens to view the options market. By mastering the interpretation of our unusual options flow feed, you can better position yourself in the dynamic world of options trading. Stay ahead of the curve and let the flow guide your next move.</p>

                <h4 style={{lineHeight:'1.5'}}>Try the Bullflow.io Unusual Options Platform for free and elevate your trading today!</h4>
            </div>
        </div>
    );
}

export default ReadingUnusualOptionsFlow;
