import { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community';
import { AgGridReact } from "ag-grid-react";
import '../agStyles_newswire.css';
import { collection, query, onSnapshot, limit, orderBy } from "firebase/firestore";
import { firestore } from '../firebase';
import { GenericSearchBox } from "../NewDashboardComponents/GenericSearchBox";
import ResetIconButton from "../NewDashboardComponents/ResetIconButton";
import { Tabs, Tab } from "@nextui-org/react"; // Import Tabs and Tab components from Next UI

function NewsPage() {
    const [articles, setArticles] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [activeTab, setActiveTab] = useState("all"); // State to manage active tab
    const gridRef = useRef(null); // Ref to access the AG Grid API
    useEffect(() => {
        const fetchArticles = async () => {
            let collectionName = "_news_feed";
            switch (activeTab) {
                case "Economy":
                    collectionName = "_economy_news";
                    break;
                case "Finance":
                    collectionName = "_finance_news";
                    break;
                case "Corporate":
                    collectionName = "_news_feed";
                    break;
                default:
                    collectionName = "_news_feed";
            }

            const q = query(
                collection(firestore, collectionName),
                orderBy("published_utc", "desc"),
                limit(100)
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const fetchedArticles = [];
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    const article = doc.data();
                    if (article.publisher && article.publisher.name) {
                        fetchedArticles.push(article);
                    }
                });

                const allGridRowData = [];
                gridRef.current.forEachNode(node => allGridRowData.push(node.data));
                const allRowIdsInGrid = new Set(allGridRowData.map(data => data.title));

                const fetchedArticleIds = new Set(fetchedArticles.map(article => article.title));

                const addsAndUpdates = fetchedArticles.filter(article =>
                    !allRowIdsInGrid.has(article.title) || allRowIdsInGrid.has(article.title)
                );

                const removes = allGridRowData.filter(data => !fetchedArticleIds.has(data.title)).map(data => data.title);

                const transaction = {
                    add: addsAndUpdates.filter(article => !allRowIdsInGrid.has(article.title)),
                    update: addsAndUpdates.filter(article => allRowIdsInGrid.has(article.title)),
                    remove: removes.map(title => allGridRowData.find(data => data.title === title))
                };

                gridRef.current.applyTransaction(transaction);
            });

            return () => unsubscribe();
        };

        fetchArticles();
    }, [activeTab]);

    const onTickerCellClicked = (params) => {
        setSelectedArticle(params.data);
    };

    const onGridReady = params => {
        gridRef.current = params.api;
    };

    const columnDefs = [
        {
            headerName: "Title",
            field: "title",
            flex: 1,
            cellStyle: {
                fontSize: "1.1em",
                fontWeight: 475,
                cursor: "pointer",
            },
            cellRenderer: (params) => {
                return (
                    <div
                        style={{ height: "100%" }}
                        onClick={() => onTickerCellClicked(params)}
                    >
                        {params.value}
                    </div>
                );
            },
        },
        {
            headerName: "Ticker",
            field: "tickers",
            cellRenderer: (params) => {
                if (params.data.tickers && params.data.tickers.length > 0) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                cursor: "pointer",
                            }}
                            onClick={() => onTickerCellClicked(params)}
                        >
                            <div
                                style={{
                                    display: "inline-block",
                                    backgroundColor: "rgba(75, 183, 144, 0.3)",
                                    borderRadius: "5px",
                                    padding: "3px 7.5px",
                                    lineHeight: "1",
                                }}
                            >
                                <span style={{ fontWeight: 550, color: "#20d46b", fontSize: "0.75em" }}>
                                    {params.data.tickers[0]}
                                </span>
                            </div>
                        </div>
                    );
                }
                return "";
            },
            width: 120,
        },
    ];
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "row",
                background: "#1a1b20",
            }}
        >
            {/* Left nav column with logo and icons */}
            <Sidebar activeItem="news" />

            {/* Center column with Title row, details, and content */}
            <div
                style={{
                    flex: 1,
                    marginRight: "20px",
                    marginTop: "10px",
                    marginLeft: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "#1a1b20",
                }}
            >
                {/* Top Bar */}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                            alignItems: "center",
                        }}
                    >
                        {/* Adjust gap as needed */}
                        <h1
                            style={{
                                color: "white",
                                margin: 0,
                                fontWeight: "bold",
                                fontSize: "1.35em",
                            }}
                        >
                            Corporate News Feed
                        </h1>
                    </div>
                    <GenericSearchBox placeholder="Search tickers" />

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {/* Next UI Tabs */}

                        <Tabs
                            aria-label="Little Menu"
                            classNames={{
                                tabList: 'bg-black',
                                cursor: 'bg-neutral-800',
                                tabContent: "group-data-[selected=true]:text-white"
                            }}
                            selectedKey={activeTab}
                            onSelectionChange={setActiveTab}
                        >
                            <Tab key="Economy" title="Economy" />
                            <Tab key="Finance" title="Finance" />
                            <Tab key="Corporate" title="Corporate" />
                        </Tabs>
                        <ResetIconButton />
                    </div>
                </div>
                {/* Content */}
                <div
                    style={{
                        height: "calc(100vh - 80px)",
                        width: "100%",
                        transition: "all 0.3s ease",
                        marginTop: "10px",
                        display: "flex",
                        gap: "20px",
                    }}
                >
                    {/* grid */}
                    <div
                        className={"ag-theme-quartz-dark"}
                        style={{ height: "100%", width: "65%" }}
                    >
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                            }}
                            headerHeight={0}
                        />
                    </div>
                    {/* article reader section */}
                    <div
                        style={{
                            width: "35%",
                            backgroundColor: "black",
                            borderRadius: "10px",
                            padding: "20px",
                            overflowY: "auto",
                        }}
                    >
                        {selectedArticle ? (
                            <div>
                                <h2 style={{ color: "white", marginBottom: "10px", fontWeight: 575, fontSize: '1.1em' }}>
                                    {selectedArticle.title}
                                </h2>
                                {selectedArticle.publisher && selectedArticle.publisher.name && (
                                    <p style={{ color: "gray", fontSize: "0.9em", marginBottom: "5px" }}>
                                        Published by {selectedArticle.publisher.name}
                                    </p>
                                )}
                                <p style={{ color: "gray", fontSize: "0.9em", marginBottom: "20px" }}>
                                    {new Date(selectedArticle.published_utc).toLocaleString()}
                                </p>
                                <p style={{ color: "white", lineHeight: "1.6" }}>
                                    {selectedArticle.description}
                                </p>
                            </div>
                        ) : (
                            <p style={{ color: "white" }}>Select an article to view its description.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsPage;