import { useState, useEffect } from 'react';
import { Slider } from 'antd';

function ExpirationFilterFields({ minDTEProp, maxDTEProp, handleFilterChange }) {
  const [minDTE, setMinDTE] = useState(minDTEProp);
  const [maxDTE, setMaxDTE] = useState(maxDTEProp);

  const handleChange = (values) => {
    const newMaxDTE = values[1] === 700 ? 1000000 : values[1];
    setMinDTE(values[0]);
    setMaxDTE(newMaxDTE);
    handleFilterChange('min DTE', values[0]);
    handleFilterChange('max DTE', newMaxDTE);
  };

  const formatTooltip = (value) => {
    if (value === 700) {
      return 'Infinite';
    }
    return value;
  };

  useEffect(() => {
    setMinDTE(minDTEProp);
  }, [minDTEProp]);

  useEffect(() => {
    setMaxDTE(maxDTEProp);
  }, [maxDTEProp]);

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          fontWeight: 600,
          fontSize: '0.8em',
        }}
      >
        <p style={{ marginBottom: '0px' }}>Days Until Expiration</p>
      </div>
      <div style={{ marginTop: '0px', marginBottom: '0px' }}>
        <Slider
          range
          min={0}
          max={700}
          value={[minDTE, maxDTE === 1000000 ? 700 : maxDTE]}
          onChange={handleChange}
          tipFormatter={formatTooltip}
          style={{ color: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '0px',
          marginTop: '0px',
        }}
      >
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          0
        </p>
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          ∞
        </p>
      </div>
    </div>
  );
}

export default ExpirationFilterFields;