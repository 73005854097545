import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Input } from 'antd';

const useStyles = makeStyles({
  root: {
    width: '100%', // add this line
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '& .MuiInputLabel-root': {
      color: 'grey',
    },
    '&:hover .MuiInputLabel-root': {
      color: 'grey',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInputBase-input': {
      color: 'white',
      height: '1.5em',
      padding: '10px 12px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    // marginBottom: '10px',
    fontWeight: 'bold'
  },
  mainTitle: {
    marginLeft: '10px',
    marginRight: '5px',
    fontSize: '0.8em',
  },
  subtitle: {
    color: 'grey',
    fontSize: '0.8em',
  },
  input: {
    margin: '0 10px',
    width: 'calc(50% - 20px)', // assuming there are 2 fields with 10px margin each, adjust accordingly

  },
});

function VolumeDifFields({ minVolumeDifProp, maxVolumeDifProp, handleFilterChange }) {
  const classes = useStyles();
  const [minVolumeDif, setMinVolumeDif] = useState(minVolumeDifProp);
  const [maxVolumeDif, setMaxVolumeDif] = useState(maxVolumeDifProp);

  const handleChange = (field, value) => {
    if (field === 'min volume dif') setMinVolumeDif(value);
    if (field === 'max volume dif') setMaxVolumeDif(value);
    handleFilterChange(field, value);
  };
  useEffect(() => {
    setMinVolumeDif(minVolumeDifProp);
  }, [minVolumeDifProp]);

  useEffect(() => {
    setMaxVolumeDif(maxVolumeDifProp);
  }, [maxVolumeDifProp]);

  return (
    <div>
      <style type="text/css">
        {`
    #min-volume-dif::placeholder, #max-volume-dif::placeholder {
      color: #6B7280; 
    }
  `}
      </style>
      <div className={classes.root}>
        <Input
          id="min-volume-dif"
          placeholder="Min % Change in Daily Volume"
          className={`${classes.input}`}
          value={minVolumeDif}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
          variant="outlined"
          onChange={event => handleChange('min volume dif', event.target.value)}
        />
        <Input
          id="max-volume-dif"
          placeholder="Max % Change in Daily Volume"
          className={`${classes.input}`}
          value={maxVolumeDif}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',ant
          }}
          variant="outlined"
          onChange={event => handleChange('max volume dif', event.target.value)}
        />
      </div>
    </div>
  );
}

export default VolumeDifFields;
