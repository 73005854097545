import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const NoSubTopBarComponent = () => {
    const navigate = useNavigate();
    return (
        <div style={{
            width: '60%',
            height: 'auto', // Adjusted to accommodate button
            backgroundColor: '#000000',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column', // Changed to column to stack items vertically
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px',
        }}>
            <p style={{ color: 'white', marginBottom: '8px' }}>You are currently viewing 30 minute delayed trade data. Join Bullflow to see trades happen in real time.</p>
            <Button style={{
                background: '#36363680',
                color: 'white', // Assuming you want the text color to be black for contrast
                border: 'none',
                borderRadius: '9999px', // Full radius for pill shape
                padding: '10px 20px',
                fontSize: '16px',
                height: '40px',
                cursor: 'pointer',
                outline: 'none',
            }}
            onClick={() => {
                navigate("/signup");
            }}>Start Free Trial
            </Button>
        </div>
    )
}

export default NoSubTopBarComponent;
