const WideBorderMagicButton = ({ text = "What's New", onClick, fillParentWidth = false }) => {
    const baseButtonClasses = "relative overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 shadow-[0px_2px_3px_rgba(0,0,0,0.3)]";
    const baseSpanClasses = "h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#1f2026] py-1 text-sm font-semibold text-white backdrop-blur-3xl";

    if (fillParentWidth) {
        return (
            <button
                className={`${baseButtonClasses} flex w-full h-10`}
                onClick={onClick}
            >
                <span className="absolute inset-[-1000%] animate-[spin_4s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className={`${baseSpanClasses} flex px-4`}>
                    {text}
                </span>
            </button>
        );
    }

    return (
        <button
            className={`${baseButtonClasses} inline-flex h-10`}
            onClick={onClick}
        >
            <span className="absolute inset-[-1000%] animate-[spin_4s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className={`${baseSpanClasses} inline-flex px-16`}>
                {text}
            </span>
        </button>
    );
};

export default WideBorderMagicButton;