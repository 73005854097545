import { useMediaQuery } from "react-responsive";
import Sidebar from "../Sidebar";
import { getGreeting } from "../Utils";
import BorderMagicButton from "../components/ui/BorderMagicButton";
import HotStocks from "./HotStocks";
import MarketOverviewHomeComponent from "./MarketOverviewHomeComponent";
import NewCallPutChart from "./NewCallPutChart";
import VolumeSpikeComponent from "./VolumeSpikeComponent";
import { Helmet } from 'react-helmet';

function HomePage() {
  const currentDate = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  const handleWhatsNewClick = () => {
    // Open updates in a new tab
    window.open('https://bullflow.io/updates', '_blank');
  };
  const isPhoneSize = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row', background: '#1a1b20' }}>
      <Sidebar activeItem="home" />
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Bullflow dashboard" />
      </Helmet>

      {/* Conditional rendering based on screen size */}
      {isPhoneSize ? (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', background: '#333', borderRadius: '10px', margin: '20px' }}>
          <p style={{ color: '#f0f0f0', textAlign: 'center', fontSize: '1.2em' }}>
            Bullflow mobile experience is in development. To experience the best of Bullflow, please use a desktop browser.
          </p>
        </div>
      ) : (
        <div style={{ flex: 1, height: '100vh', overflowY: 'auto', scrollBehavior: 'smooth' }}>
          <div style={{ marginTop: '40px', marginRight: '60px', marginLeft: '60px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#1a1b20' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', padding: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0px' }}>
                <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', fontSize: '1.35em' }}>{getGreeting()}</h1>
                <h2 style={{ color: '#A9A9A9', margin: 0, fontWeight: 550, fontSize: '0.8em' }}>{formattedDate}</h2>
              </div>
              <BorderMagicButton onClick={handleWhatsNewClick} />
            </div>

            <div style={{ width: '100%', height: '0.5px', backgroundColor: '#2b2b2b', padding: '0.5px' }}></div>

            <div style={{ width: '100%', marginTop: '40px', marginBottom: '10px', display: 'flex', flexDirection: 'column', gap: '20px', padding: '0 0px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '40px', marginBottom: '40px' }}>
                <MarketOverviewHomeComponent />
                <NewCallPutChart />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', gap: '40px', marginBottom: '30px' }}>
                <VolumeSpikeComponent />
                <HotStocks />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;