import React, { useEffect, useState } from "react";
import { Container, ThemeProvider, createTheme } from "@mui/material";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from './firebase';
import { GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import googleLogoPath from './g_icon.svg';
import { Helmet } from 'react-helmet';
import xLogoWhite from './x-logo-white.png';
import { TwitterAuthProvider } from "firebase/auth";
import { Form, Input, Button, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white" // Change this as per your requirement
                    },
                    "&:hover": {
                        color: "white" // Change this as per your requirement
                    },
                }
            }
        }
    }
});

const SignInScreen = () => {
    const [form] = Form.useForm(); // Define the form instance here

    const navigate = useNavigate();
    const auth = getAuth();
    const [signInError, setSignInError] = useState('');
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false); // New state to control visibility of password reset

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const checkPaymentsAndNavigate = async () => {
                    const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", user.email));

                    const paymentQuerySnapshot = await getDocs(paymentsQuery);

                    const paymentDocExists = !paymentQuerySnapshot.empty;

                    if (paymentDocExists) { // checks if the payment exists in both collections
                        // Get the first document from the query snapshot
                        const paymentDoc = paymentQuerySnapshot.docs[0];
                        const subscriptionType = paymentDoc.data().subscriptionType;

                        if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                            // Handle subscription type
                            // console.log(`User has a ${subscriptionType} subscription.`);
                            navigate('/app');
                        } else {
                            navigate('/pricing');
                            // console.log('User does not have a monthly or yearly subscription.');
                        }

                    } else {
                        await setDoc(doc(collection(firestore, "users"), user.email), {
                            username: user.displayName,
                            email: user.email,
                        });
                        navigate('/pricing');
                    }
                }
                checkPaymentsAndNavigate();
            } else {
                // console.log("No user is signed in");
            }
        });


        return () => {
            unsubscribe();
        };
    }, [auth, navigate]);


    const handleGoogleSignup = async () => {
        window.gtag('event', 'Sign In with google button', {
            'event_category': 'Button',
            'event_label': 'Sign In with google',
            'value': 1
        });
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            performLoginCheckProcess(user.email);
        } catch (error) {
            console.error("Error signing up with Google: ", error);
        }
    };

    const handleTwitterSignup = async () => {
        window.gtag('event', 'Sign In with X button', {
            'event_category': 'Button',
            'event_label': 'Sign In with X',
            'value': 1
        });
        const provider = new TwitterAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            // Handle the sign-in result here (e.g., update user profile, navigate)
            const email = result.user.email;
            if (email) {
                console.log("Twitter sign-in successful " + email);
                performLoginCheckProcess(email);
            } else {
                console.log("Twitter sign-in successful, no email returned");
            }
        } catch (error) {
            console.error("Error signing up with Twitter:", error);
        }
    };

    const performLoginCheckProcess = async (email) => {
        const userDoc = await getDoc(doc(collection(firestore, "users"), email));

        // Create a query against the collection
        const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", email));

        // Execute the query
        const paymentQuerySnapshot = await getDocs(paymentsQuery);

        // Checks if any matching documents were found
        const paymentDocExists = !paymentQuerySnapshot.empty;

        if (userDoc.exists() && paymentDocExists) { // checks if the user exists in both collections
            // Get the first document from the query snapshot
            const paymentDoc = paymentQuerySnapshot.docs[0];
            const subscriptionType = paymentDoc.data().subscriptionType;

            if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                // Handle subscription type
                // console.log(`User has a ${subscriptionType} subscription.`);
                navigate('/app');
            } else {
                navigate('/pricing');
                // console.log('User does not have a monthly or yearly subscription.');
            }

        } else {
            await setDoc(doc(collection(firestore, "users"), email), {
                email: email,
            });
            navigate('/pricing');
        }
    }
    const handleEmailSignIn = async (values) => {
        const { email, password } = values;
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User signed in successfully.');
            performLoginCheckProcess(user.email);
        } catch (error) {
            console.error("Error signing in with email and password:", error);
            if (error.message) {
                // Replace the firebase in error.message with a more user-friendly message
                setSignInError(error.message.replace('Firebase', 'Bullflow'));
            } else {
                setSignInError("An error occurred during sign in. Please try again later.");
            }
        }
    };

    const toggleEmailForm = () => {
        setShowEmailForm(!showEmailForm);
    };

    const handleForgotPassword = () => {
        setShowPasswordReset(!showPasswordReset); // Toggle visibility of password reset form
    };

    const handleSubmitPasswordReset = async () => {
        console.log(`Sending password reset email...`);
        const email = form.getFieldValue('email'); // Access the email value directly
        const auth = getAuth();

        if (!email) {
            alert("Please enter your email address.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent. Please check your inbox.");
            setShowPasswordReset(false); // Optionally hide after sending
        } catch (error) {
            console.error("Failed to send password reset email:", error);
            setSignInError("Error sending password reset email. Please try again. Or contact support@bullflow.io");
        }
    };

    const renderEmailForm = () => {
        if (showEmailForm) {
            return (
                <div style={{ animation: 'fadeInUp 1.0s ease-out forwards', marginTop: '30px' }}>
                    <Form form={form} onFinish={handleEmailSignIn} style={{ maxWidth: '300px', margin: 'auto' }}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Email" type="email" />
                        </Form.Item>
                        {!showPasswordReset && (
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        )}
                        {!showPasswordReset ? (
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#040129' }}>
                                    Sign In
                                </Button>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Button type="primary" onClick={handleSubmitPasswordReset} style={{ width: '100%', backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#040129' }}>
                                    Send Password Reset Email
                                </Button>
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button type="link" onClick={handleForgotPassword} style={{ float: 'right', marginTop: '0px' }}>
                                {showPasswordReset ? 'Back to Sign In' : 'Forgot password?'}
                            </Button>
                        </Form.Item>
                        {signInError && (
                            <div style={{ marginTop: '10px' }}>
                                <Alert message={signInError} type="error" showIcon />
                            </div>
                        )}
                    </Form>
                </div>
            );
        }
        return null;
    };

    return (
        <ThemeProvider theme={theme}>
            <style>
                {`
                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            `}
            </style>
            <Helmet>
                <title>Sign In</title>
                <meta name="description" content="Sign In - Bullflow" />
                <meta name="keywords" content="Bullflow unusual option flow platform" />
            </Helmet>
            <Container component="main"
                maxWidth="xs"
                style={{
                    display: 'flex', flexDirection: 'column', height: '50vh',
                    marginTop: '100px', marginBottom: '100px'
                }}>
                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    opacity: 0, // Set initial opacity to 0
                    transform: 'translateY(20px)', // Initial transform state
                    animation: 'fadeInUp 1.0s ease-out forwards' // Set animation fill mode to 'forwards'
                }}>
                    <h2 style={{
                        margin: '35px 0',
                        whiteSpace: 'nowrap',
                        fontSize: '1.5em',
                        fontWeight: 550,
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline',
                    }}>
                        Sign in to Bullflow
                    </h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                    <button
                        onClick={handleGoogleSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',  // Transparent background
                            color: '#fff',
                            padding: '10px 20px',
                            border: '0.5px solid #4285F4',       // Thin white border
                            borderRadius: '50px',            // Pill-shaped border radius
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            outline: 'none',
                            textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Arial, sans-serif',
                            height: '45px',
                            opacity: 0, // Set initial opacity to 0
                            transform: 'translateY(20px)', // Initial transform state
                            animation: 'fadeInUp 1.0s ease-out 0.15s forwards' // Set animation fill mode to 'forwards'
                        }}
                    >
                        <img
                            src={googleLogoPath}
                            alt="Google Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                                backgroundColor: '#fff',
                                padding: '2px',
                                borderRadius: '2px',
                            }}
                        />
                        Continue with Google
                    </button>
                    <button
                        onClick={handleTwitterSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                            color: '#fff',
                            padding: '10px 20px',
                            border: '0.5px solid #a9afb0',
                            borderRadius: '50px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            outline: 'none',
                            marginTop: '20px',
                            textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Arial, sans-serif',
                            height: '45px',
                            opacity: 0,
                            transform: 'translateY(20px)',
                            animation: 'fadeInUp 1.0s ease-out 0.3s forwards'
                        }}
                        type="button"
                    >
                        Continue with
                        <img
                            src={xLogoWhite}
                            alt="Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '10px', // Space between text and logo
                            }}
                        />
                    </button>
                </div>
                {!showEmailForm && (
                    <div>
                        <div onClick={toggleEmailForm} style={{
                            cursor: 'pointer',
                            opacity: 0,
                            animation: 'fadeInUp 1.0s ease-out 0.6s forwards', fontWeight: 550, fontSize: '0.9em', textAlign: 'center', marginTop: '20px'
                        }}>
                            Continue with email
                        </div>
                    </div>
                )}
                {renderEmailForm()}
            </Container>
        </ThemeProvider >
    );


};

export default SignInScreen;
