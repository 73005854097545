import React, { useEffect, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

const SentimentCheckboxes = ({ bullishChecked, bearishChecked, neutralChecked, handleSentimentBoxChange }) => {
    const [bullishState, setBullishState] = useState(bullishChecked);
    const [bearishState, setBearishState] = useState(bearishChecked);
    const [neutralState, setNeutralState] = useState(neutralChecked);

    const handleBullishChange = (isChecked) => {
        setBullishState(isChecked);
        handleSentimentBoxChange(isChecked, 'bullishCheckBox');
    };

    const handleBearishChange = (isChecked) => {
        setBearishState(isChecked);
        handleSentimentBoxChange(isChecked, 'bearishCheckBox');
    };

    const handleNeutralChange = (isChecked) => {
        setNeutralState(isChecked);
        handleSentimentBoxChange(isChecked, 'neutralCheckBox');
    };

    useEffect(() => {
        setBullishState(bullishChecked);
    }, [bullishChecked]);

    useEffect(() => {
        setBearishState(bearishChecked);
    }, [bearishChecked]);

    useEffect(() => {
        setNeutralState(neutralChecked);
    }, [neutralChecked]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={bullishState}
                        onCheckedChange={handleBullishChange}
                        style={{
                            backgroundColor: bullishState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>🐂</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={bearishState}
                        onCheckedChange={handleBearishChange}
                        style={{
                            backgroundColor: bearishState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>🐻</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox.Root
                        checked={neutralState}
                        onCheckedChange={handleNeutralChange}
                        style={{
                            backgroundColor: neutralState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>⚖️</label>
                </div>
            </div>
        </div>
    );
};

export default SentimentCheckboxes;