import React, { useEffect, useRef } from 'react';

const TradingViewWidget = ({ symbol = 'NASDAQ:AAPL', interval = '15', theme = 'light', locale = 'en' }) => {
    const chartContainerRef = useRef();

    useEffect(() => {
        // Load TradingView script dynamically
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/tv.js';
        script.async = true;
        script.onload = () => {
            // eslint-disable-next-line no-new
            new window.TradingView.widget({
                container_id: 'tradingview-chart',
                symbol,
                interval,
                theme,
                locale,
                autosize: true,
                toolbar_bg: '#f1f3f6',
                enable_publishing: false,
                hide_side_toolbar: false,
                allow_symbol_change: true,
                details: true,
                calendar: true,
                withdateranges: true,
                hideideas: true,
            });
        };

        chartContainerRef.current.appendChild(script);
    }, [symbol, interval, theme, locale]);

    return <div id="tradingview-chart" ref={chartContainerRef} style={{ height: '80vh', width: '80vw' }} />;
};

export default TradingViewWidget;
