import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import VolumeSpikeMeter from './VolumeSpikeListItem';
import getDateString, { convertOptionsSymbolShortened, optionsSymbolToJustTicker, parseOptionType } from '../Utils';
import { Tooltip } from '@nextui-org/react';
import { HelpCircle } from 'lucide-react';

const VolumeSpikeComponent = () => {
    const [volumeSpikes, setVolumeSpikes] = useState([]);
    const parentDivHeight = 350;
    const gradientMeterHeight = 30;
    const maxItems = Math.floor((parentDivHeight - 100) / gradientMeterHeight);

    useEffect(() => {
        const fetchVolumeSpikes = async () => {
            const collectionName = `_unusual_volume_spikes${getDateString()}`;
            const volumeSpikesRef = collection(firestore, collectionName);
            const q = query(volumeSpikesRef, orderBy('percent_change', 'desc'), limit(100));
            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map((doc) => doc.data());
            setVolumeSpikes(data);
        };

        fetchVolumeSpikes();
    }, []);


    // Split volumeSpikes into puts and calls
    const putVolumeSpikes = volumeSpikes
        .filter((spike) => parseOptionType(spike.symbol).includes('P'))
        .slice(0, maxItems);
    const callVolumeSpikes = volumeSpikes
        .filter((spike) => !parseOptionType(spike.symbol).includes('P'))
        .slice(0, maxItems);

    // Calculate the total percent_change for puts and calls separately
    const totalPutPercentChange = putVolumeSpikes.reduce(
        (total, spike) => total + spike.percent_change,
        0
    );
    const totalCallPercentChange = callVolumeSpikes.reduce(
        (total, spike) => total + spike.percent_change,
        0
    );
    return (
        <div
            style={{
                backgroundColor: 'black',
                borderRadius: '10px', paddingLeft: '20px', paddingRight: '20px',
                height: '350px',
                boxShadow: `0 8px 15px rgb(0, 0, 0, 0.5)`,
                flex: 1,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', marginRight: '5px' }}>Volume Spikes</h1>
                <Tooltip content="Contracts with daily volumes exceeding the 30 day average">
                    <HelpCircle size={10} color="#A9A9A9" />
                </Tooltip>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1', marginRight: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#A9A9A9', fontSize: '0.75em', fontWeight: 550, marginBottom: '7.5px' }}>
                        <span>Calls</span>
                    </div>
                    {callVolumeSpikes.map((spike) => (
                        <VolumeSpikeMeter
                            key={spike.symbol}
                            gradientLength={(spike.percent_change / totalCallPercentChange) * 3}
                            symbol={convertOptionsSymbolShortened(spike.symbol)}
                            percentChange={parseFloat(spike.percent_change.toFixed(2))}
                            putsOrCalls="calls"
                            formattedTicker={optionsSymbolToJustTicker(spike.symbol)}

                        />
                    ))}
                </div>
                <div style={{ flex: '1', marginLeft: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#A9A9A9', fontWeight: 550, fontSize: '0.75em', marginBottom: '7.5px' }}>
                        <span>Puts</span>
                    </div>
                    {putVolumeSpikes.map((spike) => (
                        <VolumeSpikeMeter
                            key={spike.symbol}
                            gradientLength={(spike.percent_change / totalPutPercentChange) * 3}
                            symbol={convertOptionsSymbolShortened(spike.symbol)}
                            percentChange={parseFloat(spike.percent_change.toFixed(2))}
                            putsOrCalls="puts"
                            formattedTicker={optionsSymbolToJustTicker(spike.symbol)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VolumeSpikeComponent;