import { useState, useEffect } from 'react';
import { Slider } from 'antd';
import './scoreAntDesignSlider.css';

function ScoreFilterFields({ minScoreProp, maxScoreProp, handleFilterChange }) {
  const [scoreRange, setScoreRange] = useState([minScoreProp, maxScoreProp]);

  const handleChange = value => {
    setScoreRange(value);
    handleFilterChange('min Score', value[0]);
    handleFilterChange('max Score', value[1]);
  };

  useEffect(() => {
    setScoreRange([minScoreProp, maxScoreProp]);
  }, [minScoreProp, maxScoreProp]);
  const tipFormatter = (sliderValue) => {
    return `${sliderValue}`;
  };

  return (
    <div style={{ width: '90%', marginTop:'0px' }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 600,
        fontSize: '0.8em',
        marginBottom: '0px'
      }}>
        <p style={{ marginBottom: '0px' }}>Refine Sigscore</p> {/* Set margin bottom to 0 */}
      </div>
      <div style={{ marginTop: '0px' }}> {/* Set margin top to 0 */}
        <Slider
        className="score-gradient-slider"
          range
          marks={{ 0: '0.0', 1: '1.0' }}
          min={0}
          max={1}
          step={0.05}
          value={scoreRange}
          onChange={handleChange}
          tooltip={{
            formatter: tipFormatter,
            visible: true,
            zIndex: 15001
          }}
        />
      </div>

    </div>
  );
}

export default ScoreFilterFields;
