import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAfa3y-u4PdIgOmqrXVXJKV6Up5ntyZDJU",
  authDomain: "liteflow-40a37.firebaseapp.com",
  projectId: "liteflow-40a37",
  storageBucket: "liteflow-40a37.appspot.com",
  messagingSenderId: "839892033407",
  appId: "1:839892033407:web:a3616853e6c095a52a444a",
  measurementId: "G-YKZZT8E9J6"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);


export {firestore, auth, functions, analytics};