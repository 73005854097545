import { useState, useEffect } from 'react';
import { Input, Slider } from 'antd';
import './bullflowvolumeslider.css';

function OIFilter({ maxOIProp, handleFilterChange }) {
    const [maxOI, setMaxOI] = useState(maxOIProp);
    // console.log('Prop:', maxOIProp, 'State:', maxOI);

    useEffect(() => {
        setMaxOI(maxOIProp);
    }, [maxOIProp]);

    const onChange = (newValue) => {
        if (newValue >= 1000000) {
            newValue = 1000000;
        }
        setMaxOI(newValue);
        handleFilterChange(newValue);
    };

    const tipFormatter = (sliderValue) => {
        return `${sliderValue}`;
    };
    const onChangeInput = (e) => {
        const newValue = Number(e.target.value);
        if (newValue >= 0 && newValue <= 1000000) {
            setMaxOI(newValue);
            handleFilterChange(newValue);
        }
    };
    return (
        <div style={{ width: '90%', marginTop: '0px' }}>
            <p style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '10px' }}>Open Interest</p>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '0px'
            }}>
                <Slider
                    className='bfvolume-gradient-slider'
                    min={0}
                    max={100000}
                    onChange={onChange}
                    style={{ flexGrow: 7.5, marginRight: '10px' }}
                    value={maxOI}
                    tooltip={{
                        formatter: value => `${value}`,
                        visible: true,
                        zIndex: 15001
                    }}
                />
            </div>
        </div>
    );
}

export default OIFilter;
