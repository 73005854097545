import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
const NoSubscription = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => {
            clearTimeout(timer);
        };
    });

    const handleRenewClick = () => {
        // You can navigate when button is clicked
        window.gtag('event', 'Nosubscription renewal', {
            'event_category': 'Button',
            'event_label': 'Nosubscription renewal',
            'value': 1
        });
        navigate('/Pricing')
    };
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
        }}>
            {loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh' // Full screen height to center the spinner vertically
                }}>
                    <CircularProgress /> {/* MUI Loading Spinner */}
                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    flexDirection: 'column',
                }}>
                    <h1>Your BullFlow Subscription has Ended</h1>
                    <h4>If you think this information is in error, please contact support@bullflow.io</h4>
                    <Button
                        type="submit"
                        fullWidth={false}
                        onClick={handleRenewClick}
                        style={{
                            backgroundColor: "#FFD700",
                            fontSize: "18px",
                            textTransform: 'none',
                            color: 'black',
                            fontWeight: 'bold'
                        }}
                        variant="contained"
                    >
                        Renew
                    </Button>
                </div>
            )}

        </div>
    );
}

export default NoSubscription;
