import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '50px',
                backgroundColor: '#000',
                width: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <p style={{ color: '#757575' }}>© 2024 BullFlow.io. All Rights Reserved.</p>
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <p
                        onClick={() => navigate('/TermsOfService')}
                        style={{ color: '#757575', cursor: 'pointer' }}
                    >
                        Terms & Conditions
                    </p>
                    <p
                        onClick={() => {
                            window.location.href = '/PrivacyPolicy.html';
                            window.gtag('event', 'Footer Privacy policy', {
                                event_category: 'Button',
                                event_label: 'Footer Privacy policy',
                                value: 1,
                            });
                        }}
                        style={{ color: '#757575', cursor: 'pointer' }}
                    >
                        Privacy Policy
                    </p>
                    <p
                        onClick={() => {
                            window.gtag('event', 'Footer Contact', {
                                event_category: 'Button',
                                event_label: 'Footer Contact',
                                value: 1,
                            });
                            console.log('Contact pressed');
                            navigate('/contact')                        }}
                        style={{ color: '#757575', cursor: 'pointer' }}
                    >
                        Contact Us
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;