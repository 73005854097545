const BorderMagicButton = ({ text = "What's New", onClick }) => {
    return (
        <button
            className="relative inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 shadow-[0px_2px_3px_rgba(0,0,0,0.3)]"
            onClick={onClick}
        >
            <span className="absolute inset-[-1000%] animate-[spin_4s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#1f2026] px-4 py-1 text-xs font-semibold text-white backdrop-blur-3xl">
                {text}
            </span>
        </button>
    );
};

export default BorderMagicButton;