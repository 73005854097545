import { Tooltip } from '@nextui-org/react';
import { Volume2, VolumeX } from 'lucide-react';
import { useState } from 'react';

const SoundIconButton = ({ onClick, isSoundOn }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconButtonStyle = {
        backgroundColor: isHovered ? '#292a30' : '#1f2026',
        border: 'none',
        padding: '10px',
        borderRadius: '90px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s ease',
        transition: 'box-shadow 0.3s ease',
        boxShadow: isHovered ? '0px 4px 6px rgba(0, 0, 0, 0.5)' : '0px 2px 3px rgba(0, 0, 0, 0.3)',
    };

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <Tooltip delay={1000} content="Toggle Alert Sound">
            <button
                style={iconButtonStyle}
                className="icon-button"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick} 
            >
                {isSoundOn ?
                    <Volume2 size={18} strokeWidth={1} /> : <VolumeX size={18} strokeWidth={1} />
                }
            </button>
        </Tooltip >
    );
};

export default SoundIconButton;