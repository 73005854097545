import React, { useEffect, useState } from 'react';
import { getAuth, applyActionCode, checkActionCode, verifyPasswordResetCode } from "firebase/auth";
import { CircularProgress, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const handleAction = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const mode = searchParams.get('mode');
            const actionCode = searchParams.get('oobCode');
            const continueUrl = searchParams.get('continueUrl');

            if (mode === 'verifyEmail' && actionCode) {
                try {
                    await checkActionCode(auth, actionCode);
                    await applyActionCode(auth, actionCode);
                    
                    const user = auth.currentUser;
                    if (user) {
                        await user.reload();
                        if (user.emailVerified) {
                            await user.getIdToken(true);
                            const url = continueUrl ? new URL(continueUrl) : '/';
                            console.log("Navigating to email verification page.");
                            navigate(url.pathname + url.search);
                        } else {
                            setIsLoading(false);
                            console.error("Email verification failed to reflect in user's status.");
                        }
                    }
                } catch (error) {
                    console.error("Error verifying email:", error);
                    setIsLoading(false);
                }
            } else if (mode === 'resetPassword' && actionCode) {
                try {
                    console.log("Navigating to reset password");
                    // Verify the password reset code is valid
                    await verifyPasswordResetCode(auth, actionCode);
                    // Navigate to the password reset component with the action code
                    navigate(`/reset-password?oobCode=${actionCode}`);
                } catch (error) {
                    console.error("Error verifying password reset code:", error);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        handleAction();
    }, [navigate, auth]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Processing... You will be redirected soon.
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            {/* Display error or fallback content here */}
            Action failed. Please try again or contact support.
        </div>
    );
};

export default EmailVerification;
