export const chipData = [
  { key: "ETFs", label: 'ETFs', tooltip: 'ETFs' },
  { key: "Stocks", label: 'Stocks', tooltip: 'Stocks' },
  { key: "Sweeps", label: 'Sweeps', tooltip: 'Trades executed rapidly across multiple exchanges' },
  { key: "Calls", label: 'Calls', tooltip: 'Call options' },
  { key: "Puts", label: 'Puts', tooltip: 'Put options' },
  { key: "Bid", label: 'Bid', tooltip: 'Trades executed on the bid side' },
  { key: "Ask", label: 'Ask', tooltip: 'Trades executed on the ask side' },
  { key: "Whales", label: 'Whales', tooltip: '$1M+' }, //
  { key: "LEAPS", label: 'LEAPS', tooltip: 'Long dated expirations' }, //
  { key: "Unusual", label: 'Unusual', tooltip: 'Trade size exceeding open interest' },
  { key: "Unusual++", label: 'Unusual++', tooltip: 'Large trade size with short expiration and 5%+ OTM' },
  { key: "Large Size", label: 'Large Size', tooltip: 'Size > 5000' },
  { key: "Earnings Soon", label: 'Earnings Soon', tooltip: 'Earnings within 2 days' },
  { key: "Vol>OI", label: 'Vol>OI', tooltip: 'Volume greater than open interest' },
  { key: "Weeklies", label: 'Weeklies', tooltip: 'Trades expiring within a week' },
  { key: "High Sig", label: 'High Sig', tooltip: 'SigScore 0.8+' },
  { key: "Ratio", label: 'Ratio', tooltip: 'High contract value to stock price ratio' },
  { key: "Penny Stocks", label: 'Penny Stocks', tooltip: 'Stock price < $5' },
  { key: "Oddities", label: 'Oddities', tooltip: 'Stocks not seen on Bullflow in a day' },
  { key: "Unicorns", label: 'Unicorns', tooltip: 'Stocks not seen on Bullflow in a week or longer' },
  { key: "Deep OTM", label: 'Deep OTM', tooltip: '20%+ OTM' },
  { key: "OTM", label: 'OTM', tooltip: 'Out of the money' },
  { key: "New Strikes", label: 'New Strikes', tooltip: 'No OI or previous volume. Likely a new strike' },
  { key: "Bullish Flow", label: 'Bullish Flow', tooltip: 'Stocks with 80%+ bullish/bearish ratio' },
];