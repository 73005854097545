import { useEffect, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Bai Jamjuree',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

const CallPutCheckBoxes = ({ callsChecked, putsChecked, handleCallPutChange }) => {
  const [callsState, setCallsState] = useState(callsChecked);
  const [putsState, setPutsState] = useState(putsChecked);

  const handleCallsChange = (isSelected) => {
    setCallsState(isSelected);
    handleCallPutChange(isSelected, 'callsCheckbox');
  };

  const handlePutsChange = (isSelected) => {
    setPutsState(isSelected);
    handleCallPutChange(isSelected, 'putsCheckbox');
  };

  useEffect(() => {
    setCallsState(callsChecked);
  }, [callsChecked]);

  useEffect(() => {
    setPutsState(putsChecked);
  }, [putsChecked]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Checkbox.Root
          checked={callsState}
          onCheckedChange={handleCallsChange}
          style={{
            backgroundColor: callsState ? 'black' : 'transparent',
            width: '14px',
            height: '14px',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '8px',
            border: '1px solid #6B7280',
          }}
        >
          <Checkbox.Indicator>
            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <label style={{ color: 'white', fontSize: '0.75rem' }}>Calls</label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox.Root
          checked={putsState}
          onCheckedChange={handlePutsChange}
          style={{
            backgroundColor: putsState ? 'black' : 'transparent',
            width: '14px',
            height: '14px',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '8px',
            border: '1px solid #6B7280',
          }}
        >
          <Checkbox.Indicator>
            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <label style={{ color: 'white', fontSize: '0.75rem' }}>Puts</label>
      </div>
    </div>
  );
};

export default CallPutCheckBoxes;