import { useState, useEffect } from 'react';
import { BlockPinContainer } from '../components/ui/block-3d-pin';
import { SplitPinContainer } from '../components/ui/split-3d-pin';
import { SweepPinContainer } from '../components/ui/sweep-3d-pin';
import blockImage from './block_trade_image.webp';
import sweepImage from './sweep_trade_image.webp';
import splitImage from './split_trade_image.webp';
import { useInView } from 'react-intersection-observer';

const FadeInSlide = ({ children, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.5,    // Trigger when 50% of the item is in view
    });

    const style = {
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(50px)',
        transition: `opacity 0.5s ease-out ${delay}s, transform 0.5s ease-out ${delay}s`,
    };

    return <div ref={ref} style={style}>{children}</div>;
};

const TradeTypeCardRow = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1250);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1250);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const containerStyle = {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        width: '100%',
        boxSizing: 'border-box',
    };

    const pinContainerStyle = {
        margin: isMobile ? '10px 0' : '0 10px',
        flex: '1 1 0',
        maxWidth: isMobile ? '100%' : 'calc(33.33% - 20px)',
    };
    const baseDelay = 0.2; // seconds
    const delayIncrement = 0.2; // seconds

    return (
        <div style={{
            marginBottom: '150px',
            width: '100%'
        }}>
            <h2 style={{
                fontWeight: 'bold', textAlign: 'center', marginBottom: '20px',
                marginTop: '20px', fontSize: '2.5em'
            }}>
                Our Scanner Detects Every Trade Type
            </h2>
            <div style={containerStyle}>
                <FadeInSlide delay={baseDelay}>
                    <BlockPinContainer
                        title="bullflow.io/blocks"
                        href="https://bullflow.io/blog/understanding-options-block-trades"
                    >
                        <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[20rem] ">
                            <h3 className="max-w-xs !pb-2 !m-0 font-bold text-base text-slate-100">
                                Blocks
                            </h3>
                            <div className="text-base !m-0 !p-0 font-normal">
                                <span className="text-slate-500">
                                    Large, privately negotiated trades signaling major institutional activity
                                </span>
                            </div>
                            <div className="flex flex-1 w-full rounded-lg mt-4 overflow-hidden">
                                <img
                                    src={blockImage}
                                    alt="Description of Image"
                                    className="w-full h-full object-cover" // This ensures the image covers the space without stretching
                                />
                            </div>
                        </div>
                    </BlockPinContainer>
                </FadeInSlide>
                <FadeInSlide delay={baseDelay + delayIncrement}>
                    <SplitPinContainer
                        title="bullflow.io/splits"
                        href="https://bullflow.io/blog/understanding-options-split-trades"
                    >
                        <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[20rem] ">
                            <h3 className="max-w-xs !pb-2 !m-0 font-bold text-base text-slate-100">
                                Splits
                            </h3>
                            <div className="text-base !m-0 !p-0 font-normal">
                                <span className="text-slate-500">
                                    Large orders, split up across multiple exchanges
                                </span>
                            </div>
                            <div className="flex flex-1 w-full rounded-lg mt-4 overflow-hidden">
                                <img
                                    src={splitImage}
                                    alt="Split Image"
                                    className="w-full h-full object-cover" // This ensures the image covers the space without stretching
                                />
                            </div>
                        </div>
                    </SplitPinContainer>
                </FadeInSlide>
                <FadeInSlide delay={baseDelay + delayIncrement * 2}>
                    <SweepPinContainer
                        title="bullflow.io/sweeps"
                        href="https://bullflow.io/blog/understanding-options-sweep-trades"
                    >
                        <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[20rem] ">
                            <h3 className="max-w-xs !pb-2 !m-0 font-bold text-base text-slate-100">
                                Sweeps
                            </h3>
                            <div className="text-base !m-0 !p-0 font-normal">
                                <span className="text-slate-500">
                                    Executed rapidly across multiple exchanges, signaling urgency
                                </span>
                            </div>
                            <div className="flex flex-1 w-full rounded-lg mt-4 overflow-hidden">
                                <img
                                    src={sweepImage}
                                    alt="Sweep Image"
                                    className="w-full h-full object-cover" // This ensures the image covers the space without stretching
                                />
                            </div>
                        </div>
                    </SweepPinContainer>
                </FadeInSlide>
            </div>
        </div>
    );
};

export default TradeTypeCardRow;