import { ThemeProvider } from "styled-components";
import stripeLogoPath from '../images/stripe_logo.svg';
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createTheme } from "@material-ui/core";
import addEventToFirestore from "../EventHelper";
import { Helmet } from "react-helmet";
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase';
import { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white"
                    },
                    "&:hover": {
                        color: "white"
                    },
                }
            }
        }
    }
});

const RenewSubscriptionScreen = () => {
    const [userEmail_, setUserEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserEmail(user.email);
            } else {
                setUserEmail(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleRenewClick = () => {
        if (!userEmail_) {
            console.error('No user email found');
            return;
        } else {
            console.log('User email:', userEmail_);
        }
        setIsLoading(true);

        window.gtag('event', 'Renew screen button', {
            'event_category': 'Button',
            'event_label': 'Renew screen button',
            'value': 1
        });
        addEventToFirestore("Renew Subscription portal button", userEmail_);

        const createStripeSessionForUser = httpsCallable(functions, 'createStripeSessionForUser');

        createStripeSessionForUser({ userEmail: userEmail_ })
            .then((result) => {
                console.log('Stripe session data:', result);
                const { url } = result.data;
                if (url) {
                    window.location.href = url;
                }
            })
            .catch((error) => {
                window.open(`https://billing.stripe.com/p/login/00g4jA2096o86FW288?prefilled_email=${userEmail_ ? userEmail_ : ""}`, '_self');
                console.error('Error creating Stripe session client side:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <style>
                {`
                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            `}
            </style>
            <Helmet>
                <title>Renew</title>
                <meta name="description" content="Renew Subscription - Bullflow" />
                <meta name="keywords" content="Renew Subscription for Bullflow" />
            </Helmet>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '50vh',
                marginTop: '100px',
                marginBottom: '100px',
                width: '80%',
                margin: '0 auto',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0,
                    transform: 'translateY(20px)',
                    animation: 'fadeInUp 1.0s ease-out forwards'
                }}>
                    <h1 style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2rem",
                        marginTop: "20px",
                        marginBottom: "0.45rem",
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}>
                        Renew your Subscription
                    </h1>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0,
                    transform: 'translateY(20px)',
                    animation: 'fadeInUp 1.0s ease-out 0.25s forwards',
                    marginTop: '0px'
                }}>
                    <p style={{
                        color: 'white',
                        marginBottom: '45px',
                        fontSize: '0.95em',
                        whiteSpace: 'nowrap',
                        display: 'inline',
                        fontWeight: 550
                    }}>
                        Regain access to one of the most powerful trading tools available. Use code <span style={{ color: '#FFD700' }}>RENEW35</span> for 35% off!
                    </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <PropagateLoader color={'#FFD700'} loading={isLoading} size={15} />
                        </div>
                    ) : (
                        <button
                            onClick={handleRenewClick}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                padding: '10px 20px',
                                border: '0.5px solid #6772e5',
                                borderRadius: '50px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                outline: 'none',
                                textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                                fontFamily: 'Arial, sans-serif',
                                height: '45px',
                                width: '400px',
                                opacity: 0,
                                transform: 'translateY(20px)',
                                animation: 'fadeInUp 1.0s ease-out 0.45s forwards'
                            }}
                        >
                            <img
                                src={stripeLogoPath}
                                alt="Stripe Logo"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '10px',
                                    padding: '2px',
                                    borderRadius: '2px',
                                }}
                            />
                            Renew With Discount
                        </button>
                    )}
                </div>
            </div>
        </ThemeProvider>
    )
}

export default RenewSubscriptionScreen;