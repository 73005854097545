import { useEffect, useState } from 'react';
import GradientMeterComponent from './CallPutMeter';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import getDateString from '../Utils';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from '@nextui-org/react';

const HotStocks = () => {
    const [callsData, setCallsData] = useState([]);
    const [putsData, setPutsData] = useState([]);
    const parentDivHeight = 350; // Adjust this value based on your parent div height
    const gradientMeterHeight = 27.5;
    const maxItems = Math.floor((parentDivHeight - 100) / gradientMeterHeight); // Subtract header and margin heights

    useEffect(() => {
        const fetchData = async () => {
            console.log("fd-");
            const excludedTickers = ['SPY', 'SPX', 'QQQ', 'IWM'];
            const stocksCollection = collection(firestore, `sentiment_tracking${getDateString()}`);

            // Fetch top 15 documents for calls, filter, then take top 6
            const callsQuery = query(stocksCollection, orderBy('callFlow_premium', 'desc'), limit(40));
            const callsSnapshot = await getDocs(callsQuery);
            const filteredCallsDataArray = callsSnapshot.docs
                .map(doc => ({
                    ticker: doc.data().ticker,
                    callFlowPremium: doc.data().callFlow_premium,
                }))
                .filter(data => !excludedTickers.includes(data.ticker))

            // Calculate total callFlow_premium for the filtered top 6
            const totalCallFlowPremium = filteredCallsDataArray.reduce((total, data) => total + data.callFlowPremium, 0);

            // Calculate gradientLength for each stock
            const updatedCallsDataArray = filteredCallsDataArray.map(data => ({
                ...data,
                gradientLength: (data.callFlowPremium / totalCallFlowPremium) * 1.6,
                count: data.callFlowPremium,
            }));

            // Fetch top 15 documents for puts, filter, then take top 6
            const putsQuery = query(stocksCollection, orderBy('putFlow_premium', 'desc'), limit(40));
            const putsSnapshot = await getDocs(putsQuery);
            const filteredPutsDataArray = putsSnapshot.docs
                .map(doc => ({
                    ticker: doc.data().ticker,
                    putFlowPremium: doc.data().putFlow_premium,
                }))
                .filter(data => !excludedTickers.includes(data.ticker))

            // Calculate total putFlow_premium for the filtered top 6
            const totalPutFlowPremium = filteredPutsDataArray.reduce((total, data) => total + data.putFlowPremium, 0);

            // Calculate gradientLength for each stock
            const updatedPutsDataArray = filteredPutsDataArray.map(data => ({
                ...data,
                gradientLength: (data.putFlowPremium / totalPutFlowPremium) * 1.6,
                count: data.putFlowPremium,
            }));
            const slicedCallsDataArray = updatedCallsDataArray.slice(0, maxItems);
            const slicedPutsDataArray = updatedPutsDataArray.slice(0, maxItems);

            setCallsData(slicedCallsDataArray);
            setPutsData(slicedPutsDataArray);
        };

        fetchData();
    }, [maxItems]);

    return (
        <div style={{
            backgroundColor: 'black', 
            borderRadius: '10px', paddingLeft: '20px', paddingRight: '20px',
            height: '350px',
            boxShadow: `0 8px 15px rgb(0, 0, 0, 0.5)`,
            flex: 1,
        }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', marginRight: '5px' }}>Trending Options</h1>
                <Tooltip content="Stocks with the highest call and put flow premiums on Bullflow.">
                    <HelpCircle size={10} color="#A9A9A9" />
                </Tooltip>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1', marginRight: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#A9A9A9', fontSize: '0.75em', fontWeight: 550, marginBottom: '7.5px' }}>
                        <span>Calls</span>
                    </div>
                    {callsData.map((data, index) => (
                        <GradientMeterComponent key={index} gradientLength={data.gradientLength} ticker={data.ticker} putsOrCalls="calls" count={data.count} />
                    ))}
                </div>
                <div style={{ flex: '1', marginLeft: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#A9A9A9', fontWeight: 550, fontSize: '0.75em', marginBottom: '7.5px' }}>
                        <span>Puts</span>
                    </div>
                    {putsData.map((data, index) => (
                        <GradientMeterComponent key={index} gradientLength={data.gradientLength} ticker={data.ticker} putsOrCalls="puts" count={data.count} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HotStocks;