export const PricingShootingStarButton = ({ text, onClick }) => {
    return (
      <button
        className="group relative flex justify-center items-center overflow-hidden rounded-full px-4 py-2 shadow-[0_1000px_0_0_hsl(0_0%_20%)_inset] text-base font-medium text-white glow-on-hover"
        onClick={onClick}
      >
        <span className="spark mask-gradient animate-flip before:animate-rotate absolute inset-0 h-[100%] w-[100%] overflow-hidden rounded-full [mask:linear-gradient(white,transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
        <span className="backdrop absolute inset-[1px] rounded-full bg-black" />
        <span className="z-10 text-lg">{text}</span>
      </button>
    );
  };