import { useEffect, useState } from "react";
import getDateString, { getDateStringStrict, getMostRecentWeekday } from "../Utils";
import { collection, query, orderBy, getDocs, limit, where } from "firebase/firestore";
import { firestore } from '../firebase';

const MarketOverviewHomeComponent = () => {
    const [SPYPercentChange, setSPYPercentChange] = useState(0.5);
    const [VTPercentChange, setVTPercentChange] = useState(-0.8);
    const [IWMPercentChange, setIWMPercentChange] = useState(0.3);
    const [DIAPercentChange, setDIAPercentChange] = useState(-0.2);
    const [SPYPrice, setSPYPrice] = useState(0.00);
    const [VTPrice, setVTPrice] = useState(0.00);
    const [IWMPrice, setIWMPrice] = useState(0.00);
    const [DIAPrice, setDIAPrice] = useState(0.00);
    const [latestHeadline, setLatestHeadline] = useState("");
    const [overallSentiment, setOverallSentiment] = useState("Bullish");
    const [sentimentShadowColor, setSentimentShadowColor] = useState("rgba(255, 255, 255, 0.3)");
    const [sentimentTextColor, setSentimentTextColor] = useState("rgba(255, 255, 255, 1.0)");
    const updateOverallSentimentBasedOnPrices = () => {
        if (getDateStringStrict() == false) {
            setOverallSentiment("Closed");
            return;
        }
        if (SPYPercentChange >= 0 && VTPercentChange >= 0) {
            setOverallSentiment("Bullish");
        } else if (SPYPercentChange < 0 && VTPercentChange < 0) {
            setOverallSentiment("Bearish");
        } else {
            console.log(`SPY: ${SPYPercentChange}, VT: ${VTPercentChange}`);
            setOverallSentiment("Neutral");
        }
    };

    useEffect(() => {
        updateOverallSentimentBasedOnPrices();
    }, [SPYPercentChange, VTPercentChange]);

    useEffect(() => {
        const getSentimentColor = () => {
            if (overallSentiment === "Bullish") {
                // console.log("Flow is Bullish overallSentiment:", overallSentiment);
                setSentimentShadowColor("rgba(9, 64, 32, 1.0)");
                setSentimentTextColor('#20d46b')
            } else if (overallSentiment === "Bearish") {
                // console.log("Flow is Bearish overallSentiment:", overallSentiment);
                setSentimentShadowColor("rgba(71, 20, 29, 1.0)");
                setSentimentTextColor('#cc3753')
            } else {
                // console.log("Flow is Neutral, overallSentiment:", overallSentiment);
                setSentimentShadowColor("rgba(0, 122, 252, 0.1)");
                setSentimentTextColor("rgba(255, 255, 255, 0.5)");
            }
        };

        getSentimentColor();
    }, [overallSentiment]);


    useEffect(() => {
        const fetchStockPrice = async (ticker, setPrice, setPercentChange) => {
            const mostRecentWeekDay = getMostRecentWeekday();
            const url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=${ticker}&date=${mostRecentWeekDay}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                const latestPrice = data.latestPrice;
                const prevClose = data.prevClose;
                const percentChange = ((latestPrice - prevClose) / prevClose) * 100;

                setPrice(latestPrice.toFixed(2));
                setPercentChange(percentChange.toFixed(2));
            } catch (error) {
                console.error(`Error fetching ${ticker} price:`, error);
            }
        };

        const fetchAllPrices = () => {
            fetchStockPrice('SPY', setSPYPrice, setSPYPercentChange);
            fetchStockPrice('VT', setVTPrice, setVTPercentChange);
            fetchStockPrice('IWM', setIWMPrice, setIWMPercentChange);
            fetchStockPrice('DIA', setDIAPrice, setDIAPercentChange);
        };

        fetchAllPrices();

        const intervalId = setInterval(fetchAllPrices, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        const fetchMarketSummary = async () => {
            try {
                const articleCollection = collection(firestore, "_market_overviews");
                const q = query(articleCollection, orderBy("unixTimestamp", "desc"), limit(1));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const articleData = querySnapshot.docs[0].data();
                    setLatestHeadline(articleData.overview);
                    // console.log(`Sentiment: ${articleData.sentiment}`);
                    // setOverallSentiment(articleData.sentiment);
                } else {
                    console.log("No articles found in the collection.");
                }
            } catch (error) {
                console.error("Error fetching the latest article:", error);
            }
        };

        fetchMarketSummary();
    }, []);
    return (
        <div
            style={{
                backgroundColor: "black",
                borderRadius: "10px",
                padding: "20px",
                position: "relative",
                boxShadow: `0 8px 15px rgb(0, 0, 0, 0.5)`,
                height: '350px',
                flex: 1,
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <h1
                    style={{
                        margin: 0,
                        fontWeight: "bold",
                        marginBottom: "20px",
                        textShadow: `0 0 20px ${sentimentShadowColor}`,
                    }}
                >
                    <span style={{ color: "white" }}>Markets Are </span>
                    <span style={{ color: sentimentTextColor, textShadow: `10px 2.5px 30px #cc3753` }}>{overallSentiment}</span>
                </h1>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", width: "330px" }}>
                    {['SPY', 'VT', 'IWM', 'DIA'].map((ticker) => (
                        <div
                            key={ticker}
                            style={{
                                backgroundColor: eval(`${ticker}PercentChange`) >= 0 ? "rgba(75, 183, 144, 0.3)" : "rgba(204, 55, 83, 0.3)",
                                borderRadius: "5px",
                                padding: "8px 10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                boxShadow:
                                    eval(`${ticker}PercentChange`) >= 0
                                        ? "0 4px 12px rgba(75, 183, 144, 0.275)"
                                        : "0 4px 12px rgba(204, 55, 83, 0.275)",
                            }}
                        >
                            <span style={{ color: "white", fontSize: "0.8em", fontWeight: 525 }}>
                                {ticker}
                            </span>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ color: "white", fontSize: "0.75em", fontWeight: 525 }}>
                                    ${eval(`${ticker}Price`)}
                                </span>
                                <span style={{
                                    color: eval(`${ticker}PercentChange`) >= 0 ? "#20d46b" : "#cc3753",
                                    fontSize: "0.75em",
                                    fontWeight: 525,
                                    marginLeft: "4px"
                                }}>
                                    ({eval(`${ticker}PercentChange`)}%)
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ position: "absolute", bottom: "20px", left: "20px", right: "20px" }}>
                <p style={{ color: "#A9A9A9", margin: 0, fontSize: "14px", fontWeight: "bold", marginBottom: "5px" }}>
                    Market Overview
                </p>
                <p style={{ color: "white", margin: 0, fontSize: "24px", fontWeight: "bold" }}>
                    {latestHeadline}
                </p>
            </div>
        </div>
    );
};

export default MarketOverviewHomeComponent;