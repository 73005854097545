import React from 'react';
import bull from './bullflow_logo_no_text_final.png';

const TermsOfService = () => {
    const styles = {
        logo: {
            height: '30px',
            margin: 0,
            padding: 0,
            marginRight: '10px', // Adjust this as needed
            cursor: 'pointer',
        },
        title: {
            margin: 0, // Remove the default margin
            padding: 0, // Remove the default padding
            cursor: 'pointer',
        },
    };
    return (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1em' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0em' }}>
            <img src={bull} alt="Logo" style={styles.logo} />
            <h2 style={styles.title}>BullFlow.io</h2>
        </div>
        <h1>Terms of Service</h1>
        <p style={{ color: 'grey', marginBottom: '1em' }}>Last updated 07/05/2023</p>
        <p style={{ border: '1px solid #FFD700', borderRadius: '5px', padding: '1em', width: '60%', textAlign: 'justify' }}>
            <b>REGISTRATION</b><br /><br />
            These BullFlow Terms of Service (the “Terms of Service” or “Agreement”)  is entered into by the authorized user (“You”) and BullFlow, and any of its affiliates, agencies, sponsors, licensees or content partners (hereafter, collectively referred to as “Bull Flow”, “We”, or “Us”) and governs your use of www.bullflow.io (the “Site”) and the information services, web sites, documents, content, services, functions, or features accessible on or through, or associated with, the Site (the “Products”). Your use of the Site and the Products is subject to the terms and conditions hereof. By using the Site or any of the associated Products owned, operated, licensed, or controlled by Us, you agree to be bound by these Terms of Service. Other provisions that govern your use of the Site may be set forth in online notices appearing in connection with certain Products (collectively, the “Additional Terms”), all of which are incorporated by reference into this Agreement. Your use of any Products that are subject to Additional Terms constitutes your acceptance of the respective Additional Terms.
            BY REGISTERING FOR OR USING THIS SITE, YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT. USE OF THIS SITE WILL CONSTITUTE YOUR ACCEPTANCE OF ALL OF SUCH TERMS OF SERVICE.
            * IF YOU DO NOT AGREE TO THESE "TERMS OF SERVICE", DO NOT USE THE SITE.
            The Terms of Service for your continued use of this Site will be available at https://bullflow.io/TermsOfService/. Please check this area before using this Site whenever a change has been indicated. Notifications of changes to the Terms of Service posted on the home page of the Site is an effective notice to You of the change in such terms and conditions. If You do not agree to these changes in the Terms of Service as they may occur, please arrange to terminate your registration with Us immediately (i) by notifying Us of your unwillingness to accept the changes to the Terms of Service and (ii) by discontinuing your use of the Site. Your continued use of Site after such changes in the Terms and Services will signify your acceptance of the change in the terms and conditions.
            <br /><br />1. PAYMENT AND ACCOUNT<br /><br />
            You agree to make all payments associated with your account and in consideration for your access to the Site and Products. Your initial Payment will occur at the time of registration and recurring subscription payments will be charged thereafter. You hereby verify and confirm that all information provided by You is correct and accurate to the best of your knowledge. We reserve the right to change the feels or applicable charges, or to institute new charges and fees upon thirty (30) days prior notice to You.
            As the Products are non-tangible, irrevocable, digital goods, We do not issue refunds. By accepting these Terms of Service, You hereby acknowledge and agree that no refunds of purchases shall be issued. During checkout, upon clicking the final submit button, your purchase is charged to the credit/debit card that You supplied. All sales are final. You cannot cancel a purchase or receive a refund for a purchase. You can cancel future recurring subscription payments at any time from your Account Settings page.
            If you are under a free trial period, you will be charged at the end of your trial unless cancelled. We do not provide refunds. All sales are final. By accessing the free trial period, You hereby acknowledge and agree to be charged a recurring subscription fee at the end of the trial period unless cancelled.
            You are entitled to ONE (1) free trial period. Our system automatically checks if you have already signed up for a free trial. If it is found that You have attempted to create multiple free trials, your free trial period will be immediately removed from the subsequent account or accounts and will be transitioned to a standard non-trial account. Any attempt to circumvent these Terms of Service will result in your account being banned without notice.
            You agree that you will use the Site and Products solely for your own personal benefit or internal company use. You agree that you will not share access to your account or share your password with anyone. Our security system regularly checks for simultaneous logins from multiple locations. If it is found that your account is being accessed simultaneously from multiple locations, the system will auto-lock the account for a minimum 24-hour period.
            You agree that You will not directly or indirectly copy, modify, reproduce, republish, distribute, transmit, or use the Site or the Products for any commercial or other purposes, except to the extent required in order for you to use the Site and the Products in the manner expressly provided herein.
            You represent, covenant, and warrant that You will use the Site and the Products for the informational purposes for which they are intended. You also agree to comply with all applicable laws and regulations while using the Site and Products. You hereby agree to indemnify and hold harmless BullFlow against any damages, losses, liabilities, settlements, and expenses in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from your use of the Site and Products. You agree that you will  not utilize automated systems or software to extract data from the Site. You further agree that you will not scrape or extract data from the Site for use in any external applications including but not limited to Discord, Slack, and Twitter. If You are determined to be violating this term, your account will be banned without notice and no refunds will be issued.
            * LEGAL ACTION WILL BE TAKEN WHERE DEEMED APPROPRIATE IF IT IS FOUND THAT YOU ARE REDISTRIBUTING OR RESELLING THE INFORMATION ON THE SITE.
            <br /><br />2. PROPRIETARY RIGHTS<br /><br />
            * THIS SITE AND ALL OF ITS CONTENTS ARE COPYRIGHTED. WITH AN ACTIVE SUBSCRIPTION, YOU ARE GRANTED A LIMITED, NON-EXCLUSIVE, NON-TRANSFERABLE, NON-SUBLICENSABLE LICENSE TO USE THE PRODUCTS FOR PERSONAL OR INTERNAL COMPANY USE ONLY. ALL RIGHTS NOT EXPRESSLY GRANTED TO YOU ARE RESERVED BY BullFlow
            Neither BullFlow nor any individual or organization that may be a source of content distributed through the Site makes any warranty as to the timeliness and accuracy of the information. The Products are provided for informational purposes only and do not constitute investment advice. Access to, transmission or receipt of, or reliance upon, content from the Site does not create, and is not intended to create, an investor/broker relationship between You and Us, any other person, or any of BullFlow’s content partners.
            Except for the limited license granted under these Terms of Service, all right, title, and interest in the Site and the Products (including all trademarks, copyrights, and other intellectual property rights), in all languages, formats and media throughout the world, are and will continue to be the exclusive property of BullFlow and/or our content partners. You agree that you will not decompile, reverse engineer, disassemble, rent, lease, loan, sublicense, or create derivative works from any of the Products or the Site.
            <br /><br />3. PRIVACY<br /><br />
            We respect your privacy rights! We will not sell or distribute your email address or other information without your express consent. Only BullFlow, our Agencies, Attorneys, and Partners are entitled to your information. For more information, please see the BullFlow Privacy Policy.
            <br /><br />4. NO REPRESENTATIONS OR WARRANTIES; LIMITATION OF LIABILITY<br /><br />
            BullFlow shall use reasonable efforts consistent with prevailing industry standards to maintain the Site and Products in a manner which minimizes errors and interruptions. The Site may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by BullFlow or by third-party providers, or because of causes beyond BullFlow’s reasonable control, but We shall use reasonable efforts to provide advance notice in writing or by email of any unscheduled service disruption. However, We do not warrant that the Site and Products will be uninterrupted or error free.
            Neither BullFlow, its agents, attorneys, members, nor affiliates make any warranty or representation that the information is accurate and timely. You hereby understand and acknowledge that use of the Site and of the Products is undertaken at your own risk. Any information or data provided by BullFlow on the Site or in the Products is not exhaustive or complete. Neither BullFlow nor any content partner assumes any liability for your use or interpretation of any Product. The Site and the Products are provided “as is” without warranty of any kind, either expressed or implied, including, but not limited to, implied warranties with respect to performance, merchantability, fitness for a particular purpose, noninfringement of third party rights, accuracy, omissions, completeness, currentness, or timeliness. You acknowledge that the presentation of the Site and Products entails the risk of some human and machine errors, delays, interruptions and losses, including the inadvertent loss of data and You agree to bear that risk.
            In no event will BullFlow, its affiliates, partners, members, managers, directors, officers, employees or agents be liable to you or any third person for any indirect, consequential, exemplary, incidental, special, or punitive damages, including for any lost profits or lost data arising from your use of the Site, or any of the Products on, accessed through, or downloaded from the Site, even if BullFlow or its affiliates, partners, members, managers, directors, officers, employees or agents are aware of or have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, the liability of BullFlow and its affiliates, partners, members, managers, directors, officers, employees and agents to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by You to BullFlow in the immediately preceding twelve months, but in no case will such liability to you exceed $1000. You acknowledge that if no fees have been paid to BullFlow for the Site in the preceding 12 months, You shall be limited to injunctive relief only, unless otherwise permitted by law, and shall not be entitled to damages of any kind, regardless of the cause of action. Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to You, some or all of the above disclaimers, exclusions or limitations may not apply to You, and You may have additional rights.
            <br /><br />5. DISCLAIMER<br /><br />
            there is a high degree of risk involved in trading stocks and options. past results are not indicative of future returns. BullFlow is not a registered investment advisor nor is it licensed as such with any federal or state regulatory agency. BullFlow does not manage client assets in any way. information provided and opinions expressed on the site and in the products do not constitute investment advice. any and all ideas expressed, research, tutorials, and instructional resources are for educational purposes only. the ideas expressed are solely the opinions of BullFlow. we advise everyone to know the risks involved with trading stocks and options. we encourage every visitor to the site to do their own research and to consult with their own financial advisor prior to engaging in any investing activities, and to make sure they know all the potential risks involved. any investment decision that results in losses or gains made based on any information on this site, or related services is not the responsibility of BullFlow. your use of the products provided by BullFlow are at your own risk and it is your sole responsibility to evaluate the accuracy, completeness, and usefulness of the information.
            <br /><br />6. MISCELLANEOUS<br /><br />
            if any provision of this agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this agreement will otherwise remain in full force and effect and enforceable.  this agreement is not assignable, transferable or sub-licensable by you except with BullFlow’s prior written consent.  BullFlow may transfer and assign any of its rights and obligations under this agreement without consent.  this agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.  no agency, partnership, joint venture, or employment is created as a result of this agreement and you do not have any authority of any kind to bind BullFlow in any respect whatsoever.  in any action or proceeding to enforce rights under this agreement, the prevailing party will be entitled to recover costs and attorneys’ fees.  all notices under this agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.  this agreement shall be governed by the laws of the state of california without regard to its conflict of laws provisions.
            we reserve the right to refuse service to anyone for any reason at any time. we may, at our sole discretion, terminate or suspend access to our service immediately without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms of service. all provisions of the terms of service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

        </p>
    </div>)
};

export default TermsOfService;
