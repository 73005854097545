import { useState, useEffect } from 'react';
import { Slider } from 'antd';
import './bullflowvolumeslider.css';

function Last7DaysBullflowVolume({ maxLast7DaysBFVolumeProp, handleFilterChange }) {
    const [maxLast7DaysBFVolume, setMaxLast7DaysBfVolume] = useState(maxLast7DaysBFVolumeProp);

    const onChange = (newValue) => {
        if (newValue >= 500) {
            newValue = 500;
        }
        handleFilterChange(newValue);
    };

    useEffect(() => {
        setMaxLast7DaysBfVolume(maxLast7DaysBFVolumeProp);
    }, [maxLast7DaysBFVolumeProp]);

    const tipFormatter = (sliderValue) => {
        return `${sliderValue}`;
    };

    return (
        <div style={{ width: '90%', marginTop: '0px' }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                fontWeight: 600,
                fontSize: '0.8em',
                marginBottom: '0px'
            }}>
                <p style={{ marginBottom: '0px' }}>Bullflow Volume, Last 5 Days</p>
            </div>
            <div style={{ marginTop: '0px' }}>
                <Slider
                    className='bfvolume-gradient-slider'
                    min={0}
                    max={500}
                    onChange={onChange}
                    value={maxLast7DaysBFVolume}
                    tooltip={{
                        formatter: tipFormatter,
                        visible: true,
                        zIndex: 15001
                    }}
                />
            </div>
        </div>
    );
}

export default Last7DaysBullflowVolume;
