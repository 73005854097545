import React, { useEffect } from "react";
import { Container, ThemeProvider, createTheme } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from './firebase';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import googleLogoPath from './g_icon.svg';
import { TwitterAuthProvider } from "firebase/auth";
import xLogoWhite from './x-logo-white.png';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white" // Change this as per your requirement
                    },
                    "&:hover": {
                        color: "white" // Change this as per your requirement
                    },
                }
            }
        }
    }
});

const SignInWithPromoScreen = () => {
    console.log("SignInWithPromoScreen");
    const navigate = useNavigate();
    const auth = getAuth();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // console.log("User is already signed in" + user.email);

                // Define async function
                const checkPaymentsAndNavigate = async () => {
                    console.log("Check pay");
                    const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", user.email));

                    // Execute the query
                    const paymentQuerySnapshot = await getDocs(paymentsQuery);

                    // Checks if any matching documents were found
                    const paymentDocExists = !paymentQuerySnapshot.empty;

                    if (paymentDocExists) { // checks if the payment exists in both collections
                        // Get the first document from the query snapshot
                        const paymentDoc = paymentQuerySnapshot.docs[0];
                        const subscriptionType = paymentDoc.data().subscriptionType;

                        if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                            // Handle subscription type
                            // console.log(`User has a ${subscriptionType} subscription.`);
                            navigate('/app');
                        } else {
                            navigate('/RedeemACode');
                            console.log('User does not have a monthly or yearly subscription.');
                        }

                    } else {
                        await setDoc(doc(collection(firestore, "users"), user.email), {
                            username: user.displayName,
                            email: user.email,
                        });
                        navigate('/RedeemACode');
                    }
                }
                checkPaymentsAndNavigate();
            } else {
                console.log("No user is signed in");
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth, navigate]);

    const handleTwitterSignup = async () => {
        const provider = new TwitterAuthProvider();
        window.gtag('event', 'Sign up with X Button', {
            'event_category': 'Button',
            'event_label': 'Sign up with X',
            'value': 1
        });
        try {
            const result = await signInWithPopup(auth, provider);
            // Handle the sign-in result here (e.g., update user profile, navigate)
            const email = result.user.email;
            if (email) {
                console.log("Twitter sign-in successful " + email);
                performLoginCheckProcess(email);
            } else {
                console.log("Twitter sign-in successful, no email returned");
            }
        } catch (error) {
            console.error("Error signing up with Twitter:", error);
        }
    };

    const handleGoogleSignup = async () => {
        window.gtag('event', 'Sign in with promo button', {
            'event_category': 'Button',
            'event_label': 'Sign in with promo',
            'value': 1
        });
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            performLoginCheckProcess(user.email);
        } catch (error) {
            console.error("Error signing up with Google: ", error);
        }
    };

    const performLoginCheckProcess = async (email) => {
        const userDoc = await getDoc(doc(collection(firestore, "users"), email));

        // Create a query against the collection
        const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", email));

        // Execute the query
        const paymentQuerySnapshot = await getDocs(paymentsQuery);

        // Checks if any matching documents were found
        const paymentDocExists = !paymentQuerySnapshot.empty;

        if (userDoc.exists() && paymentDocExists) { // checks if the user exists in both collections
            // Get the first document from the query snapshot
            const paymentDoc = paymentQuerySnapshot.docs[0];
            const subscriptionType = paymentDoc.data().subscriptionType;

            if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                // Handle subscription type
                // console.log(`User has a ${subscriptionType} subscription.`);
                navigate('/app');
            } else {
                console.log('User does not have a monthly or yearly subscription.');
                navigate('/RedeemACode');
            }

        } else {
            await setDoc(doc(collection(firestore, "users"), email), {
                email: email,
            });
            console.log('Nav to RedeemACode.');
            navigate('/RedeemACode');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main"
                maxWidth="xs"
                style={{
                    display: 'flex', flexDirection: 'column', height: '50vh',
                    marginTop: '100px'
                }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ color: 'white', margin: '20px 0' }}>Continue to Redeem Code</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <button
                        onClick={handleTwitterSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#15202B',
                            color: '#fff',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            outline: 'none',
                            textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Arial, sans-serif',
                            height: '45px',
                        }}
                        type="button"
                    >
                        Continue with
                        <img
                            src={xLogoWhite}
                            alt="Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '10px', // Add some space between the text and the logo
                            }}
                        />
                    </button>
                    <button
                        onClick={handleGoogleSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Added to center the contents
                            backgroundColor: '#4285F4',
                            color: '#fff',
                            border: 'none',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            outline: 'none',
                            height: '45px',
                            marginTop: '15px',
                            width: '100%', // Width is set to 'auto'
                        }}
                    >
                        <img
                            src={googleLogoPath}
                            alt="Google Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                                backgroundColor: '#fff',
                                padding: '2px',
                                borderRadius: '2px',
                            }}
                        />
                        Sign in with Google
                    </button>
                </div>
            </Container>
        </ThemeProvider>
    );


};

export default SignInWithPromoScreen;
