import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Input } from 'antd';


const useStyles = makeStyles({
  root: {
    width: '100%', // add this line
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey',
    },
    '& .MuiInputLabel-root': {
      color: 'grey',
    },
    '&:hover .MuiInputLabel-root': {
      color: 'grey',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInputBase-input': {
      color: 'white',
      height: '1.5em',
      padding: '10px 12px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    // marginBottom: '10px',
    fontWeight: 'bold'
  },
  mainTitle: {
    marginLeft: '10px',
    marginRight: '5px',
    fontSize: '0.8em',
  },
  subtitle: {
    color: 'grey',
    fontSize: '0.8em',
  },
  input: {
    margin: '0 10px',
    width: 'calc(50% - 20px)', // assuming there are 2 fields with 10px margin each, adjust accordingly

  },
});

function PriceToPremiumFilterFields({ minMultiplierProp, maxMultiplierProp, handleFilterChange }) {
  const classes = useStyles();
  const [minMultiplier, setMinMultiplier] = useState(minMultiplierProp);
  const [maxMultiplier, setMaxMultiplier] = useState(maxMultiplierProp);

  const handleChange = (field, value) => {
    if (field === 'min multiplier') setMinMultiplier(value);
    if (field === 'max multiplier') setMaxMultiplier(value);
    handleFilterChange(field, value);
  };
  useEffect(() => {
    setMinMultiplier(minMultiplierProp);
  }, [minMultiplierProp]);

  useEffect(() => {
    setMaxMultiplier(maxMultiplierProp);
  }, [maxMultiplierProp]);

  return (
    <div>
      <style type="text/css">
        {`
      #min-score::placeholder, #max-score::placeholder {
        color: #6B7280; 
      }
    `}
      </style>
      <div className={classes.root}>
        <Input
          id="min-score"
          placeholder="Min Multiplier"
          className={`${classes.input}`}
          value={minMultiplier}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
          variant="outlined"
          onChange={event => handleChange('min multiplier', event.target.value)}
        />
        <Input
          id="max-score"
          placeholder="Max Multiplier"
          className={`${classes.input}`}
          value={maxMultiplier}
          style={{
            backgroundColor: '#000',
            border: '1px solid #000',
            color: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
          variant="outlined"
          onChange={event => handleChange('max multiplier', event.target.value)}
        />
      </div>
    </div>
  );
}

export default PriceToPremiumFilterFields;
