import { Home, Newspaper, Waves, LineChart, History } from "lucide-react";
import Bull from '../bull_2.png';
import { useNavigate } from "react-router-dom";
import { ResizablePanel, ResizablePanelGroup, ResizableHandle } from "../components/ui/resizable";
import Sidebar from "../Sidebar";

function NewChartsPage() {
    const navigate = useNavigate();

    return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row', background: '#1a1b20' }}>
            {/* Left nav column with logo and icons */}
            <Sidebar activeItem="charts" />

            {/* Center column with Title row, details, and content */}
            <div style={{ flex: 1, marginRight: '20px', marginTop: '10px', marginLeft: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#1a1b20' }}>
                {/* Top Bar */}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 0, padding: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', alignItems: 'center' }}> {/* Adjust gap as needed */}
                        <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', fontSize: '1.35em' }}>Charts</h1>
                    </div>
                    <></>
                </div>

                {/* Content */}
                <div style={{ width: '100%', marginTop: '20px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: '20px' }}>
                    <ResizablePanelGroup direction="horizontal">
                        <ResizablePanel>One</ResizablePanel>
                        <ResizableHandle />
                        <ResizablePanel>Two</ResizablePanel>
                    </ResizablePanelGroup>
                </div>
            </div>
        </div>
    );
}

export default NewChartsPage;