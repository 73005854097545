import React from 'react';
import './VideoBorderStyles.css';
import { motion, useAnimation } from 'framer-motion';
import { InView } from 'react-intersection-observer';

const FullWidthVideo = () => {
    const videoSrc = "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/Quick%20Filters%20updated.mp4?alt=media&token=10de904d-314b-4b39-865d-a1229119c289";

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100vh',
        marginTop: '150px',
        marginBottom: '150px',
    };

    const titleStyle = {
        margin: '0 0 20px 0',
        fontSize: '2.5em',
        fontWeight: 'bold',
    };

    const subtitleStyle = {
        margin: '0 0 20px 0',
        fontSize: '1.2em',
        color: '#667',
    };

    const titleAnimation = {
        initial: { y: 30, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    };

    const subtitleAnimation = {
        initial: { y: 30, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.25 } },
    };

    return (
        <div style={containerStyle}>
            <InView threshold={0.5} triggerOnce>
                {({ inView, ref }) => (
                    <motion.h1
                        ref={ref}
                        style={titleStyle}
                        variants={titleAnimation}
                        initial="initial"
                        animate={inView ? 'visible' : 'initial'}
                    >
                        Spot Unusual Trades, Easier Than Ever
                    </motion.h1>
                )}
            </InView>
            <InView threshold={0.5} triggerOnce>
                {({ inView, ref }) => (
                    <motion.h4
                        ref={ref}
                        style={subtitleStyle}
                        variants={subtitleAnimation}
                        initial="initial"
                        animate={inView ? 'visible' : 'initial'}
                    >
                        Robust quick filter tags allow you to effortlessly curate the flow for your strategy
                    </motion.h4>
                )}
            </InView>
            <video className="video-style" src={videoSrc} autoPlay muted loop>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default FullWidthVideo;