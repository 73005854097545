import { useState } from 'react';

const TextButton = ({ onClick, text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: isHovered ? '#202126' : '#1f2026',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '90px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s ease',
        transition: 'box-shadow 0.3s ease',
        boxShadow: isHovered ? '0px 4px 6px rgba(0, 0, 0, 0.5)' : '0px 2px 3px rgba(0, 0, 0, 0.3)',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 500,
    };

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <button
            style={buttonStyle}
            className="text-button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick}
        >
            {text}
        </button>
    );
};

export default TextButton;