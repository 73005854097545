import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import { isTradingDay, isTradingHours } from '../Utils';
import { Spinner } from '@nextui-org/react';

const hexToRGBA = (hex, opacity) => {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Extract styles outside the component to avoid redefinition
const pulseAnimation = (boxShadowColor, boxShadowColorFaint) => `
  @keyframes pulseAnimation {
    0% {
      box-shadow: 0 0 1px 1px ${boxShadowColor};
    }
    50% {
      box-shadow: 0 0 3px 3px ${boxShadowColorFaint};
    }
    100% {
      box-shadow: 0 0 1px 1px ${boxShadowColor};
    }
  }
`;

const PulsingDot = ({ showLoading }) => {
  if (showLoading) {
    return <Spinner size="sm" color="secondary" />;
  }

  let today = new Date();
  let color;
  let tradingHoursText;

  if (isTradingDay(today) && isTradingHours()) {
    color = '#20d46b';
    tradingHoursText = 'Markets are open';
  } else {
    color = '#cc3753';
    tradingHoursText = 'Markets are closed';
  }

  const boxShadowColor = hexToRGBA(color, 0.7);
  const boxShadowColorFaint = hexToRGBA(color, 0.3);

  return (
    <>
      <style>{pulseAnimation(boxShadowColor, boxShadowColorFaint)}</style>
      <Tooltip title={tradingHoursText}>
        <div style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: color,
          animation: 'pulseAnimation 4s infinite',
          marginTop: '4px',
          marginLeft: '2px',
        }}></div>
      </Tooltip>
    </>
  );
};

export default memo(PulsingDot);
