import React, { useState, useEffect } from 'react';
import { Slider, Input } from 'antd';
import './bullflowvolumeslider.css';

function CurrentVolumeFilter({ maxCurrentVolumeProp, handleFilterChange }) {
    const [maxCurrentVolume, setMaxCurrentVolume] = useState(maxCurrentVolumeProp);

    useEffect(() => {
        setMaxCurrentVolume(maxCurrentVolumeProp);
    }, [maxCurrentVolumeProp]);

    const onChangeSlider = (newValue) => {
        if (newValue >= 1000000) {
            newValue = 1000000;
        }
        setMaxCurrentVolume(newValue);
        handleFilterChange(newValue);
    };

    const onChangeInput = (e) => {
        const newValue = Number(e.target.value);
        if (newValue >= 0 && newValue <= 1000000) {
            setMaxCurrentVolume(newValue);
            handleFilterChange(newValue);
        }
    };

    return (
        <div style={{ width: '90%', marginTop: '0px' }}>
            <p style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '10px' }}>Current Volume</p>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '0px'
            }}>
                {/* Slider takes ~80% of the width */}
                <Slider
                    className='bfvolume-gradient-slider'
                    min={0}
                    max={100000}
                    onChange={onChangeSlider}
                    value={maxCurrentVolume}
                    style={{ flexGrow: 7.5, marginRight: '10px' }} // Adjusted for roughly 80% width
                    tooltip={{
                        formatter: value => `${value}`,
                        visible: true,
                        zIndex: 15001
                    }}
                />
            </div>
        </div>
    );

}

export default CurrentVolumeFilter;
