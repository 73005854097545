import { ReactNode } from 'react';
import { useRef, useState } from 'react';
import { LightningBoltIcon } from "@radix-ui/react-icons";

// Define an interface for props (if using TypeScript)
interface CardSpotlightProps {
  title: string;
  description: string;
  icon: ReactNode; // Allows any React node to be passed in, providing flexibility for icons
}

export const SpotlightCard = ({ title, description, icon }: CardSpotlightProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  return (
    <div
      ref={divRef}
      onMouseMove={handleMouseMove}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative max-w-sm rounded-3xl border border-neutral-800 bg-neutral-950 p-10"
    >
      <div
        className="pointer-events-none absolute -inset-px opacity-0 transition duration-500 rounded-3xl"
        style={{
          opacity,
          background: `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(255,255,255,.25), transparent 40%)`,
          borderRadius: 'inherit',
        }}
      />
      <div className="mb-5">
        {icon}
      </div>
      <h3 className="mb-2.5 font-medium tracking-tight text-neutral-100">
        {title}
      </h3>
      <p className="text-sm text-neutral-400">
        {description}
      </p>
    </div>
  );
};
