import { useState, useEffect } from 'react';
import { Slider } from 'antd';

function OTMFilterFields({ minOTMProp, maxOTMProp, handleFilterChange }) {
  const [minOTM, setMinOTM] = useState(minOTMProp);
  const [maxOTM, setMaxOTM] = useState(maxOTMProp);

  const handleChange = (values) => {
    // Check if the slider's min value is at its minimum position
    const adjustedMinValue = values[0] === -500 ? -1000000 : values[0];
    // Check if the slider's max value is at its maximum position
    const adjustedMaxValue = values[1] === 500 ? 1000000 : values[1];
    setMinOTM(adjustedMinValue);
    setMaxOTM(adjustedMaxValue);
    handleFilterChange('min OTM', adjustedMinValue);
    handleFilterChange('max OTM', adjustedMaxValue);
  };

  useEffect(() => {
    setMinOTM(minOTMProp);
  }, [minOTMProp]);

  useEffect(() => {
    setMaxOTM(maxOTMProp);
  }, [maxOTMProp]);

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 600,
        fontSize: '0.8em',
      }}>
        <p style={{ marginBottom: '0px' }}>Out of the Money %</p>
      </div>
      <div style={{ marginTop: '0px', marginBottom: '0px' }}>
        <Slider
          range
          min={-500}
          max={500}
          value={[minOTM, maxOTM]}
          onChange={handleChange}
          style={{
            color: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between', marginBottom: '0px', marginTop: '0px'
      }}>
        <p style={{ fontSize: '0.7em', fontWeight: 600, color: 'GrayText', marginTop: '0px', marginBottom: '0px' }}>-∞</p>
        <p style={{ fontSize: '0.7em', fontWeight: 600, color: 'GrayText', marginTop: '0px', marginBottom: '0px' }}>∞</p>
      </div>
    </div>
  );
}

export default OTMFilterFields;
