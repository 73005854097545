import { user } from "@nextui-org/react";
import { firestore } from "../firebase";
import { getFirestore, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc } from "firebase/firestore";

export async function getUserFavTickersByEmail(userEmail) {
    try {
        const tickersCollection = collection(firestore, "_user_fav_tickers");
        const q = query(tickersCollection, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            console.log("No matching documents found.");
            return [];
        }
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        const tickersList = data?.fav_tickers || [];
        return tickersList;
    } catch (error) {
        console.error("Error fetching user favorite tickers: ", error);
        throw error;
    }
}
export async function addUserFavTicker(userEmail, ticker) {
    try {
        if (userEmail === null || userEmail === "" || ticker === null || ticker === "") {
            return;
        }
        console.log(`Adding ticker ${ticker} to favorites for email: `, userEmail);
        const tickersCollection = collection(firestore, "_user_fav_tickers");
        const q = query(tickersCollection, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            // Create a new document with the user's email and the ticker
            await addDoc(tickersCollection, {
                email: userEmail,
                fav_tickers: [ticker]
            });

            console.log(`Ticker ${ticker} successfully added to favorites for a new user.`);
            return;
        }

        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;
        await updateDoc(docRef, {
            fav_tickers: arrayUnion(ticker)
        });

        console.log(`Ticker ${ticker} successfully added to favorites.`);
    } catch (error) {
        console.error("Error adding ticker to user favorites: ", error);
    }
}


export async function removeUserFavTicker(userEmail, ticker) {
    try {
        if (userEmail === null || userEmail === "" || ticker === null || ticker === "") {
            return;
        }
        console.log(`Removing ticker ${ticker} from favorites for email: `, userEmail);
        const tickersCollection = collection(firestore, "_user_fav_tickers");
        const q = query(tickersCollection, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.error("No matching document found for the provided email.");
            throw new Error("No matching document found.");
        }
        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;
        await updateDoc(docRef, {
            fav_tickers: arrayRemove(ticker)
        });

        console.log(`Ticker ${ticker} successfully removed from favorites.`);
    } catch (error) {
        console.error("Error removing ticker from user favorites: ", error);
    }
}