import { useState } from 'react';
import { CopyIcon, CopyCheck } from 'lucide-react';
import { toast } from 'sonner';

const ContactPage = () => {
    const [isCopied, setIsCopied] = useState(false);

    const copyEmail = () => {
        navigator.clipboard.writeText('support@bullflow.io');
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        toast('Email copied to clipboard!');
    };

    return (
        <div style={{ height: '90vh', width: '100%', textAlign: 'center' }}>
            <div style={{ display: 'inline-block', marginBottom: '10px', marginTop:'50px' }}>
                <h1
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        marginTop: '20px',
                        marginBottom: '1rem',
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline',
                    }}
                >
                    For account support, questions, or feature requests, send us an email at:
                </h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p
                    style={{
                        color: 'white',
                        margin: '0',
                        fontWeight: 'normal',
                        fontSize: '1.25em',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    support@bullflow.io
                    <span style={{ marginLeft: '5px' }}>
                        {isCopied ? (
                            <CopyCheck size={12} color="green" />
                        ) : (
                            <CopyIcon size={12} color="#A9A9A9" style={{ cursor: 'pointer' }} onClick={copyEmail} />
                        )}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default ContactPage;