import { Search, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";

interface TickerSearchBoxProps {
    onChange: (value: string) => void;
    onEnter: () => void;
    onClear: () => void;
    initialInputValue?: string;
}

export const TickerSearchBox = ({ onChange, onEnter, onClear, initialInputValue }: TickerSearchBoxProps) => {

    const divRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (initialInputValue !== undefined) {
            setInputValue(initialInputValue);
        }
    }, [initialInputValue]);
    const handleClear = () => {
        setInputValue('');
        onClear();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filteredValue = e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase();
        if (inputValue.length >= 1 && filteredValue.length === 0) {
            setInputValue('');
            onClear();
        }
        else {
            setInputValue(filteredValue);
        }
        onChange(filteredValue);
    };
    const handleEnter = () => {
        onEnter();
    }
    return (
        <>
            <div className="relative w-60">
                <input
                    onChange={handleChange}
                    value={inputValue}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleEnter();
                        }
                    }}
                    autoComplete="off"
                    placeholder="Ticker Search"
                    type="text"
                    name="search"
                    className="h-8 w-full pl-3.5 pr-10 cursor-default rounded-md border border-black bg-neutral-950 text-sm text-slate-100 transition-colors duration-500 placeholder:select-none placeholder:text-neutral-500 placeholder:text-sm focus:border-\[rgb(0\_0\_0)\] focus:outline-none shadow-md shadow-black/30"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    {inputValue.length >= 1 ? (
                        <X size={15} strokeWidth={1} onClick={handleClear} />
                    ) : (
                        <Search size={15} strokeWidth={1} />
                    )}
                </div>
                <input
                    ref={divRef}
                    disabled
                    style={{
                        border: "1px solid rgb(0 0 0)",
                    }}
                    aria-hidden="true"
                    className="border-[rgb(0 0 0)] pointer-events-none absolute left-0 top-0 z-10 h-8 w-full cursor-default rounded-md border bg-[transparent] p-3.5 opacity-0 transition-opacity duration-500 placeholder:select-none"
                />
            </div >
        </>
    );
};
