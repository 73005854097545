import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Slider } from 'antd';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  mainTitle: {
    marginLeft: '10px',
    marginRight: '5px',
    fontSize: '0.8em',
  },
  subtitle: {
    color: 'grey',
    fontSize: '0.8em',
  },
  slider: {
    margin: '0 10px',
    width: 'calc(100% - 20px)',
  },
});

function OptionPriceFields({ minOptionPriceProp, maxOptionPriceProp, handleFilterChange }) {
  const classes = useStyles();
  const [minOptionPrice, setMinOptionPrice] = useState(minOptionPriceProp);
  const [maxOptionPrice, setMaxOptionPrice] = useState(maxOptionPriceProp);

  const handleChange = (values) => {
    const newMaxOptionPrice = values[1] === 100 ? 1000000 : values[1];
    setMinOptionPrice(values[0]);
    setMaxOptionPrice(newMaxOptionPrice);
    handleFilterChange('min option price', values[0]);
    handleFilterChange('max option price', newMaxOptionPrice);
  };

  const formatTooltip = (value) => {
    if (value === 100) {
      return 'Infinite';
    }
    return value;
  };
  useEffect(() => {
    setMinOptionPrice(minOptionPriceProp);
  }, [minOptionPriceProp]);

  useEffect(() => {
    setMaxOptionPrice(maxOptionPriceProp);
  }, [maxOptionPriceProp]);

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          fontWeight: 600,
          fontSize: '0.8em',
        }}
      >
        <p style={{ marginBottom: '0px' }}>Contract Price</p>
      </div>
      <div style={{ marginTop: '0px', marginBottom: '0px' }}>
        <Slider
          range
          min={0}
          max={100}
          value={[minOptionPrice, maxOptionPrice === 1000000 ? 100 : maxOptionPrice]}
          onChange={handleChange}
          tipFormatter={formatTooltip}
          style={{ color: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '0px',
          marginTop: '0px',
        }}
      >
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          0
        </p>
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          ∞
        </p>
      </div>
    </div>
  );
}

export default OptionPriceFields;