import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { CircularProgress } from '@mui/material';

const BlogListings = () => {
    const blogs = {
        "blocksVsSweepsKnowingTheDifference": {
            title: "Sweeps, Blocks, and Splits - Knowing the Difference",
            url: "https://bullflow.io/blog/sweeps-blocks-splits-knowing-the-difference",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/DALL%C2%B7E%202024-01-20%2011.19.02%20-%20Create%20an%20animated-style%20blog%20thumbnail%20for%20a%20finance-related%20article%2C%20focusing%20on%20stock%20options%20trading.%20The%20design%20should%20be%20vibrant%20and%20engaging%2C%20w.png?alt=media&token=5ba3986f-d565-415e-a5c2-cf7991169f88",
            date: "01/18/2024"
        },
        "readingUnusualOptionsFlow": {
            title: "Reading Unusual Options Flow",
            url: "https://bullflow.io/blog/reading-unusual-options-flow",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/DALL%C2%B7E%202024-01-20%2011.21.11%20-%20Design%20an%20animated-style%20blog%20thumbnail%20for%20a%20finance-related%20article%20about%20'Reading%20Unusual%20Options%20Flow'.%20The%20thumbnail%20should%20be%20dynamic%20and%20colorf.png?alt=media&token=e302382c-8c0e-49a2-9438-710cea52603a",
            date: "01/17/2024"
        },
        "blogpost1": {
            title: "Real Time Options Basics",
            url: "https://bullflow.io/blog/real-time-options-basics",
            imageUrl: "https://thumbor.forbes.com/thumbor/fit-in/x/https://www.forbes.com/advisor/in/wp-content/uploads/2022/05/finance-g89edbb884_1280-Cropped.jpg",
            date: "08/25/2023"
        },
        "greeks": {
            title: "Decoding the Greek: Delta, Gamma, Theta, and Vega in Options",
            url: "https://bullflow.io/blog/decoding-the-greek-delta-gamma-theta-and-vega-in-options",
            imageUrl: "https://media.marketrealist.com/brand-img/RUK_hvjwz/0x0/option-greeks-1617921651521.png",
            date: "07/29/2023"
        },
        "the wheel": {
            title: "Mastering the Wheel Strategy in Options Trading",
            url: "https://bullflow.io/blog/mastering-the-wheel-srategy-in-options-trading",
            imageUrl: "https://static.wixstatic.com/media/36b442_3ab8243251ea4650be5466231966d0e3~mv2.png/v1/fill/w_800,h_600,al_c,q_90/36b442_3ab8243251ea4650be5466231966d0e3~mv2.png",
            date: "07/31/2023"
        },
        "time decay": {
            title: "Time Decay in Options: An In-depth Look at Theta",
            url: "https://bullflow.io/blog/time-decay-in-options-in-depth-look-at-theta-decay",
            imageUrl: "https://www.projectfinance.com/wp-content/uploads/2022/01/Option-Value-vs-Time-To-Expiration.png",
            date: "08/01/2023"
        },
        "top5CheapestUnusualOptions": {
            title: "Top 5 Cheapest Unusual Options Flow Platforms",
            url: "https://bullflow.io/blog/top-5-cheapest-unusual-options-flow-platforms",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/cheap_unusual_options_platforms.png?alt=media&token=c30679f1-634f-4118-9978-0eaaf3edb4d4",
            date: "01/21/2024"
        },
        "sweepsblog": {
            title: "Sweep Trades",
            url: "https://bullflow.io/blog/understanding-options-sweep-trades",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/sweeps_blog_screenshot.png?alt=media&token=3b070d1d-b031-4766-9060-2e1fcb3b7e1a",
            date: "03/18/2024"
        },
        "blocksblog": {
            title: "Block Trades",
            url: "https://bullflow.io/blog/understanding-options-block-trades",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/sweeps_blog_screenshot.png?alt=media&token=3b070d1d-b031-4766-9060-2e1fcb3b7e1a",
            date: "03/18/2024"
        },
        "splitsblog": {
            title: "Split Trades",
            url: "https://bullflow.io/blog/understanding-options-split-trades",
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/splits_screenshot.png?alt=media&token=44811d3b-a67f-476b-a9a6-e27c287c6933",
            date: "03/18/2024"
        },
    }
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 550);
        return () => {
            clearTimeout(timer);
        };
    });
    return (
        <div>
            <Helmet>
                <title>Blogs</title>
                <meta name="description" content="List of our blogs all relating to Real Time Options Flow and the stock market." />
                <meta name="keywords" content="Blogs, Real Time Options Basics" />
            </Helmet>
            {loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh' 
                }}>
                    <CircularProgress /> {/* MUI Loading Spinner */}
                </div>
            ) : (
                <div>
                    <h1 style={{ textAlign: 'center', fontSize: '1.5em', fontWeight: 'bold' }}>Blogs</h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '1200px', marginTop:'20px' }}>
                        {Object.entries(blogs).map(([blogId, blog]) => (
                            <div key={blogId} style={{ width: 'calc(50% - 20px)', marginBottom: '20px', textAlign: 'center', padding: '0 10px' }}>
                                <a href={blog.url} target="_blank" rel="noopener noreferrer" style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}>
                                    {blog.imageUrl && <img src={blog.imageUrl} alt={blog.title} style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '10px' }} />}
                                    <h2 style={{fontWeight: 550, marginTop: '10px', fontSize:'1.3rem'}}>{blog.title}</h2>
                                    <p style={{ color: "grey", margin: "0" }}>{blog.date}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogListings;
