import { useState, useEffect } from 'react';
import { Slider } from 'antd';

function SpotPriceFields({ minSpotPriceProp, maxSpotPriceProp, handleFilterChange }) {
  const [minSpot, setMinSpot] = useState(minSpotPriceProp);
  const [maxSpot, setMaxSpot] = useState(maxSpotPriceProp);

  const handleChange = (values) => {
    const newMaxSpot = values[1] === 7500 ? 100000 : values[1];
    setMinSpot(values[0]);
    setMaxSpot(newMaxSpot);
    handleFilterChange('min spot', values[0]);
    handleFilterChange('max spot', newMaxSpot);
  };

  const formatTooltip = (value) => {
    if (value === 7500) {
      return 'Infinite';
    }
    return value;
  };

  useEffect(() => {
    setMinSpot(minSpotPriceProp);
  }, [minSpotPriceProp]);

  useEffect(() => {
    setMaxSpot(maxSpotPriceProp);
  }, [maxSpotPriceProp]);

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          fontWeight: 600,
          fontSize: '0.8em',
        }}
      >
        <p style={{ marginBottom: '0px' }}>Underlying Spot Price</p>
      </div>
      <div style={{ marginTop: '0px', marginBottom: '0px' }}>
        <Slider
          range
          min={0}
          max={7500}
          value={[minSpot, maxSpot === 100000 ? 7500 : maxSpot]}
          onChange={handleChange}
          tipFormatter={formatTooltip}
          style={{ color: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '0px',
          marginTop: '0px',
        }}
      >
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          0
        </p>
        <p
          style={{
            fontSize: '0.7em',
            fontWeight: 600,
            color: 'GrayText',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        >
          ∞
        </p>
      </div>
    </div>
  );
}

export default SpotPriceFields;