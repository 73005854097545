import React, { useEffect, useRef, useState } from 'react';

const BullflowTwoPointOh = ({ titleDuration = 4, subtitleDuration = 3, subtitleDelay = 2 }) => {
    const [titleOpacity, setTitleOpacity] = useState(0);
    const [subtitleOpacity, setSubtitleOpacity] = useState(0);

    useEffect(() => {
        const titleTimer = setTimeout(() => {
            setTitleOpacity(1);
        }, 5000);

        const subtitleTimer = setTimeout(() => {
            setSubtitleOpacity(1);
        }, (subtitleDelay + 5) * 1000);

        return () => {
            clearTimeout(titleTimer);
            clearTimeout(subtitleTimer);
        };
    }, [subtitleDelay]);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
    };

    const headerStyle = {
        fontSize: '4.5rem',
        fontWeight: 'bold',
        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        opacity: titleOpacity,
        transition: `opacity ${titleDuration}s`,
    };

    const subtitleStyle = {
        fontSize: '1.5rem',
        color: 'white',
        fontWeight: 575,
        marginTop: '0.5rem',
        opacity: subtitleOpacity,
        transition: `opacity ${subtitleDuration}s`,
    };

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                Bullflow 2.0
            </div>
            <div style={subtitleStyle}>
                Coming Next Week
            </div>
        </div>
    );
};

export default BullflowTwoPointOh;