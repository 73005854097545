import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Slider } from 'antd';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  mainTitle: {
    marginLeft: '10px',
    marginRight: '5px',
    fontSize: '0.8em',
  },
  subtitle: {
    color: 'grey',
    fontSize: '0.8em',
  },
  slider: {
    margin: '0 10px',
    width: 'calc(100% - 20px)',
  },
});
function TradeSizeFields({ minTradeSizeProp, maxTradeSizeProp, handleFilterChange }) {
    const classes = useStyles();
    const [minTradeSize, setMinTradeSize] = useState(minTradeSizeProp);
    const [maxTradeSize, setMaxTradeSize] = useState(maxTradeSizeProp);
  
    const handleChange = (values) => {
      const newMaxTradeSize = values[1] === 10000 ? 1000000 : values[1];
      setMinTradeSize(values[0]);
      setMaxTradeSize(newMaxTradeSize);
      handleFilterChange('min trade size', values[0]);
      handleFilterChange('max trade size', newMaxTradeSize);
    };
  
    const formatTooltip = (value) => {
      if (value === 10000) {
        return 'Infinite';
      }
      return value;
    };
  
    useEffect(() => {
      setMinTradeSize(minTradeSizeProp);
    }, [minTradeSizeProp]);
  
    useEffect(() => {
      setMaxTradeSize(maxTradeSizeProp);
    }, [maxTradeSizeProp]);
  
    return (
      <div style={{ width: '90%', marginBottom: '0px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            fontWeight: 600,
            fontSize: '0.8em',
          }}
        >
          <p style={{ marginBottom: '0px' }}># Contracts</p>
        </div>
        <div style={{ marginTop: '0px', marginBottom: '0px' }}>
          <Slider
            range
            min={0}
            max={10000}
            value={[minTradeSize, maxTradeSize === 1000000 ? 10000 : maxTradeSize]}
            onChange={handleChange}
            tipFormatter={formatTooltip}
            style={{ color: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0px',
            marginTop: '0px',
          }}
        >
          <p
            style={{
              fontSize: '0.7em',
              fontWeight: 600,
              color: 'GrayText',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            0
          </p>
          <p
            style={{
              fontSize: '0.7em',
              fontWeight: 600,
              color: 'GrayText',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            ∞
          </p>
        </div>
      </div>
    );
  }
  
  export default TradeSizeFields;
  