import React, { useEffect } from 'react';

const HeroSection = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const title = document.getElementById('hero-title');
            const subtitle = document.getElementById('hero-subtitle');
            // Assuming there's a button you want to include in the effect
            const button = document.getElementById('hero-button');

            // Adjust these values to control the speed and fading
            const speed = 0.5;
            const fadeOutThreshold = 150; // The scroll position at which elements start fading out

            // Parallax scrolling effect
            const offset = scrollPosition * speed;

            // Fade out effect as you scroll down
            const opacity = 1 - scrollPosition / fadeOutThreshold;

            if (title && subtitle && button) {
                title.style.transform = `translateY(${offset}px)`;
                subtitle.style.transform = `translateY(${offset}px)`;
                button.style.transform = `translateY(${offset}px)`;

                title.style.opacity = opacity;
                subtitle.style.opacity = opacity;
                button.style.opacity = opacity;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <style>
                {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
                `}
            </style>
            <div style={{ textAlign: 'center', overflow: 'hidden', width: '100%' }}>
                <h1
                    id="hero-title"
                    style={{
                        opacity: 0,
                        animation: 'fadeIn 1.25s ease-out forwards',
                        zIndex: 2,
                        position: 'relative',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '3.35vw',
                        lineHeight: '1.2',
                        marginBottom: '12px',
                    }}
                >
                    <span
                        style={{
                            background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Follow the Smart Money
                    </span>
                </h1>
                <h2 id="hero-subtitle" style={{
                    opacity: 0,
                    animation: 'fadeIn 1.25s ease-out 0.45s forwards',
                    zIndex: 2, position: 'relative', color: '#879097', fontWeight: 600,
                    fontSize: '1.0vw', lineHeight: '1.2', marginBottom: '50px'
                }}>
                    Uncover the options trades of hedge funds and institutional investors in real-time
                </h2>

                <img
                    src="https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/new_bullflow_promo_macbook.png?alt=media&token=5ee229e6-6f09-4589-a8d1-49e542800eb6"
                    alt="Hero"
                    style={{
                        opacity: 0,
                        animation: 'fadeIn 1.25s ease-out 0.85s forwards',
                        maxWidth: '100%',
                        height: 'auto',
                        zIndex: 1,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            </div>
        </>
    );
};

export default HeroSection;
