import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook to access query parameters

  // Helper function to parse query string parameters
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode'); // Retrieve the oobCode from query parameters


  const onFinish = async (values) => {
    const { password } = values;
    const auth = getAuth();
    setLoading(true);
    console.log(`oobCode: ${oobCode}`);
    try {
      await verifyPasswordResetCode(auth, oobCode);
      await confirmPasswordReset(auth, oobCode, password);
      message.success('Your password has been reset successfully.');
      navigate('/login');
    } catch (error) {
      console.error("Error resetting password:", error);
      message.error('Failed to reset password. The link may be expired or invalid. Contact support@bullflow.io for assistance.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      name="password_reset"
      onFinish={onFinish}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input.Password placeholder="New Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} style={{ backgroundColor: '#FFD700' }}>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordReset;
