import { Tooltip } from '@nextui-org/react';
import { XCircle } from 'lucide-react';
import { useState, useEffect } from 'react';

const ClearIsolatedContractButton = ({ onClick, isolatedSymbol }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isolatedSymbol != "") {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [isolatedSymbol]);

    const iconButtonStyle = {
        backgroundColor: isHovered ? '#0d0d0d' : '#000000', // Darker background colors
        border: 'none',
        padding: '10px',
        borderRadius: '90px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease',
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none', // Prevent interaction when not visible
        marginRight: '10px',
    };

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <Tooltip delay={150} content="Clear Isolated Contract">
            <button
                style={iconButtonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}
            >
                <XCircle size={16} strokeWidth={1} />
            </button>
        </Tooltip>
    );
};

export default ClearIsolatedContractButton;
