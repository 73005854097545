import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';

const VladAccessPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        createUserWithEmailAndPassword(auth, 'tester@bullflow.io', 'securePassword')
            .then((userCredential) => {
                // User creation successful
                const paymentRef = doc(firestore, 'payments', 'uniqueDocumentId');
                setDoc(paymentRef, {
                    user: 'tester@bullflow.io',
                    subscriptionStatus: 'complete',
                    subscriptionType: 'yearly',
                    timestamp: Date.now()
                })
                    .then(() => {
                        console.log('Payment document successfully written!');
                        navigate('/home');
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            })
            .catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    // Attempt to sign in since the email is already in use
                    signInWithEmailAndPassword(auth, 'tester@bullflow.io', 'securePassword')
                        .then((userCredential) => {
                            console.log("Existing user signed in successfully.");
                            const paymentRef = doc(firestore, 'payments', 'uniqueDocumentId');
                            setDoc(paymentRef, {
                                user: 'tester@bullflow.io',
                                subscriptionStatus: 'complete',
                                subscriptionType: 'yearly',
                                timestamp: Date.now()
                            })
                                .then(() => {
                                    console.log('Payment document successfully written!');
                                    navigate('/home');
                                })
                                .catch((error) => {
                                    console.error("Error writing document: ", error);
                                });
                            navigate('/home');
                        })
                        .catch((signInError) => {
                            console.error("Error signing in existing user: ", signInError);
                        });
                } else {
                    // Handle other errors
                    console.error("Error creating user: ", error);
                }
            });
    }, [navigate]);

    return (
        <div>
            <p>
                Welcome! We're giving you access...
            </p>
        </div>
    );
};

export default VladAccessPage;