import React, { useEffect, useState } from "react";
import { Container, ThemeProvider, createTheme } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, addDoc, setDoc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import CircularProgress from '@material-ui/core/CircularProgress'; // import CircularProgress for the spinner
import { Button } from '@mui/material';



const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white" // Change this as per your requirement
                    },
                    "&:hover": {
                        color: "white" // Change this as per your requirement
                    },
                }
            }
        }
    }
});



const RedeemACodeScreen = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const [isLoading, setIsLoading] = React.useState(false); // Add loading state
    const [code, setCode] = React.useState(''); // State to hold the code
    const [errorMessage, setErrorMessage] = React.useState(''); // State to hold error message
    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(false); // State to track user authentication
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 550);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsUserAuthenticated(true); // Set user authenticated state to true
                // console.log("User is already signed in" + user.email);

                // Define async function
                const checkPaymentsAndNavigate = async () => {
                    const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", user.email));

                    // Execute the query
                    const paymentQuerySnapshot = await getDocs(paymentsQuery);

                    // Checks if any matching documents were found
                    const paymentDocExists = !paymentQuerySnapshot.empty;

                    if (paymentDocExists) { // checks if the payment exists in both collections
                        // Get the first document from the query snapshot
                        const paymentDoc = paymentQuerySnapshot.docs[0];
                        const subscriptionType = paymentDoc.data().subscriptionType;

                        if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                            // Handle subscription type
                            // console.log(`User has a ${subscriptionType} subscription.`);
                            navigate('/app');
                        } else {
                            // console.log('User does not have a monthly or yearly subscription.');
                        }

                    }
                }
                checkPaymentsAndNavigate();
            } else {
                setIsUserAuthenticated(false); // Set user authenticated state to false
                navigate("/PromoAuthentication");
                // console.log("No user is signed in");
            }
        });

        // Cleanup subscription on unmount
        return () => {
            unsubscribe();
            clearTimeout(timer);
        };
    }, [auth, navigate]);

    const createPaymentDoc = async () => {
        const user = auth.currentUser;

        if (!user || !user.email) {
            console.error("No user or email found");
            setErrorMessage("User is not authenticated.");
            return;
        }

        try {
            // Prepare the new document object
            const newDocument = {
                user: user.email,
                subscriptionType: "monthly",
                // Add the current timestamp
                timestamp: new Date(),
                // Add any other fields you need here
            };

            // Add a new document to the "payments" collection
            await addDoc(collection(firestore, "payments"), newDocument);

            // Call onSuccess callback if provided
            navigate("/app");
        } catch (error) {
            // Handle any errors
            console.error("Error creating new document: ", error);
            setErrorMessage("Error. Please try again later.");
        }
    };

    const handleButtonClick = async (event) => {
        window.gtag('event', 'Redeem a code button', {
            'event_category': 'Button',
            'event_label': 'Redeem a code',
            'value': 1
        });
        setIsLoading(true); // Start loading when the button is clicked
        setErrorMessage(''); // Clear any existing error messages


        try {
            // Query to check if the code exists in the Firestore database
            const codesQuery = query(collection(firestore, "redemption_codes"), where("code", "==", code));

            // Execute the query
            const codesQuerySnapshot = await getDocs(codesQuery);

            // Check if any matching documents were found
            const codeExists = !codesQuerySnapshot.empty;

            if (codeExists) {
                // Code exists, handle accordingly
                createPaymentDoc();
                navigate('/app');
            } else {
                // Code does not exist, handle accordingly
                setErrorMessage("Invalid code.");
            }
        } catch (error) {
            // Handle errors from Firebase
            setErrorMessage("An error occurred while verifying the code.");
        }

        setIsLoading(false); // Stop loading after the check is complete
    };
    if (!isUserAuthenticated) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress /> {/* Spinner component */}
            </div>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>

                <Container component="main"
                    maxWidth="xs"
                    style={{
                        display: 'flex', flexDirection: 'column', height: '50vh',
                        marginTop: '100px'
                    }}>
                    {loading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh' // Full screen height to center the spinner vertically
                        }}>
                            <CircularProgress /> {/* MUI Loading Spinner */}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Bold text above the text box */}
                            <h2 style={{ marginBottom: '5px' }}>Have a code? Enter it here</h2>

                            {/* Text box with rounded corners */}
                            <input
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value)} // Update the code state when the input changes
                                style={{ borderRadius: '5px', padding: '10px', marginBottom: '10px', width: '80%' }}
                            />

                            {/* Error message display */}
                            {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}

                            {/* Submit button */}
                            <Button
                                type="submit"
                                fullWidth={false}
                                onClick={handleButtonClick}
                                startIcon={isLoading ? <CircularProgress size={20} /> : null} // Show spinner when loading
                                disabled={isLoading} // Disable button when loading
                                style={{
                                    backgroundColor: isLoading ? "grey" : "#FFD700", // Change color to grey when loading
                                    fontSize: "18px",
                                    textTransform: 'none',
                                    padding: '10px',
                                    color: isLoading ? "darkGrey" : 'black', // Change text color when loading
                                    fontWeight: 'bold',
                                    marginTop: '10px',
                                    borderRadius: '5px',
                                    boxShadow: '0 0 5px #B8860B, 0 0 10px #B8860B, 0 0 15px #B8860B, 0 0 20px #B8860B', // Yellow box shadow
                                }}
                                variant="contained"
                            >
                                {isLoading ? "Loading..." : "Redeem"} {/* Change button text when loading */}
                            </Button>

                        </div>
                    )}
                </Container>
            </ThemeProvider >
        );
    }
};

export default RedeemACodeScreen;
