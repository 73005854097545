import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import '../agStyles.css';
import '../antdesigndatestyles.css'
import { Toaster, toast } from 'sonner';
import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Alert, Button as AntButton, DatePicker } from 'antd';
import { collection, query, orderBy, onSnapshot, doc, deleteDoc, getDocs, where, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from '../firebase';
import { analyticsLogError, convertOptionsSymbol, convertToCSV, daysUntilCanceled, combinedDisabledDate, formatNumber, generateCollectionNames, triggerCSVDownload } from '../Utils';
import { CurrentVolumeCellRenderer, EndOfDayPerformanceCellRenderer, GuestLockCellRenderer, HistoricalFlowDateTimeCellRenderer, ImpliedVolatilityCellRenderer, OTMCellRenderer, OpenInterestCellRenderer, OptionTypeCellRenderer, PremiumCellRenderer, PreviousVolumeCellRenderer, SigScoreCellRenderer, SpeadExecutionCellRenderer, TickerCellRenderer, TickerSentimentCellRenderer, TradePriceCellRenderer, TradeTypeCellRenderer, UnixTimestampCellRenderer } from '../MainDashboardCellRendering';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import FilterIconButton from './FilterIconButton';
import ResetIconButton from './ResetIconButton';
import { TickerSearchBox } from './TickerSearchBox';
import { Tooltip } from "@nextui-org/react";
import QuickFilterAndFavoritesComponent from './ChipFilterComponent';
import CallPutCheckBoxes from '../CallPutCheckBoxes';
import SentimentCheckboxes from '../DashboardComponents/SentimentCheckboxes';
import TradesSweepsCheckBoxes from '../TradesSweepsCheckBoxes';
import ExecutionPriceCheckboxes from '../ExecutionPriceCheckboxes';
import PremiumFilter from '../PremiumFilter';
import ScoreFilterFields from '../ScoreFields';
import TickerFilter from '../TickerFilterFields';
import ExpirationFilterFields from '../ExpirationFilterFields';
import OTMFilterFields from '../OTMFilterFields';
import SpotPriceFields from '../SpotPriceFields';
import OptionPriceFields from '../OptionPriceFields';
import MarketCapFields from '../MarketCapFields';
import Last7DaysBullflowVolume from '../DashboardComponents/BF7DayVolumeFilter';
import PrevDayBullflowVolume from '../DashboardComponents/BFPrevDayVolumeFilter';
import PreviousVolumeFilter from '../DashboardComponents/PreviousVolumeFilter';
import CurrentVolumeFilter from '../DashboardComponents/CurrentVolumeFilter';
import OIFilter from '../DashboardComponents/OIFilter';
import addEventToFirestore from '../EventHelper';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import AlertsListView from '../AlertsListView';
import { BarChart, FileText, Gem, LineChartIcon, NewspaperIcon } from 'lucide-react';
import Sidebar from '../Sidebar';
import TextButton from '../components/ui/BullflowTextButton';
import { GenericSearchBox } from './GenericSearchBox';
import TradingViewWidget from '../DashboardComponents/TradingViewWidget';
import { Dialog, DialogContent, styled } from '@material-ui/core';
import OptionPriceChart from '../OptionPriceChart';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import Bull from '../bullflow_3.png';
import TradeSizeFields from '../DashboardComponents/TradeSizeFilter';

const MAX_LAST_7_DAYS_BF_VOLUME = 500;
const MAX_PREV_DAY_BF_VOLUME = 250;
const MAX_PREVIOUS_VOLUME = 1000000;
const MAX_CURRENT_VOLUME = 1000000;
const MAX_OI = 1000000;
const secondsCalc = (1000 * 3600 * 24);
const sectors = ["ALL", "Industrials", "Financial Services", "Consumer Cyclical", "Real Estate", "Basic Materials", "Other", "Technology", "Energy", "Consumer Defensive", "Communication Services", "Healthcare", "Utilities"]
const gridOptions = {
    getRowNodeId: data => data.tradeDocName,
    getRowId: params => params.data.tradeDocName,
    headerHeight: 20,
};
const MyDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        backgroundColor: 'black',
        borderRadius: '10px',
        minWidth: '300px',
        maxWidth: '90%',
        width: 'auto',
        height: 'auto',
        overflowY: 'auto',
        zIndex: 10000
    },
});

function HistoricalFlowDashboard() {
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const toggleDrawer = () => setIsFilterDrawerOpen(!isFilterDrawerOpen);
    const [trades, setTrades] = useState([]);
    const isMountedRef = useRef(true);
    const unsubscribeRef = useRef(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [loading, setLoading] = useState(true);
    const gridApiRef = useRef(null);
    const columnApiRef = useRef(null);
    const myDivRef = useRef();
    const [minPremium, setMinPremium] = useState(20000);
    const [maxPremium, setMaxPremium] = useState(500000000);
    const [excludeTickers, setExcludeTickers] = useState([]);
    const [onlyShowTickers, setOnlyShowTickers] = useState([]);
    const [minDTE, setMinDTE] = useState(0);
    const [maxDTE, setMaxDTE] = useState(1200);
    const [minOTM, setMinOTM] = useState(-3000);
    const [maxOTM, setMaxOTM] = useState(3000);
    const [minScore, setMinScore] = useState(0.0);
    const [maxScore, setMaxScore] = useState(1.0);
    const [maxLast7DaysBFVolume, setMaxLast7DaysBFVolume] = useState(MAX_LAST_7_DAYS_BF_VOLUME);
    const [maxPrevDayBFVolume, setMaxPrevDayBFVolume] = useState(MAX_PREV_DAY_BF_VOLUME);
    const [maxPreviousVolume, setMaxPreviousVolume] = useState(MAX_PREVIOUS_VOLUME);
    const [maxCurrentVolume, setMaxCurrentVolume] = useState(MAX_CURRENT_VOLUME);
    const [maxOI, setMaxOI] = useState(MAX_OI);
    const [minSpot, setMinSpot] = useState(0);
    const [maxSpot, setMaxSpot] = useState(5000);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [minOptionPrice, setMinOptionPrice] = useState(0);
    const [maxOptionPrice, setMaxOptionPrice] = useState(1000000);
    const [minTradeSize, setMinTradeSize] = useState(0);
    const [maxTradeSize, setMaxTradeSize] = useState(1000000);
    const [minVolumeDif, setMinVolumeDif] = useState(null);
    const [maxVolumeDif, setMaxVolumeDif] = useState(null);
    const [minMarketCap, setMinMarketCap] = useState(0);
    const [maxMarketCap, setMaxMarketCap] = useState(99999999999999);
    const [tradeInfoDialog, setTradeInfoDialog] = useState(false);
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openSaveAlertDialog, setOpenSaveAlertDialog] = useState(false);
    const [openOptionPriceChart, setOpenOptionPriceChart] = useState(false);
    const [openSpotTradingView, setOpenSpotTradingView] = useState(false);
    const [tradingViewTicker, setTradingViewTicker] = useState("");
    const [optionPriceChartTicker, setOptionPriceChartTicker] = useState("");
    const [accountSettingsDialog, setAccountSettingsDialog] = useState(false);
    const [callsCheckBox, setCallsCheckBox] = useState(true);
    const [putsCheckBox, setPutsCheckBox] = useState(true);
    const [aaCheckBox, setAACheckBox] = useState(true);
    const [bbCheckBox, setBBCheckBox] = useState(true);
    const [bullishCheckBox, setBullishCheckBox] = useState(true);
    const [bearishCheckBox, setBearishCheckBox] = useState(true);
    const [neutralCheckBox, setNeutralCheckBox] = useState(true);
    const [spCheckBox, setSpCheckBox] = useState(true);
    const [splitsCheckBox, setSplitsCheckBox] = useState(true);
    const [sweepsCheckBox, setSweepsCheckBox] = useState(true);
    const [blocksCheckbox, setBlocksCheckBox] = useState(true);
    const [multilegCheckbox, setMultilegCheckbox] = useState(true);
    const [singlesCheckbox, setSinglesCheckBox] = useState(true);
    const [filterNameValue, setFilterNameValue] = useState("");
    const [alertNameValue, setAlertNameValue] = useState("");
    const [selectedTickerForInfo, setSelectedTickerForInfo] = useState(null);
    const [filterItems, setFilterItems] = useState([]);
    const [alertItems, setAlertItems] = useState([]);
    const alertItemsRef = useRef({});
    const [selectedItem, setSelectedItem] = useState("Filters");
    const [filterNames, setFilterNames] = useState([]);
    const [userHasSubscription, setUserHasSubscription] = useState(true);
    const [alreadyDeterminedUserSubscriptionState, setAlreadyDeterminedUserSubscriptionState] = useState(false);
    const [contractPercentChange, setContractPercentChange] = useState(null);
    const [contractCurrentValue, setContractCurrentValue] = useState(null);
    const [sectorFilter, setSectorFilter] = useState("ALL");
    const [isFiltering, setIsFiltering] = useState(false);
    const [filtersHidden, setFiltersHidden] = useState(false);
    const [showAlertsView, setShowAlertsView] = useState(false);
    const [cancelAt, setCancelAt] = useState(null);
    const [activeTags, setActiveTags] = useState([]);
    const [volOverOI, setVolOverOI] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [showRowDropdown, setShowRowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [tickerForData, setTickerForData] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [filterSelectValue, setFilterSelectValue] = useState("");
    const [collectionList, setCollectionList] = useState([]);
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const gridStyle = useMemo(() => ({
        height: 'calc(100vh - 20px)', width: '100%', transition: 'all 0.3s ease', marginTop: '10px'
    }), []);
    const showSignupNotif = () => {
        toast('Start your free trial to access this feature', {
            style: {
                background: 'black',
                color: 'white',
            },
            action: {
                label: 'Let\'s Go',
                onClick: () => navigate('/signup'),
            },
            icon: <Gem size={18} />,
        });
    };
    const onRowClicked = (params) => {
        const rowData = params.data;
        setSelectedRowData(rowData);
        setShowRowDropdown(true);

        // Get the viewport height
        const viewportHeight = window.innerHeight;

        // Calculate the distance from the bottom of the screen
        const distanceFromBottom = viewportHeight - params.event.clientY;

        // Set a threshold value for the distance from the bottom
        const threshold = 200; // Adjust this value according to your needs

        // If the distance from the bottom is less than the threshold, move the dropdown up
        if (distanceFromBottom < threshold) {
            setDropdownPosition({
                x: params.event.clientX,
                y: params.event.clientY - threshold,
            });
        } else {
            setDropdownPosition({
                x: params.event.clientX,
                y: params.event.clientY,
            });
        }
    };
    const closeRowDropdown = () => {
        setShowRowDropdown(false);
    };

    function tradePriceClickHandler(gtag, addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker, params) {
        gtag('event', 'Click trade price', {
            'event_category': 'Button',
            'event_label': 'Click trade price',
            'value': 1
        });
        addEventToFirestore("Open option volume chart");
        setOpenOptionPriceChart(true);
        setOptionPriceChartTicker(params.row.Symbol);
    };
    function spotClickHandler(gtag, addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker, params) {
        gtag('event', 'Click spot price', {
            'event_category': 'Button',
            'event_label': 'Click spot price',
            'value': 1
        });
        addEventToFirestore("Open Trading View Chart");
        setOpenSpotTradingView(true);
        setTradingViewTicker(params.row.tickerSymbol);
    }

    const onTradePriceClick = useCallback((params) => () => {
        tradePriceClickHandler(window.gtag, addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker, params);
    }, [addEventToFirestore, setOpenOptionPriceChart, setOptionPriceChartTicker]);

    const openTradingViewChart = useCallback((params) => {
        spotClickHandler(window.gtag, addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker, params);
    }, [addEventToFirestore, setOpenSpotTradingView, setTradingViewTicker]);

    const DropdownMenu = ({ position, onClose }) => {
        const items = [
            { key: "details", label: "Trade Details", icon: <FileText size={16} /> },
            { key: "chart", label: "Option Price/Volume", icon: <BarChart size={16} /> },
            { key: "news", label: "News", icon: <NewspaperIcon size={16} /> },
            { key: "ticker chart", label: "TradingView Chart", icon: <LineChartIcon size={16} /> },
        ];

        const handleItemClick = (item) => {
            switch (item.key) {
                case "details":
                    openTradeInfo(selectedRowData);
                    break;
                case "chart":
                    onTradePriceClick({ row: selectedRowData })();
                    break;
                case "news":
                    window.open(`https://www.benzinga.com/quote/${selectedRowData.tickerSymbol}/news`, '_blank');
                    console.log("News clicked for row:", selectedRowData);
                    break;
                case "ticker chart":
                    openTradingViewChart({ row: selectedRowData });
                    break;
                default:
                    break;
            }
            onClose();
        };

        return (
            <div
                style={{
                    position: 'absolute',
                    top: position.y,
                    left: position.x,
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    zIndex: 100,
                    fontWeight: 550,
                }}
            >
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                    {items.map((item) => (
                        <li
                            key={item.key}
                            style={{
                                padding: '8px 0',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                            onClick={() => handleItemClick(item)}
                        >
                            {item.label}
                            <span style={{ marginLeft: '8px' }}>{item.icon}</span>
                        </li>
                    ))}
                </ul>
                <button
                    onClick={onClose}
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: 'none',
                        borderRadius: '3px',
                        padding: '5px 10px',
                        marginTop: '10px',
                        cursor: 'pointer',
                    }}
                >
                    Close
                </button>
            </div>
        );
    };


    const LivePerformanceCellRenderer = (params) => {
        const optionsTicker = params?.data?.Symbol;
        const oldSpread = params?.data?.tradePrice;
        const { data, loading } = useAsyncPerformance(optionsTicker, oldSpread);
    
        if (loading) {
            return <CircularProgress size={10} />;
        }
    
        // Inline function to determine color
        const getColor = (value) => {
            if (value >= 0) return '#20d46b';
            if (value < 0) return '#cc3753';
            return 'inherit'; // Default color if value is neither positive nor negative
        };
    
        // Determine the display value
        const displayValue = (data || data === 0) ? `${data}%` : "";
    
        return (
            <Tooltip delay={750} content="Performance since trade">
                <div style={{ color: getColor(data), fontWeight: 550 }}>
                    {displayValue}
                </div>
            </Tooltip>
        );
    };
    

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
    const useAsyncPerformance = (optionsTicker, oldSpread) => {
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(false);
        // Debounced fetchData function
        const fetchData = useCallback(debounce(async (optionsTicker, oldSpread) => {
            setLoading(true);
            const result = await fetchOptionsSpread(optionsTicker, oldSpread); // Ensure fetchOptionsSpread is defined or imported
            if (result != null) {
                if (result[0] !== null) {
                    setData(result[0]);
                } else {
                    setData("N/A");
                }
            }
            setLoading(false);
        }, 500), [optionsTicker, oldSpread]);

        useEffect(() => {
            if (optionsTicker && oldSpread) {
                fetchData(optionsTicker, oldSpread);
            }
        }, [optionsTicker, oldSpread, fetchData]);

        return { data, loading };
    };

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'unixTimestamp',
            width: 120,
            headerName: 'Time',
            suppressMenu: true,
            cellRenderer: HistoricalFlowDateTimeCellRenderer,
            sort: 'desc',
            editable: false,
        },
        {
            field: 'tickerSymbol', headerName: 'Ticker', cellRenderer: TickerCellRenderer,
            width: 92.5,
            cellStyle: { fontSize: '11px', fontWeight: 600 },
            suppressMenu: true,
            editable: false,
        },
        {
            field: 'optionType', headerName: 'P/C',
            cellRenderer: OptionTypeCellRenderer, width: 50,
            editable: false,
        },
        {
            field: 'expirationDate', headerName: 'Expiration',
            width: 115,
            editable: false,
        },
        {
            field: 'tradeType', headerName: 'Type',
            width: 80,
            cellRenderer: TradeTypeCellRenderer,
            editable: false,
        },
        {
            field: 'tickerSentiment', headerName: 'Sentiment',
            width: 100,
            suppressMenu: true,
            wrapHeaderText: true,
            cellRenderer: TickerSentimentCellRenderer,
            editable: false,
        },
        {
            field: 'tradeSize', headerName: 'Size',
            editable: false,
            width: 75
        },
        {
            field: 'tradePrice', headerName: 'Price',
            width: 85, cellRenderer: TradePriceCellRenderer,
            editable: false,
        },
        {
            field: 'strikePrice', headerName: 'Strike', width: 85,
            editable: false,
        },
        {
            field: 'otmPercentage', headerName: 'OTM', width: 80,
            editable: false,
            cellRenderer: OTMCellRenderer
        },
        {
            field: 'premium', headerName: 'Value', width: 85,
            editable: false,
            cellRenderer: PremiumCellRenderer
        },
        {
            field: 'eod_performance', headerName: 'EOD Return',
            width: 125,
            editable: false,
            cellRenderer: EndOfDayPerformanceCellRenderer
        },
        {
            field: 'lp', headerName: 'Latest Return', width: 135,
            sortable: false,
            editable: false, cellRenderer: LivePerformanceCellRenderer
        },
        {
            field: 'spreadExecution', headerName: 'X', width: 80,
            editable: false,
            cellRenderer: SpeadExecutionCellRenderer
        },
        {
            field: 'score', headerName: 'SigScore', width: 107.5,
            editable: false,
            cellRenderer: SigScoreCellRenderer
        },
        {
            field: 'spotPrice', headerName: 'Spot', width: 85,
            editable: false,
        },
        {
            field: 'currentVolume', headerName: 'Volume', width: 90,
            editable: false,
            cellRenderer: CurrentVolumeCellRenderer
        },
        {
            field: 'previousVolume', headerName: 'Prev Vol.', width: 90,
            editable: false,
            cellRenderer: PreviousVolumeCellRenderer
        },
        {
            field: 'openInterest', headerName: 'OI', width: 70,
            editable: false,
            cellRenderer: OpenInterestCellRenderer
        },
        {
            field: 'impliedVolatility', headerName: 'IV', width: 70,
            editable: false,
            cellRenderer: ImpliedVolatilityCellRenderer
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            editable: true,
            filter: true,
        };
    }, []);

    useEffect(() => {
        // Subscribe to auth changes
        console.log("Authenticating...")
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (!alreadyDeterminedUserSubscriptionState) {
                    fetchSubscriptionType(user);
                }
            } else {
                setUserHasSubscription(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchSubscriptionType = async (user_) => {
        try {
            if (user_ != null) {
                const paymentsCollection = collection(firestore, 'payments');
                const q = query(paymentsCollection, where('user', '==', user_.email));
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    console.log(`Error 588, user does not have a subscription. User: ${user_.email}`);
                    setUserHasSubscription(false);
                } else {
                    querySnapshot.forEach((doc) => {
                        console.log("Checking if canceling");
                        let subtype = doc.data().subscriptionType;
                        let canceling = doc.data().canceling;
                        let cancelAt_ = doc.data().cancelAt;
                        let subscriptionStatus = doc.data().subscriptionStatus;
                        if (cancelAt_ != null && canceling != null) {
                            console.log("Canceling: ", canceling);
                            const daysUntilCanceled_ = daysUntilCanceled(cancelAt_);
                            console.log("Days until canceled: ", daysUntilCanceled_);
                            if (canceling === true && daysUntilCanceled_ <= 14) {
                                setCancelAt(daysUntilCanceled_);
                            }
                        }
                        if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {
                            navigate('/pricing');
                        }

                        if (subscriptionStatus != null && (subscriptionStatus === "deleted" || subscriptionStatus === "canceled" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "incomplete" || subscriptionStatus === "past_due" || subscriptionStatus === "unpaid")) {                            // Open stripe portal
                            navigate('/pricing');
                        }
                        if (subtype != null && (subtype === "yearly" || subtype === "monthly")) {
                            setUserHasSubscription(true);
                            setAlreadyDeterminedUserSubscriptionState(true);
                        } else {
                            setUserHasSubscription(false);
                        }
                    });
                }
            } else {
                console.error("error code 19277");
            }
        } catch (error) {
            console.error('Error fetching subscription type');
        }
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const ticker = searchParams.get('ticker');
        if (ticker != null && ticker != "") {
            setOnlyShowTickers([ticker]);
            setTickerForData(ticker);
            setIsSearching(true);
        }
        fetchFilters();
        fetchAlerts();
        addEventToFirestore("Dashboard loaded");
    }, [user]);
    const fetchData = useCallback(async (symbol, currentSpread) => {
        setContractPercentChange("N/A");
        setContractCurrentValue("N/A");
        const data = await fetchOptionsSpread(symbol, currentSpread);
        try {
            setContractPercentChange(data[0]);
            setContractCurrentValue(data[1]);
        } catch (error) {
            console.log("Spread error 10062");
            setContractPercentChange("N/A");
            setContractCurrentValue("N/A");
        }
    }, []);
    const openTradeInfo = (trade) => {
        setSelectedTickerForInfo(trade);
        fetchData(trade.Symbol, trade.tradePrice)
        setTradeInfoDialog(true);
    };
    const fetchAlerts = async () => {
        try {
            if (user != null) {
                const querySnapshot = await getDocs(query(
                    collection(firestore, '_custom_alerts'),
                    where('userEmail', '==', user.email))
                );
                let fetchedItems = {};
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    fetchedItems[data.alertName] = data;
                });

                setAlertItems(fetchedItems); // Update state
                alertItemsRef.current = fetchedItems;
            } else {
                console.log("Alert error 6574839");
            }
        } catch (error) {
            console.error('Alert error 3391855: ', error);
        }
    };
    const fetchTrades = useCallback(async () => {
        console.log("Fetching trades")
        if (collectionList.length > 0) {
            console.log(`Collection list: ${collectionList}`);
            setLoading(true);
            setIsLoading(true);

            for (const collectionName of collectionList.reverse()) {
                const tradesRef = collection(firestore, collectionName);
                const orderedTradesRef = query(tradesRef, orderBy("unixTimestamp", "desc"));

                try {
                    const snapshot = await getDocs(orderedTradesRef);
                    const tradesList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    setTrades(currentTrades => [...currentTrades, ...tradesList]);
                } catch (err) {
                    console.error("Db error 45672", err.message);
                }
            }
            setLoading(false);
        } else {
            console.log("Collection list is empty!");
            setLoading(false);
        }
    }, [collectionList]);

    useEffect(() => {
        fetchTrades();
        return () => {
            isMountedRef.current = false;
            if (unsubscribeRef.current) {
                unsubscribeRef.current();
            }
        };
    }, [fetchTrades]);

    function useDebouncedEffect(effect, deps, delay) {
        useEffect(() => {
            const handler = setTimeout(() => effect(), delay);
            return () => clearTimeout(handler);
        }, [...deps, delay]);
    }

    const updateDataGridRows = () => {
        if (trades.length > 0) {
            setIsFiltering(true);
            setIsLoading(true);
        }
        const today = new Date();
        const thirtyMinAgo = (today.getTime() / 1000) - (30 * 60);
        const filteredTrades = trades.filter(trade => checkTradeFilter(trade, today, secondsCalc, thirtyMinAgo));
        const allGridRowData = [];
        gridApiRef.current.forEachNode((node) => allGridRowData.push(node.data));
        const allRowIdsInGrid = new Set(allGridRowData.map((data) => data.id));
        const filteredTradeIds = new Set(filteredTrades.map((trade) => trade.id));
        const addsAndUpdates = filteredTrades.filter((trade) =>
            !allRowIdsInGrid.has(trade.id) || allRowIdsInGrid.has(trade.id)
        );
        const removes = allGridRowData
            .filter((data) => !filteredTradeIds.has(data.id))
            .map((data) => data.id);
        const removeIdsSet = new Set(removes); // Convert to Set for faster lookup
        const transaction = {
            add: addsAndUpdates.filter((trade) => !allRowIdsInGrid.has(trade.id)),
            update: addsAndUpdates.filter((trade) => allRowIdsInGrid.has(trade.id)),
            remove: allGridRowData.filter((data) => removeIdsSet.has(data.id)), // Optimized remove calculation
        };
        gridApiRef.current.applyTransaction(transaction);
        setIsLoading(false);
    };

    useDebouncedEffect(updateDataGridRows, [trades], 100);

    const onGridReady = params => {
        gridApiRef.current = params.api;
        columnApiRef.current = params.columnApi;
    };
    useDebouncedEffect(updateDataGridRows, [
        trades,
        filtersHidden,
        minPremium,
        maxPremium,
        minOTM,
        maxOTM,
        minScore,
        maxScore,
        maxLast7DaysBFVolume,
        maxPrevDayBFVolume,
        maxPreviousVolume,
        maxCurrentVolume,
        maxOI,
        minSpot,
        maxSpot,
        minOptionPrice,
        maxOptionPrice,
        minTradeSize,
        maxTradeSize,
        minVolumeDif,
        maxVolumeDif,
        minMarketCap,
        maxMarketCap,
        minDTE,
        maxDTE,
        excludeTickers,
        onlyShowTickers,
        callsCheckBox,
        putsCheckBox,
        aaCheckBox,
        bbCheckBox,
        bullishCheckBox,
        bearishCheckBox,
        neutralCheckBox,
        spCheckBox,
        splitsCheckBox,
        sweepsCheckBox,
        multilegCheckbox,
        singlesCheckbox,
        blocksCheckbox,
        userHasSubscription,
        sectorFilter,
        activeTags
    ], 100);

    const checkTradeFilter = (trade, today, secondsCalc, thirtyMinAgo) => {
        if (!userHasSubscription && trade.unixTimestamp > thirtyMinAgo) {
            return false;
        }
        if (activeTags.length > 0 && trade.tags?.length <= 0) {
            return false;
        }
        if (activeTags.length > 0 && trade.tags?.length > 0) {
            if (!activeTags.every(item => trade.tags.includes(item))) {
                return false;
            }
        }
        let yearPrefix = "20";

        let premium = trade.premium;
        if (isNaN(premium)) {
            return false;
        };
        let premiumCheck = ((minPremium ? premium >= minPremium : true) && (maxPremium ? premium <= maxPremium : true));
        if (!premiumCheck) {
            return false;
        };
        let otmPercentage = trade.otmPercentage;
        if (isNaN(otmPercentage)) {
            return false;
        };

        let OTMCheck = ((minOTM ? otmPercentage >= minOTM : true) && (maxOTM ? otmPercentage <= maxOTM : true));
        if (!OTMCheck) {
            return false;
        };
        let score = trade.score;
        let last7DaysBullflowVolume = trade.last7DaysBullflowVolume;
        let previousBullflowVolume = trade.previousBullflowVolume;
        let spot = trade.spotPrice;
        let optionPrice = trade.tradePrice;
        let volumeDif = trade.volumePercentageDif;
        let marketCap = trade.marketCap;
        let previousVolume = trade.previousVolume;
        let currentVolume = trade.currentVolume;
        let openInterest = trade.openInterest;
        if (isNaN(score) || (isNaN(spot) && spot != null)
            || (isNaN(optionPrice) && optionPrice != null)
            || (isNaN(volumeDif) && volumeDif != null)
            || (isNaN(marketCap) && marketCap != null)
            || (isNaN(last7DaysBullflowVolume) && last7DaysBullflowVolume != null)
            || (isNaN(previousBullflowVolume) && previousBullflowVolume != null)
            || (isNaN(previousVolume) && previousVolume != null)
            || (isNaN(currentVolume) && currentVolume != null)
            || (isNaN(openInterest) && openInterest != null)) {
            return false;
        };

        let filterOIMax = parseFloat(maxOI);

        if (filterOIMax >= MAX_OI) {
            filterOIMax = 99999999;
        }

        if (openInterest > filterOIMax) {
            return false;
        }

        let filterPreviousVolumeMax = parseFloat(maxPreviousVolume);
        let filterCurrentVolumeMax = parseFloat(maxCurrentVolume);

        if (filterPreviousVolumeMax >= MAX_PREVIOUS_VOLUME) {
            filterPreviousVolumeMax = 9999999;
        }

        if (filterCurrentVolumeMax >= MAX_CURRENT_VOLUME) {
            filterCurrentVolumeMax = 9999999;
        }

        if (previousVolume > filterPreviousVolumeMax || currentVolume > filterCurrentVolumeMax) {
            return false;
        }

        let filterLast7DaysBFVolumeMax = parseFloat(maxLast7DaysBFVolume);
        let filterPrevDayBFVolumeMax = parseFloat(maxPrevDayBFVolume);
        if (filterLast7DaysBFVolumeMax >= MAX_LAST_7_DAYS_BF_VOLUME) {
            filterLast7DaysBFVolumeMax = 999999;
        }
        if (filterPrevDayBFVolumeMax >= MAX_PREV_DAY_BF_VOLUME) {
            filterPrevDayBFVolumeMax = 999999;
        }
        if (last7DaysBullflowVolume > filterLast7DaysBFVolumeMax || previousBullflowVolume > filterPrevDayBFVolumeMax) {
            return false;
        }

        let parseScoreMin = parseFloat(minScore);
        let parseScoreMax = parseFloat(maxScore);
        let scoreCheck = ((parseScoreMin ? score >= parseScoreMin : true) && (parseScoreMax ? score <= parseScoreMax : true));
        if (!scoreCheck) {
            return false;
        };

        let parseMinSpot = parseFloat(minSpot);
        let parseMaxSpot = parseFloat(maxSpot);
        let spotCheck = ((parseMinSpot ? spot >= parseMinSpot : true) && (parseMaxSpot ? spot <= parseMaxSpot : true));
        if (!spotCheck) {
            return false;
        };

        let parseMinOptionPrice = parseFloat(minOptionPrice);
        let parseMaxOptionPrice = parseFloat(maxOptionPrice);
        let optionPriceCheck = ((parseMinOptionPrice ? optionPrice >= parseMinOptionPrice : true) && (parseMaxOptionPrice ? optionPrice <= parseMaxOptionPrice : true));
        if (!optionPriceCheck) {
            return false;
        };

        let minTradeSizeCheck = parseFloat(minTradeSize);
        let maxTradeSizeCheck = parseFloat(maxTradeSize);
        let tradeSizeCheck = ((minTradeSizeCheck ? trade.tradeSize >= minTradeSizeCheck : true) && (maxTradeSizeCheck ? trade.tradeSize <= maxTradeSizeCheck : true));
        if (!tradeSizeCheck) {
            return false;
        };

        let parseMinVolumeDif = parseFloat(minVolumeDif);
        let parseMaxVolumeDif = parseFloat(maxVolumeDif);
        let volumeDifCheck = ((parseMinVolumeDif ? volumeDif >= parseMinVolumeDif : true) && (parseMaxVolumeDif ? volumeDif <= parseMaxVolumeDif : true));
        if (!volumeDifCheck) {
            return false;
        };

        let parseMinMarketCap = isNaN(parseFloat(minMarketCap)) ? 0 : parseFloat(minMarketCap);
        let parseMaxMarketCap = isNaN(parseFloat(maxMarketCap)) ? 0 : parseFloat(maxMarketCap);
        let marketCapCheck = ((parseMinMarketCap ? marketCap >= parseMinMarketCap : true) && (parseMaxMarketCap ? marketCap <= parseMaxMarketCap : true));
        if (!marketCapCheck) {
            return false;
        } else if (marketCapCheck && marketCap === "N/A") {
            return false;
        }

        let excludeCheck = excludeTickers.length > 0 ? !excludeTickers.includes(trade.tickerSymbol) : true;
        if (!excludeCheck) {
            return false;
        };
        let showCheck = onlyShowTickers.length > 0 ? onlyShowTickers.includes(trade.tickerSymbol) : true;
        if (!showCheck) {
            return false;
        };

        let parseMinDTE = parseInt(minDTE);
        let parseMaxDTE = parseInt(maxDTE);
        if (isNaN(parseMinDTE)) {
            parseMinDTE = 0;
        }
        if (isNaN(parseMaxDTE)) {
            parseMaxDTE = 9999;
        }

        let fullYearDate = yearPrefix + trade.expirationDate;
        let expirationDate = new Date(fullYearDate);

        let timeDiff = expirationDate - today;

        let daysTillExpiration = Math.ceil(timeDiff / secondsCalc);

        let expirationCheck = ((parseMinDTE ? daysTillExpiration >= parseMinDTE : true) && (parseMaxDTE ? daysTillExpiration <= parseMaxDTE : true));
        if (!expirationCheck) {
            return false;
        };
        let callsCheck = true;
        let putsCheck = true;
        if (!callsCheckBox && trade.optionType === "C") {
            return false;
        }
        if (!putsCheckBox && trade.optionType === "P") {
            return false;
        }

        if (!aaCheckBox && (trade.spreadExecution === "Ask" || trade.spreadExecution === "Above Ask")) {
            return false;
        }
        if (!bbCheckBox && (trade.spreadExecution === "Bid" || trade.spreadExecution === "Below Bid")) {
            return false;
        }
        if (!spCheckBox && (trade.spreadExecution === "Mid")) {
            return false;
        }
        if (!bullishCheckBox && trade.tickerSentiment === 1) {
            return false;
        }
        if (!bearishCheckBox && trade.tickerSentiment === -1) {
            return false;
        }
        if (!neutralCheckBox && trade.tickerSentiment === 0) {
            return false;
        }

        let tradesCheck = true;
        let sweepsCheck = true;
        if (!multilegCheckbox && trade.tradeType === "MULTI") {
            return false;
        }
        if (!splitsCheckBox && trade.tradeType === "SPLIT") {
            return false;
        }
        if (!sweepsCheckBox && trade.tradeType === "SWEEP") {
            return false;
        }
        if (!singlesCheckbox && trade.tradeType === "SINGLE") {
            return false;
        }
        if (!blocksCheckbox && trade.tradeType === "BLOCK") {
            return false;
        }
        let sectorCheck;

        if (!sectors.includes(sectorFilter) || sectorFilter === "ALL") {
            sectorCheck = true;
        } else {
            sectorCheck = (trade.sector === sectorFilter ? true : false)
        }
        return premiumCheck && OTMCheck && sectorCheck && excludeCheck && showCheck && expirationCheck && scoreCheck && callsCheck && putsCheck && tradesCheck && sweepsCheck && spotCheck && optionPriceCheck && volumeDifCheck && marketCapCheck;
    }

    const handleTickerSearchInputChange = (value) => {
        if (value === "") {
            onTickerSearchClear();
        } else {
            setSearchInputValue(value);
        }
    };

    const onTickerSearchEnter = () => {
        setOnlyShowTickers([searchInputValue]);
        setTickerForData(searchInputValue);
        setIsSearching(true);
    };

    const onTickerSearchClear = () => {
        setTickerForData("");
        setOnlyShowTickers([]);
        setIsSearching(false);
        setSearchInputValue("");
    };

    const handleSelectionChange = (newSelectedChips) => {
        setActiveTags(newSelectedChips);
    };

    const clearFilters = () => {
        setVolOverOI(false);
        setMinPremium(20000);
        setMaxPremium(500000000);
        setMinDTE(0);
        setMaxDTE(1200);
        setOnlyShowTickers([]);
        setExcludeTickers([]);
        setMinOTM(-3000);
        setMaxOTM(3000);
        setMinScore(0.0);
        setMaxScore(1.0);
        setMaxLast7DaysBFVolume(MAX_LAST_7_DAYS_BF_VOLUME);
        setMaxPrevDayBFVolume(MAX_PREV_DAY_BF_VOLUME);
        setMaxPreviousVolume(MAX_PREVIOUS_VOLUME);
        setMaxCurrentVolume(MAX_CURRENT_VOLUME);
        setMaxOI(MAX_OI);
        setMinSpot(0);
        setMaxSpot(5000);
        setMinOptionPrice(0);
        setMaxOptionPrice(100);
        setMinTradeSize(0);
        setMaxTradeSize(1000000);
        setMinVolumeDif("");
        setMaxVolumeDif("");
        setCallsCheckBox(true);
        setPutsCheckBox(true);
        setAACheckBox(true);
        setBBCheckBox(true);
        setBullishCheckBox(true);
        setBearishCheckBox(true);
        setNeutralCheckBox(true);
        setSpCheckBox(true);
        setSweepsCheckBox(true);
        setSplitsCheckBox(true);
        setMultilegCheckbox(true);
        setSinglesCheckBox(true);
        setBlocksCheckBox(true);
        setMinMarketCap(0);
        setMaxMarketCap(99999999999999);
        setSectorFilter("ALL");
        setActiveTags([]);
        onTickerSearchClear();
    };

    const handleSentimentBoxChange = (value, sentimentBox) => {
        if (sentimentBox === "bullishCheckBox") {
            setBullishCheckBox(value);
        } else if (sentimentBox === "bearishCheckBox") {
            setBearishCheckBox(value);
        } else if (sentimentBox === "neutralCheckBox") {
            setNeutralCheckBox(value);
        }
        addEventToFirestore("handleSentimentBoxChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleSentimentBoxChange', {
            'event_category': 'Button',
            'event_label': 'handleSentimentBoxChange',
            'value': 1
        });
    }

    const handleExecutionPriceBoxChange = (value, executionBox) => {
        if (executionBox === "aaCheckBox") {
            setAACheckBox(value);
        } else if (executionBox === "bbCheckBox") {
            setBBCheckBox(value);
        } else {
            setSpCheckBox(value);
        }
        addEventToFirestore("handleExecutionPriceBoxChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleExecutionPriceBoxChange', {
            'event_category': 'Button',
            'event_label': 'handleExecutionPriceBoxChange',
            'value': 1
        });
    }
    const handleTradeSweepChange = (value, tradeOrSweepBox) => {
        if (tradeOrSweepBox === "splitsCheckbox") {
            setSplitsCheckBox(value);
        } else if (tradeOrSweepBox === "blocksCheckbox") {
            setBlocksCheckBox(value);
        } else if (tradeOrSweepBox === "sweepsCheckbox") {
            setSweepsCheckBox(value);
        } else if (tradeOrSweepBox === "multiLegCheckbox") {
            setMultilegCheckbox(value);
        }

        addEventToFirestore("handleTradeSweepChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleTradeSweepChange', {
            'event_category': 'Button',
            'event_label': 'handleTradeSweepChange',
            'value': 1
        });
    }

    const handleFilterNameChange = (value) => {
        setFilterNameValue(value);
    };

    const handleAlertNameChange = (value) => {
        setAlertNameValue(value);
        addEventToFirestore("handleAlertNameChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleAlertNameChange', {
            'event_category': 'Button',
            'event_label': 'handleAlertNameChange',
            'value': 1
        });
    };


    const handleOTMFilterChange = (field, value) => {
        if (field === 'min OTM') {
            setMinOTM(value);
        } else if (field === 'max OTM') {
            setMaxOTM(value);
        } else {
            console.error("Filter error 76090");
        }
    }
    const handleScoreFilterChange = (field, value) => {
        if (field === 'min Score') {
            setMinScore(value);
        } else if (field === 'max Score') {
            setMaxScore(value);
        } else {
            console.error("Filter error 77155");
        }
    }

    const handleLast7DaysBFVolumeFilterChange = (value) => {
        setMaxLast7DaysBFVolume(value);
    }

    const handlePrevDayBFVolumeFilterChange = (value) => {
        setMaxPrevDayBFVolume(value);
    }

    const handlePreviousVolumeFilterChange = (value) => {
        setMaxPreviousVolume(value);
    }

    const handleCurrentVolumeFilterChange = (value) => {
        setMaxCurrentVolume(value);
    }
    const handleOIFilterChange = (value) => {
        setMaxOI(value);
    }

    const handleSpotFilterChange = (field, value) => {
        if (field === 'min spot') {
            setMinSpot(value);
        } else if (field === 'max spot') {
            setMaxSpot(value);
        } else {
            console.error("Filter error 70155");
        }
    }

    const handleOptionPriceFilterChange = (field, value) => {
        if (field === 'min option price') {
            setMinOptionPrice(value);
        } else if (field === 'max option price') {
            setMaxOptionPrice(value);
        } else {
            console.error("Filter error 70115");
        }
    }

    const handleTradeSizeFilterChange = (field, value) => {
        if (field === 'min trade size') {
            setMinTradeSize(value);
        } else if (field === 'max trade size') {
            setMaxTradeSize(value);
        } else {
            console.error("Filter error 202455");
        }
    }

    const handleMarketCapFilterChange = (field, value) => {
        if (field === 'min market cap') {
            setMinMarketCap(value);
        } else if (field === 'max market cap') {
            setMaxMarketCap(value);
        } else {
            console.error("Filter error 70199");
        }
    }

    const handleExpirationFilterChange = (field, value) => {
        if (field === 'min DTE') {
            setMinDTE(value);
        } else if (field === 'max DTE') {
            setMaxDTE(value);
        }
    };
    const handleCallPutChange = (value, callOrPutBox) => {
        if (callOrPutBox === "callsCheckbox") {
            setCallsCheckBox(value);
        } else {
            setPutsCheckBox(value);
        }
        addEventToFirestore("handleCallPutChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleCallPutChange', {
            'event_category': 'Button',
            'event_label': 'handleCallPutChange',
            'value': 1
        });
    };
    const handlePremiumFilterChange = (field, value) => {
        if (field === 'min') {
            setMinPremium(value);
        } else if (field === 'max') {
            setMaxPremium(value);
        } else {
            console.error("Error code 55312");
        }
    };
    const handleSectorDropDownChange = (value) => {
        setSectorFilter(value);
        addEventToFirestore("handleSectorDropDownChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleSectorDropDownChange', {
            'event_category': 'Button',
            'event_label': 'handleSectorDropDownChange',
            'value': 1
        });
    };

    const handleDropdownChange = (e) => {
        const value = e.target.value;
        const selectedItem = filterItems[value];
        if (selectedItem) {
            setMinPremium(selectedItem.minPremium);
            setMaxPremium(selectedItem.maxPremium);
            setMinDTE(selectedItem.minDTE);
            setMaxDTE(selectedItem.maxDTE);
            setOnlyShowTickers(selectedItem.onlyShowTickers);
            setExcludeTickers(selectedItem.excludeTickers);
            setMinOTM(selectedItem.minOTM);
            setMaxOTM(selectedItem.maxOTM);
            setMinScore(selectedItem.minScore);
            setMaxScore(selectedItem.maxScore);
            setMaxLast7DaysBFVolume(selectedItem.maxLast7DaysBFVolume);
            setMaxPrevDayBFVolume(selectedItem.maxPrevDayBFVolume);
            setMaxPreviousVolume(selectedItem.maxPreviousVolume);
            setMaxCurrentVolume(selectedItem.maxCurrentVolume);
            setMaxOI(selectedItem.maxOI);
            setCallsCheckBox(selectedItem.callsCheckBox);
            setPutsCheckBox(selectedItem.putsCheckBox);
            setAACheckBox(selectedItem.aaCheckBox);
            setBBCheckBox(selectedItem.bbCheckBox);
            setBullishCheckBox(selectedItem.bullishCheckBox);
            setBearishCheckBox(selectedItem.bearishCheckBox);
            setNeutralCheckBox(selectedItem.neutralCheckBox);
            setSpCheckBox(selectedItem.spCheckBox);
            setSweepsCheckBox(selectedItem.sweepsCheckBox);
            setSplitsCheckBox(selectedItem.splitsCheckBox);
            setBlocksCheckBox(selectedItem.blocksCheckbox);
            setMultilegCheckbox(selectedItem.multilegCheckbox);
            setSinglesCheckBox(selectedItem.singlesCheckbox);
            setMinSpot(selectedItem.minSpot);
            setMaxSpot(selectedItem.maxSpot);
            setSectorFilter(selectedItem.sector);
            setFilterSelectValue(value);
            setActiveTags(selectedItem.activeTags);
        } else {
            console.error(`No filter item found with key: ${value}`);
        }
        addEventToFirestore("handleFilterDropdownChange", user && user.email ? user.email : "");
        window.gtag('event', 'handleFilterDropdownChange', {
            'event_category': 'Button',
            'event_label': 'handleFilterDropdownChange',
            'value': 1
        });
    };
    const handleTickerFilterChange = (field, value) => {
        const valueWithCommas = value.replace(/\s+/g, ',');
        const tickerArray = valueWithCommas.toUpperCase().split(',').map(ticker => ticker.trim());
        if (field === 'exclude') {
            if (valueWithCommas.trim() === '') {
                setExcludeTickers([]);
            } else {
                setExcludeTickers(tickerArray);
            }
        } else if (field === 'onlyShow') {
            if (valueWithCommas.trim() === '') {
                setOnlyShowTickers([]);
            } else {
                setOnlyShowTickers(tickerArray);
            }
        }
    };

    const fetchFilters = async () => {
        try {
            if (user != null) {
                const querySnapshot = await getDocs(query(
                    collection(firestore, 'custom_filters'),
                    where('userEmail', '==', user && user.email ? user.email : ""))
                );
                let fetchedItems = {};
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    fetchedItems[data.filterName] = { ...data, id: doc.id };
                });
                setFilterItems(fetchedItems);
                const filterNames = Object.keys(fetchedItems);
                setFilterNames(filterNames);
            } else {
                console.error("User error 183801");
            }
        } catch (error) {
            console.error('Error code 61838: ', error);
        }
    };

    const deleteFilter = async (filterName) => {
        window.gtag('event', 'Delete filter', {
            'event_category': 'Button',
            'event_label': 'Delete filter',
            'value': 1
        });
        addEventToFirestore("Delete filter", user && user.email ? user.email : "");

        try {
            if (user != null) {
                const filterDocId = filterItems[filterName].id;

                if (!filterDocId) {
                    console.error('Document ID not found for the filter:', filterName);
                    return;
                }

                const filterDoc = doc(firestore, 'custom_filters', filterDocId);
                await deleteDoc(filterDoc);

                setFilterItems(prevItems => {
                    const updatedItems = { ...prevItems };
                    delete updatedItems[filterName];
                    return updatedItems;
                });

                setFilterNames(prevNames => prevNames.filter(name => name !== filterName));
                setSelectedItem('Filters');
                clearFilters();
                fetchFilters();
            } else {
                console.error("User error 567391");
            }
        } catch (error) {
            console.error('Error deleting filter: ', error);
        }
    };

    const handleAlertDelete = async (alertName) => {
        // Update the state without the deleted alert
        setAlertItems(currentAlertItems => {
            const { [alertName]: value, ...remainingAlerts } = currentAlertItems;
            return remainingAlerts;
        });
        const updatedAlertItems = { ...alertItemsRef.current };
        delete updatedAlertItems[alertName];
        alertItemsRef.current = updatedAlertItems;
    };

    const handleSave = async () => {
        addEventToFirestore("handleSave", user && user.email ? user.email : "");
        window.gtag('event', 'handleSave', {
            'event_category': 'Button',
            'event_label': 'handleSave',
            'value': 1
        });
        if (user != null) {
            const docData = {
                filterName: filterNameValue,
                userEmail: user.email,
                minPremium: minPremium,
                maxPremium: maxPremium,
                excludeTickers: excludeTickers,
                onlyShowTickers: onlyShowTickers,
                minDTE: minDTE,
                maxDTE: maxDTE,
                minOTM: minOTM,
                maxOTM: maxOTM,
                minScore: minScore,
                maxScore: maxScore,
                maxLast7DaysBFVolume: maxLast7DaysBFVolume,
                maxPrevDayBFVolume: maxPrevDayBFVolume,
                maxPreviousVolume: maxPreviousVolume,
                maxCurrentVolume: maxCurrentVolume,
                maxOI: maxOI,
                callsCheckBox: callsCheckBox,
                putsCheckBox: putsCheckBox,
                aaCheckBox: aaCheckBox,
                bbCheckBox: bbCheckBox,
                bullishCheckBox: bullishCheckBox,
                bearishCheckBox: bearishCheckBox,
                neutralCheckBox: neutralCheckBox,
                spCheckBox: spCheckBox,
                singlesCheckbox: singlesCheckbox,
                sweepsCheckBox: sweepsCheckBox,
                splitsCheckBox: splitsCheckBox,
                multilegCheckbox: multilegCheckbox,
                blocksCheckbox: blocksCheckbox,
                minSpot: minSpot,
                maxSpot: maxSpot,
                minOptionPrice: minOptionPrice,
                maxOptionPrice: maxOptionPrice,
                minTradeSize: minTradeSize,
                maxTradeSize: maxTradeSize,
                minVolumeDif: minVolumeDif,
                maxVolumeDif: maxVolumeDif,
                minMarketCap: minMarketCap,
                maxMarketCap: maxMarketCap,
                sector: sectorFilter,
                activeTags: activeTags,
                timestamp: serverTimestamp()
            };

            try {
                await addDoc(collection(firestore, 'custom_filters'), docData);
                toast('Filter saved successfully');
            } catch (error) {
                toast('Error saving filter');
                console.error('Error code 81723: ', error);
            }
            closeSaveDialog();
            fetchFilters();
        } else {
            auth.signOut();
            navigate('/');
        }
        setFilterNameValue("");
    };

    const saveAlert = async () => {
        addEventToFirestore(`Save alert ${alertNameValue}`, user && user.email ? user.email : "");
        window.gtag('event', 'Create alert button', {
            'event_category': 'Button',
            'event_label': 'Create alert',
            'value': 1
        });
        if (user != null) {
            const docData = {
                alertName: alertNameValue,
                userEmail: user.email,
                minPremium: minPremium,
                maxPremium: maxPremium,
                excludeTickers: excludeTickers,
                onlyShowTickers: onlyShowTickers,
                minDTE: minDTE,
                maxDTE: maxDTE,
                minOTM: minOTM,
                maxOTM: maxOTM,
                minScore: minScore,
                maxScore: maxScore,
                maxLast7DaysBFVolume: maxLast7DaysBFVolume,
                maxPrevDayBFVolume: maxPrevDayBFVolume,
                maxPreviousVolume: maxPreviousVolume,
                maxCurrentVolume: maxCurrentVolume,
                maxOI: maxOI,
                callsCheckBox: callsCheckBox,
                putsCheckBox: putsCheckBox,
                aaCheckBox: aaCheckBox,
                bbCheckBox: bbCheckBox,
                bullishCheckBox: bullishCheckBox,
                bearishCheckBox: bearishCheckBox,
                neutralCheckBox: neutralCheckBox,
                spCheckBox: spCheckBox,
                singlesCheckbox: singlesCheckbox,
                sweepsCheckBox: sweepsCheckBox,
                splitsCheckBox: splitsCheckBox,
                blocksCheckbox: blocksCheckbox,
                multilegCheckbox: multilegCheckbox,
                minSpot: minSpot,
                maxSpot: maxSpot,
                minOptionPrice: minOptionPrice,
                maxOptionPrice: maxOptionPrice,
                minTradeSize: minTradeSize,
                maxTradeSize: maxTradeSize,
                minVolumeDif: minVolumeDif,
                maxVolumeDif: maxVolumeDif,
                minMarketCap: minMarketCap,
                maxMarketCap: maxMarketCap,
                sector: sectorFilter,
                activeTags: activeTags,
                timestamp: serverTimestamp()
            };
            try {
                await addDoc(collection(firestore, '_custom_alerts'), docData);
            } catch (error) {
                console.error('Error code 81723: ', error);
            }
            closeSaveAlertDialog();
            toast('Alert saved successfully');
            fetchAlerts();
        } else {
            console.error("User error 274818");
        }
        setAlertNameValue("");
    }
    const closeTradeInfo = () => {
        setTradeInfoDialog(false);
    };
    const toggleSaveDialog = () => {
        setOpenSaveDialog(true);
    };
    const closeSaveDialog = () => {
        setOpenSaveDialog(false);
    };
    const toggleSaveAlertDialog = () => {
        setOpenSaveAlertDialog(true);
    };
    const toggleSetShowAlertsView = () => {
        setShowAlertsView(!showAlertsView);
    }
    const closeSaveAlertDialog = () => {
        setOpenSaveAlertDialog(false);
    };
    const closeSpotTradingView = () => {
        setOpenSpotTradingView(false);
    };
    const closeOptionPriceChart = () => {
        setOpenOptionPriceChart(false);
    };
    const closeAccountSettings = () => {
        setAccountSettingsDialog(false);
    };

    async function downloadCollectionsAsCSV() {
        const today = new Date();
        const thirtyMinAgo = (today.getTime() / 1000) - (30 * 60);
        setIsDownloadingCsv(true);
        let counter = parseInt(localStorage.getItem('downloadCSVCounter')) || 0;
        if (counter > 50) {
            console.log("Download limit reached.");
            setIsDownloadingCsv(false);
            return; // Stop the function if the limit is exceeded
        }

        localStorage.setItem('downloadCSVCounter', counter + 1);

        window.gtag('event', 'downloadCSV', {
            'event_category': 'Button',
            'event_label': 'downloadCSV',
            'value': 1
        });

        const fields = "Symbol, currentAsk, currentBid, currentSpread, currentVolume, dateOfTrade, eod_performance, exchangeName, expirationDate, impliedVolatility, marketCap, openInterest, optionType, otmPercentage, premium, premiumFormatted, previousVolume, score, sector, spotPrice, spreadExecution, strikePrice, tickerSymbol, tickerSentiment, timestamp, tradePrice, tradeSize, tradeType, unixTimestamp";
        const fieldsArray = fields.split(', ').map(item => item.trim());

        let allData = [];

        try {
            // Filter the trades first
            const filteredTrades = trades.filter(trade => checkTradeFilter(trade, today, secondsCalc, thirtyMinAgo));

            let data = filteredTrades.map(rowData => {
                // Populate eod_performance with "N/A" if it does not exist
                if (!rowData.hasOwnProperty('eod_performance')) {
                    rowData['eod_performance'] = 'N/A';
                }

                // Iterate over each key in the document and replace commas in its value
                Object.keys(rowData).forEach(key => {
                    if (typeof rowData[key] === 'string') {
                        rowData[key] = rowData[key].replace(/,/g, ' '); // Replace commas with a space
                    }
                });
                return rowData;
            });

            if (fieldsArray) {
                data = data.map(row => {
                    return fieldsArray.reduce((acc, key) => {
                        acc[key] = row[key] || 'N/A'; // Ensure all fields are populated
                        return acc;
                    }, {});
                });
            }

            allData = allData.concat(data);

            // Convert to CSV
            const csvContent = convertToCSV(allData);

            // Trigger CSV download
            triggerCSVDownload(csvContent, `downloaded_trades.csv`);
            setIsDownloadingCsv(false);

        } catch (error) {
            setIsDownloadingCsv(false);
            console.error("Error handling local data: ", error);
        }
    }


    const handleDateRangeChange = (dates, dateStrings) => {
        setTrades([]);
        if (!dates) {
            console.log('No dates selected');
            return;
        }
        const startDate = dates[0].toDate();
        const endDate = dates[1].toDate();

        const collectionNames = generateCollectionNames(startDate, endDate);
        setCollectionList(collectionNames);
        console.log('Generated Collection Names:', collectionNames);
    };

    const NoRowsTemplate = ({ loading, isFiltering }) => {

        let template = ``;

        if (isFiltering) {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              No trades matching the filters.
            </div>
          `;
        } else if (!isLoading) {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              No trades matching the filters.
            </div>
          `;
        } else {
            template = `
            <div style="padding: 10px; border-radius: 8px; background-color: #333; color: white; text-align: center;">
              Loading...
            </div>
          `;
        }

        return template;
    };
    const isPhoneSize = useMediaQuery({ query: '(max-width: 768px)' });

    if (isPhoneSize) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', background: '#333', borderRadius: '10px', margin: '20px' }}>
                <div style={{ textAlign: 'center' }}>
                    <img src={Bull} alt="Bullflow Logo" style={{ width: '50px', height: '50px', display: 'block', margin: '0 auto 20px' }} />
                    <p style={{ color: '#f0f0f0', fontSize: '1.2em', margin: '0' }}>
                        Bullflow mobile experience is in development. To experience the best of Bullflow, please use a desktop browser.
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row', background: '#1a1b20' }}>
                <Helmet>
                    <title>Historical Flow</title>
                    <meta name="description" content="Bullflow historical flow." />
                </Helmet>
                <Toaster />
                {/* Left nav column with logo and icons */}
                <Sidebar activeItem="historical-flow" />

                {/* Center column with Title row, details, Ag grid */}
                <div style={{ backgroundColor: '#1a1b20', flex: 1, marginRight: '20px', marginTop: '10px', marginLeft: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {
                        cancelAt !== null && (
                            <Alert
                                message={`Your subscription will expire in ${cancelAt} days. Keep your access to powerful real-time options flow by renewing you subscription! Enjoy 35% off your first month with code: RENEW35`}
                                type="warning"
                                showIcon
                                style={{ width: '100%', textAlign: 'center', marginBottom: '4px', marginTop: '4px' }}
                                closable
                                action={
                                    <AntButton
                                        style={{ backgroundColor: '#FFD700', color: '#000', marginRight: '10px' }}
                                        onClick={() => {
                                            window.gtag('event', 'Renew Subscription portal button', {
                                                'event_category': 'Button',
                                                'event_label': 'Renew Subscription portal button',
                                                'value': 1
                                            });
                                            addEventToFirestore("Renew Subscription portal button", user && user.email ? user.email : "");
                                            window.open(`https://billing.stripe.com/p/login/00g4jA2096o86FW288?prefilled_email=${user && user.email ? user.email : ""}`, '_blank');
                                        }} size="small" type="primary">
                                        Renew
                                    </AntButton>
                                }
                            />
                        )
                    }
                    {/* Top Bar */}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 0, padding: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', alignItems: 'center' }}>
                            <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', fontSize: '1.35em' }}>Historical Flow</h1>
                        </div>
                        <TickerSearchBox onChange={handleTickerSearchInputChange} onEnter={userHasSubscription ? onTickerSearchEnter : showSignupNotif} onClear={onTickerSearchClear} initialInputValue={searchInputValue} />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            {/* <FilterIconButton onClick={toggleDrawer} /> */}
                            <ResetIconButton onClick={clearFilters} />
                            <FilterIconButton onClick={userHasSubscription ? toggleDrawer : showSignupNotif} />
                        </div>
                    </div>
                    {/* Detail bar: */}
                    <div style={{
                        marginTop: '10px',
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        borderRadius: '10px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: '#000000',
                            borderRadius: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '24px 12px',
                        }}>
                            <RangePicker
                                style={{
                                    height: '100%',
                                    width: 450,
                                    backgroundColor: '#333'
                                }}
                                disabledDate={combinedDisabledDate}
                                onChange={handleDateRangeChange}
                            />
                            <button
                                onClick={downloadCollectionsAsCSV}
                                style={{
                                    marginLeft: '10px',
                                    backgroundColor: '#36363680',
                                    color: 'white',
                                    borderRadius: '24px',
                                    border: 'none',
                                    padding: '6px 12px',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                }}>
                                Download
                            </button>
                        </div>
                        <QuickFilterAndFavoritesComponent
                            isHistoricalFlow={true}
                            selectedChips={activeTags}
                            onSelectionChange={userHasSubscription ? handleSelectionChange : showSignupNotif}
                        />
                    </div>

                    {/* Grid */}
                    <div
                        style={gridStyle}
                        className={"ag-theme-quartz-dark"}
                    >
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={'columns'}
                            onGridReady={onGridReady}
                            getRowNodeId={gridOptions.getRowNodeId}
                            getRowId={gridOptions.getRowId}
                            headerHeight={25}
                            onRowClicked={onRowClicked}
                            ref={gridApiRef}
                            animateRows={false}
                            overlayNoRowsTemplate={NoRowsTemplate({ isLoading, isFiltering })}
                        />
                        {showRowDropdown && <DropdownMenu position={dropdownPosition} onClose={closeRowDropdown} />}
                    </div>
                </div>


                {/* Right Filter Drawer */}
                <div style={{
                    width: isFilterDrawerOpen ? '25%' : '0%', // Control width based on the drawer state
                    height: '100%',
                    background: '#15181d',
                    transition: 'width 0.3s ease',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', marginTop: '10px' }}>
                        <Tabs
                            aria-label="Little Menu"
                            classNames={{
                                tabList: 'bg-black',
                                cursor: 'bg-neutral-800',
                                tabContent: "group-data-[selected=true]:text-white"
                            }}
                        >
                            <Tab key="Filters" title="Filters">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px', padding: '0 10px' }}>
                                        <TextButton
                                            text="Save as Alert"
                                            onClick={() => {
                                                window.gtag('event', 'Save Alert button', {
                                                    'event_category': 'Button',
                                                    'event_label': 'Save Alert button',
                                                    'value': 1
                                                });
                                                addEventToFirestore(`Click toggle save dialog`, user && user.email ? user.email : "");
                                                toggleSaveAlertDialog();
                                            }}
                                            style={{
                                                fontSize: '.65em',
                                            }}
                                        />
                                        <TextButton
                                            text="Save as Filter"
                                            onClick={() => {
                                                window.gtag('event', 'Toggle Save Dialog button', {
                                                    'event_category': 'Button',
                                                    'event_label': 'Toggle Save Dialog',
                                                    'value': 1
                                                });
                                                addEventToFirestore(`Click toggle save dialog`, user && user.email ? user.email : "");
                                                toggleSaveDialog();
                                            }}
                                            style={{
                                                fontSize: '.65em',
                                            }}
                                        />
                                    </div>
                                </div>
                                <Select
                                    size="sm"
                                    color='default'
                                    label="Select a filter"
                                    placeholder='Select a filter'
                                    selectionMode='single'
                                    onChange={handleDropdownChange}
                                    width="90%"
                                >
                                    {filterNames.map((filter) => (
                                        <SelectItem key={filter} value={filter}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {filter}
                                                <span
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{ cursor: 'pointer', color: 'red', marginLeft: '20px' }}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </Select>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', height: '200vh', gap: '15px', marginTop: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%' }}>
                                        <CallPutCheckBoxes callsChecked={callsCheckBox} putsChecked={putsCheckBox} handleCallPutChange={handleCallPutChange} />
                                        <SentimentCheckboxes bullishChecked={bullishCheckBox} bearishChecked={bearishCheckBox} neutralChecked={neutralCheckBox} handleSentimentBoxChange={handleSentimentBoxChange} />
                                        <TradesSweepsCheckBoxes splitsChecked={splitsCheckBox} sweepsChecked={sweepsCheckBox} blocksChecked={blocksCheckbox} multilegChecked={multilegCheckbox} handleTradeSweepChange={handleTradeSweepChange} />
                                        <ExecutionPriceCheckboxes aaChecked={aaCheckBox} bbChecked={bbCheckBox} spChecked={spCheckBox} handleExecutionPriceBoxChange={handleExecutionPriceBoxChange} />
                                    </div>
                                    <TickerFilter onlyShowTickersProp={onlyShowTickers} excludeTickersProp={excludeTickers} handleFilterChange={handleTickerFilterChange} />
                                    <PremiumFilter minPremium={minPremium} maxPremium={maxPremium} handleFilterChange={handlePremiumFilterChange} />
                                    <ScoreFilterFields minScoreProp={minScore} maxScoreProp={maxScore} handleFilterChange={handleScoreFilterChange} />
                                    <ExpirationFilterFields minDTEProp={minDTE} maxDTEProp={maxDTE} handleFilterChange={handleExpirationFilterChange} />
                                    <OTMFilterFields minOTMProp={minOTM} maxOTMProp={maxOTM} handleFilterChange={handleOTMFilterChange} />
                                    <SpotPriceFields minSpotPriceProp={minSpot} maxSpotPriceProp={maxSpot} handleFilterChange={handleSpotFilterChange} />
                                    <OptionPriceFields minOptionPriceProp={minOptionPrice} maxOptionPriceProp={maxOptionPrice} handleFilterChange={handleOptionPriceFilterChange} />
                                    <TradeSizeFields minTradeSizeProp={minTradeSize} maxTradeSizeProp={maxTradeSize} handleFilterChange={handleTradeSizeFilterChange} />
                                    <MarketCapFields minMarketCapProp={minMarketCap} maxMarketCapProp={maxMarketCap} handleFilterChange={handleMarketCapFilterChange} />
                                    <Last7DaysBullflowVolume maxLast7DaysBFVolumeProp={maxLast7DaysBFVolume} handleFilterChange={handleLast7DaysBFVolumeFilterChange} />
                                    <PrevDayBullflowVolume maxLastPrevDayBFVolumeProp={maxPrevDayBFVolume} handleFilterChange={handlePrevDayBFVolumeFilterChange} />
                                    <PreviousVolumeFilter maxPreviousVolumeProp={maxPreviousVolume} handleFilterChange={handlePreviousVolumeFilterChange} />
                                    <CurrentVolumeFilter maxCurrentVolumeProp={maxCurrentVolume} handleFilterChange={handleCurrentVolumeFilterChange} />
                                    <OIFilter maxOIProp={maxOI} handleFilterChange={handleOIFilterChange} />

                                    {/* <Select
                                    defaultValue="Filter by Sector"
                                    style={{ width: 200, marginTop: '20px' }} // Changes the background and text color of the select element itself
                                    dropdownStyle={{ backgroundColor: 'black' }} // Changes the background color of dropdown list
                                    value={sectorFilter}
                                    onChange={handleSectorDropDownChange}
                                >
                                    {sectors.map((item, index) => (
                                        <Option value={item} key={index} style={{ color: "white" }}>{item}</Option>
                                    ))}
                                </Select> */}
                                </div>
                            </Tab>
                            <Tab key="Alerts" title="Alerts" style={{ width: '100%' }}>
                                <div>
                                    <AlertsListView email={user && user.email ? user.email : ""} deleteAlert={handleAlertDelete} />

                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <Modal
                    backdrop="opaque"
                    isOpen={openSaveDialog}
                    onOpenChange={setOpenSaveDialog}
                    radius="lg"
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
                        base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                >
                    <ModalContent>
                        <ModalHeader>Save Filter</ModalHeader>
                        <ModalBody>
                            <GenericSearchBox
                                initialInputValue={filterNameValue}
                                onChange={handleFilterNameChange}
                                placeholder="Custom Filter Name"
                                className="w-full"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={handleSave}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal
                    backdrop="opaque"
                    isOpen={openSaveAlertDialog}
                    onOpenChange={setOpenSaveAlertDialog}
                    radius="lg"
                    classNames={{
                        body: "py-6",
                        backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
                        base: "border-[#292f46] bg-[#111111] text-[#a8b0d3]",
                        header: "border-b-[1px] border-[#292f46]",
                        footer: "border-t-[1px] border-[#292f46]",
                        closeButton: "hover:bg-white/5 active:bg-white/10",
                    }}
                >
                    <ModalContent>
                        <ModalHeader>Save Alert</ModalHeader>
                        <ModalBody>
                            <GenericSearchBox
                                initialInputValue={alertNameValue}
                                onChange={handleAlertNameChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button className="bg-[#6f4ef2] shadow-lg shadow-indigo-500/20" onPress={saveAlert}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <MyDialog open={openSpotTradingView} onClose={closeSpotTradingView} aria-labelledby="Trading View" aria-describedby="trading-view">
                    <DialogContent style={{ background: '#1a1b20', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                        <TradingViewWidget symbol={tradingViewTicker} />
                    </DialogContent>
                </MyDialog>
                <MyDialog open={accountSettingsDialog} onClose={closeAccountSettings} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{ background: '#1a1b20', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                        <h2 style={{ color: 'white', margin: '0 0 0 0' }}>Welcome</h2>
                        {user && <p style={{ color: 'grey', margin: '0 0 10px 0', fontSize: '.75em' }}>{user && user.email ? user.email : ""}</p>}

                        <p style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
                            onClick={
                                () => {
                                    window.gtag('event', 'Suggest a request button', {
                                        'event_category': 'Button',
                                        'event_label': 'Suggest a request',
                                        'value': 1
                                    });
                                    addEventToFirestore("Click Suggest request", user && user.email ? user.email : "");
                                    window.location.href = "mailto:support@bullflow.io?subject=New Feature Suggestion";
                                }
                            }
                        >Submit a Feature Request</p>
                        <p style={{ color: 'grey', margin: '0 0 0px 0', fontWeight: 'normal', fontSize: '0.65em', cursor: 'pointer' }}
                            onClick={
                                () => {
                                    window.gtag('event', 'Suggest a request button', {
                                        'event_category': 'Button',
                                        'event_label': 'Suggest a request',
                                        'value': 1
                                    });
                                    addEventToFirestore("Click Suggest request", user && user.email ? user.email : "");
                                    window.location.href = "mailto:support@bullflow.io?subject=New Feature Suggestion";
                                }
                            }
                        >(Simple feature requests could be implemented in just a few days)</p>

                        <p style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
                            onClick={
                                () => {
                                    window.location.href = "mailto:support@bullflow.io";
                                }
                            }
                        >Contact</p>
                        <p style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}
                            onClick={
                                () => {
                                    window.gtag('event', 'Payment portal button', {
                                        'event_category': 'Button',
                                        'event_label': 'Payment portal',
                                        'value': 1
                                    });
                                    addEventToFirestore("Click Payment Portal", user && user.email ? user.email : "");
                                    window.open('https://billing.stripe.com/p/login/00g4jA2096o86FW288', '_blank');
                                }
                            }
                        >Payment Portal</p>
                        <p style={{ color: 'white', margin: '15px 0 0px 0', fontWeight: 'normal', fontSize: '0.85em', cursor: 'pointer' }}

                            onClick={
                                () => {
                                    window.gtag('event', 'Sign out button', {
                                        'event_category': 'Button',
                                        'event_label': 'Sign out',
                                        'value': 1
                                    });
                                    addEventToFirestore("Click Sign out", user && user.email ? user.email : "");
                                    auth.signOut()
                                        .then(() => {
                                            setUserHasSubscription(false);
                                            navigate('/');
                                        })
                                        .catch((error) => {
                                            console.error("User error 22200");
                                            setUserHasSubscription(false);
                                            navigate('/');
                                        });
                                }
                            } >Sign Out</p>
                    </DialogContent>
                </MyDialog>
                <MyDialog open={tradeInfoDialog} onClose={closeTradeInfo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{ background: '#1a1b20', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px', padding: '20px' }}>
                        <div background='rgb(34,26,54)' ref={myDivRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            <h2 style={{ color: 'white', margin: '0 0 20px 0' }}>{selectedTickerForInfo && convertOptionsSymbol(selectedTickerForInfo.Symbol)}</h2>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px', width: '100%', marginBottom: '20px' }}>
                                <div style={{ textAlign: 'left', color: 'white' }}>Bid, Ask:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && `${selectedTickerForInfo.currentBid}, ${selectedTickerForInfo.currentAsk}`}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Spread:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && (typeof selectedTickerForInfo.currentSpread === 'number' ? Number(selectedTickerForInfo.currentSpread.toFixed(2)) : 'N/A')}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Contracts:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && `${selectedTickerForInfo.tradeSize} @ ${selectedTickerForInfo.tradePrice}`}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Latest Contract Value:</div>
                                <div style={{ textAlign: 'right', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                                    {selectedTickerForInfo && contractCurrentValue}
                                    {selectedTickerForInfo &&
                                        <span style={{ color: parseFloat(contractPercentChange) >= 0 ? '#02d42c' : '#fa0043', marginLeft: '5px' }}>
                                            ({parseFloat(contractPercentChange) >= 0 ? '↑' : '↓'}{Math.abs(contractPercentChange)}%)
                                        </span>
                                    }
                                </div>


                                {/* <div style={{ textAlign: 'left', color: 'white' }}>Expiration:</div>
        <div style={{ textAlign: 'right', color: 'white', fontWeight: 'bold' }}>{selectedTickerForInfo && selectedTickerForInfo.expirationDate}</div> */}

                                <div style={{ textAlign: 'left', color: 'white' }}>Premium:</div>
                                <div style={{ textAlign: 'right', color: '#FFD700', textShadow: '0 0 10px #FFD700' }}>{selectedTickerForInfo && selectedTickerForInfo.premiumFormatted}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>OTM %:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.otmPercentage}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Open Interest:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.openInterest}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Implied Volatility:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.impliedVolatility}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Previous Volume:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.previousVolume}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Today's Volume:</div>
                                <div style={{ textAlign: 'right', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {selectedTickerForInfo && selectedTickerForInfo.currentVolume}
                                    {selectedTickerForInfo &&
                                        <span style={{ color: selectedTickerForInfo.currentVolume > selectedTickerForInfo.previousVolume ? '#02d42c' : '#fa0043', marginLeft: '5px' }}>
                                            ({selectedTickerForInfo.currentVolume > selectedTickerForInfo.previousVolume ? '↑' : '↓'}{selectedTickerForInfo.volumePercentageDif}%)
                                        </span>
                                    }
                                </div>


                                <div style={{ textAlign: 'left', color: 'white' }}>Market Cap:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && formatNumber(selectedTickerForInfo.marketCap)}</div>

                                <div style={{ textAlign: 'left', color: 'white' }}>Exchange:</div>
                                <div style={{ textAlign: 'right', color: 'white' }}>{selectedTickerForInfo && selectedTickerForInfo.exchangeName}</div>
                            </div>
                        </div>
                    </DialogContent>
                </MyDialog>
                <MyDialog open={openOptionPriceChart} onClose={closeOptionPriceChart} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent style={{
                        background: '#1a1b20',
                        display: 'flex',
                        height: 'auto',
                        width: 'auto',
                    }}>
                        <OptionPriceChart optionsTicker_={optionPriceChartTicker} />
                    </DialogContent>
                </MyDialog>
            </div >
        );
    }
};


async function fetchOptionsSpread(optionsTicker, oldSpread) {
    try {
        const url = `https://api.bullflow.io/v1/spreadPercentChange?Symbol=${optionsTicker}&spread=${oldSpread}`;
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            console.error('There was an issue retrieving the options spread.');

            analyticsLogError('There was an issue retrieving the options spread.');
            return null;
        }
    } catch (error) {
        analyticsLogError(`option spread error: ${error}.`);
        console.error('Error:', error);
        return null;
    }
}


export default HistoricalFlowDashboard;
