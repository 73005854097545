import React, { useState, useEffect } from 'react';
import { LightningBoltIcon, Crosshair2Icon, CounterClockwiseClockIcon } from "@radix-ui/react-icons";
import { SpotlightCard } from './SpotlightCard';
import { motion, AnimatePresence } from 'framer-motion';

const InfoCardsRow = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    const cardList = [{
        title: 'Real Time Flow',
        description: 'Our systems process millions of trades a day to bring you the most significant.',
        icon: <LightningBoltIcon className="h-8 w-8 text-neutral-400" />,
    }, {
        title: 'Unusual Analysis',
        description: 'Every trade goes through our Sigscore algorithm to help you find the most unusual trades with ease',
        description: 'The Bullflow Sigscore algorithm helps you find the most unusual trades with ease',
        icon: <Crosshair2Icon className="h-8 w-8 text-neutral-400" />,
    }, {
        title: 'Historical Flow',
        description: 'Dive into the past to see how trades have performed over time and find patterns',
        icon: <CounterClockwiseClockIcon className="h-8 w-8 text-neutral-400" />,
    }];

    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-around',
            alignItems: isMobile ? 'center' : 'stretch',
            width: '95%',
            margin: '0 auto',
            marginTop: '-95px',
            flexWrap: 'wrap',
        }}>
            <AnimatePresence>
                {cardList.map((card, index) => (
                    <div key={index} style={{ marginBottom: isMobile ? '20px' : '0' }}>
                        <motion.div
                            key={index}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.75, delay: index * 0.3 }}
                        >
                            <SpotlightCard
                                title={card.title}
                                description={card.description}
                                icon={card.icon}
                            />
                        </motion.div>
                    </div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export default InfoCardsRow;
