import { Search, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { sanitizeInput } from "../Utils";

interface GenericSearchBoxProps {
  onChange: (value: string) => void;
  initialInputValue?: string;
  placeholder?: string;
  hints?: string[];
}



export const GenericSearchBox = ({
  onChange,
  initialInputValue,
  placeholder = "Custom name",
  hints = [],
}: GenericSearchBoxProps) => {
  const divRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (initialInputValue !== undefined) {
      setInputValue(initialInputValue);
    }
  }, [initialInputValue]);

  const handleMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleClear = () => {
    setInputValue("");
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value;
    setInputValue(filteredValue);
    onChange(filteredValue);
  };

  return (
    <>
      <div className="relative w-60">
        <input
          onMouseMove={handleMouseMove}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onChange={handleChange}
          value={inputValue}
          onKeyDown={(e) => {}}
          autoComplete="off"
          placeholder={placeholder}
          type="text"
          name="search"
          className="h-8 w-full pl-3.5 pr-10 cursor-default rounded-md border border-black bg-neutral-950 text-sm text-slate-100 transition-colors duration-500 placeholder:select-none placeholder:text-neutral-500 placeholder:text-sm focus:border-[rgb(0_0_0)] focus:outline-none shadow-md shadow-black/30"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {inputValue.length >= 1 ? (
            <X size={15} strokeWidth={1} onClick={handleClear} />
          ) : (
            <Search size={15} strokeWidth={1} />
          )}
        </div>
        <input
          ref={divRef}
          disabled
          style={{
            border: "1px solid rgb(0 0 0)",
          }}
          aria-hidden="true"
          className="border-[rgb(0 0 0)] pointer-events-none absolute left-0 top-0 z-10 h-8 w-full cursor-default rounded-md border bg-[transparent] p-3.5 opacity-0 transition-opacity duration-500 placeholder:select-none"
        />
      </div>
    </>
  );
};