import React, { useState } from 'react';
import { CircleProgress } from 'react-gradient-progress';
import { useEffect } from 'react';
import { collection, query, orderBy, where, onSnapshot, limit } from "firebase/firestore";
import { firestore } from './firebase';
import getDateString from './Utils';
import formatMoney from './FormatMoney';
import { SearchOutlined } from '@ant-design/icons';
// import Tooltip from '@material-ui/core/Tooltip';

function getMostRecentWeekday() {
    let date = new Date();

    // Go back 1 day initially
    date.setDate(date.getDate() - 1);

    // Check if the new date is Sunday (0) or Saturday (6)
    if (date.getDay() === 0) {
        // If it's Sunday, go back 2 more days to Friday
        date.setDate(date.getDate() - 2);
    } else if (date.getDay() === 6) {
        // If it's Saturday, go back 1 more day to Friday
        date.setDate(date.getDate() - 1);
    }

    // Format the date in YYYY-MM-DD
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const TickerDataSection = ({ ticker, searchTicker, swapToOverallMarket, collectionName = `sentiment_tracking${getDateString()}` }, historicalFeeds = false) => {
    const [bullishFlow, setBullishFlow] = useState(0);
    const [bearishFlow, setBearishFlow] = useState(0);
    const [callFlow, setCallFlow] = useState(0);
    const [putFlow, setPutFlow] = useState(0);
    const [putOtmAverage, setPutOtmAverage] = useState(0.0);
    const [callOtmAverage, setCallOtmAverage] = useState(0.0);
    const [putPremiumTotal, setPutPremiumTotal] = useState(0);
    const [callPremiumTotal, setCallPremiumTotal] = useState(0);
    const [tickerPrice, setTickerPrice] = useState(0.0);
    const [tickerChangeFromPrev, setTickerChangeFromPrev] = useState(0.0);
    const [callsPercentage, setCallsPercentage] = useState(0.0);
    const [putsPercentage, setPutsPercentage] = useState(0.0);
    const [bullishOrBearish, setBullishOrBearish] = useState("Neutral");
    const [bearishSentimentPercent, setBearishSentimentPercent] = useState(0);
    const [bullishSentimentPercent, setBullishSentimentPercent] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [tickerText, setTickerText] = useState('');
    const [tickerForSearch, setTickerForSearch] = useState(ticker);

    const swapMarket = () => {
        swapToOverallMarket();
    }
    const handleTextChange = (e) => {
        setTickerText(e.target.value);
    };

    const toggleEdit = () => {
        if (isEditing && tickerText !== "") {
            console.log("Setting ticker for search: " + tickerText);
            setTickerForSearch(tickerText);
            searchTicker(tickerText);
        }
        setIsEditing(!isEditing);
    };

    const arrowStyle = {
        color: tickerChangeFromPrev >= 0.0 ? '#02d42c' : '#fa0043',
        fontSize: 12
    };

    useEffect(() => {
        const fetchData = async () => {
            // console.log("Ticker is " + tickerForSearch);
            // console.log(`Fetching ticker data`);
            try {
                // const response = await axios.get(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${ticker}&apikey=${API_KEY}`);
                // Send an HTTP POST request to the function
                const mostRecentWeekDay = getMostRecentWeekday();
                // console.log("MOST RECENT WEEKDAY: " + mostRecentWeekDay);
                const url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=${tickerForSearch}&date=${mostRecentWeekDay}`;
                // console.log("Stock data url " + url);
                const response = await fetch(url);

                if (!response.ok) {
                    console.log("HTTP error getting stock ticker price data");
                }

                // Parse the response
                const result = await response.json();
                // console.log("ticker data quote: " + result)
                const currentPrice = result.latestPrice;
                const previousClose = result.prevClose;
                // console.log("Latest price: " + currentPrice);
                // console.log("Prev close price: " + previousClose);
                const percentageChange = ((currentPrice - previousClose) / previousClose) * 100;
                // console.log(`percent change` + percentageChange);
                setTickerPrice(
                    isNaN(result.latestPrice) ? "N/A" : Number(result.latestPrice).toFixed(2)
                );
                setTickerChangeFromPrev(
                    isNaN(percentageChange) ? "N/A" : Number(percentageChange).toFixed(2)
                );

                // setPreviousClose(quote["08. previous close"]); // Uncomment if you need previous close price
            } catch (err) {
                // setError('Failed to fetch data');
                console.error("Ticker data error 189373673" + err);
            }
        };

        fetchData();

        // Optional: Set an interval to refresh the data regularly
        const intervalId = setInterval(fetchData, 60000); // Refresh every 60 seconds

        return () => clearInterval(intervalId);
    }, [ticker, tickerForSearch]);

    function determineMarketSentiment(bullishFlow, bearishFlow) {
        if (bullishFlow > bearishFlow) {
            return "Bullish";
        } else if (bullishFlow < bearishFlow) {
            return "Bearish";
        } else {
            return "Neutral";
        }
    }

    useEffect(() => {
        // console.log(`poiqwe callFlow: ${callFlow}, putFlow ${putFlow}`);
        // console.log(`poqiwe Bullishsentiment: ${bullishSentimentPercent}, Bearishsentiment ${bearishSentimentPercent}`);
        if (bullishSentimentPercent === bearishSentimentPercent) {
            setBullishOrBearish('Neutral');
        } else if (bearishSentimentPercent > bullishSentimentPercent) {
            setBullishOrBearish('Bearish');
        } else {
            setBullishOrBearish('Bullish');
        }
    }, [bullishSentimentPercent, bearishSentimentPercent]);

    useEffect(() => {
        let unsubscribe;

        const fetchTodaysTickerDoc = async () => {
            try {
                // const sentimentRef = collection(firestore, 'sentiment_tracking' + getDateString());
                // console.log(`TICKER DATA SECTION COLLECTION NAME: ${collectionName}`);
                const sentimentRef = collection(firestore, collectionName);
                // console.log('sentiment_tracking' + getDateString());
                const orderedTickerSentimentRef = query(
                    sentimentRef,
                    where("ticker", "==", tickerForSearch),
                    limit(1)
                );

                unsubscribe = onSnapshot(orderedTickerSentimentRef, (snapshot) => {
                    const data = snapshot.docs[0]?.data();
                    if (data) {
                        // console.log("Got ticker data");
                        const bearishFlow = data.bearishTradeCount;
                        const bullishFlow = data.bullishTradeCount;
                        const callFlow = data.callFlow;
                        const putFlow = data.putFlow;
                        const putOtmAverage = data.putFlow_average_otm_percent;
                        const callOtmAverage = data.callFlow_average_otm_percent;
                        const putPremiumTotal = data.putFlow_premium;
                        const callPremiumTotal = data.callFlow_premium;
                        
                        setCallFlow(callFlow || 0);
                        setPutFlow(putFlow || 0);
                        setBullishFlow(bullishFlow || 0);
                        setBearishFlow(bearishFlow || 0);
                        setPutPremiumTotal(putPremiumTotal || 0);
                        setCallPremiumTotal(callPremiumTotal || 0);
                        setPutOtmAverage(putOtmAverage || 0);
                        setCallOtmAverage(callOtmAverage || 0);
                        if (bullishFlow === 0) {
                            setBullishSentimentPercent(0);
                        } else {
                            setBullishSentimentPercent((bullishFlow / (bullishFlow + bearishFlow)) * 100);
                        }

                        if (bearishFlow === 0) {
                            setBearishSentimentPercent(0);
                        } else {
                            setBearishSentimentPercent((bearishFlow / (bullishFlow + bearishFlow)) * 100);
                        }
                        // console.log(`Bullish sentiment: ${bullishSentimentPercent}, Bearish Sentiment: ${bearishSentimentPercent}`);
                        if (bullishSentimentPercent < bearishSentimentPercent) {
                            setBullishOrBearish('Bearish');
                        } else if (bullishSentimentPercent > bearishSentimentPercent) {
                            setBullishOrBearish('Bullish');
                        } else {
                            setBullishOrBearish('Neutral');
                        }
                        setCallsPercentage(Number((callFlow / (callFlow + putFlow) * 100).toFixed(1)));
                        // console.log("Calls percentage: " + callsPercentage);
                        setPutsPercentage(Number((putFlow / (callFlow + putFlow) * 100).toFixed(1)));
                        // console.log("Puts percentage: " + putsPercentage);
                    } else {
                        console.log("fb error 27745");
                    }
                });
            } catch (err) {
                console.log("fb error 55767");
            }
        };
        fetchTodaysTickerDoc();

        return () => {
            if (unsubscribe) {
                // console.log("Unsubbing sentiment");
                unsubscribe();
            }
        }
    }, [ticker, tickerForSearch, collectionName]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'row',
            padding: '10px',
            height: historicalFeeds ? 'auto': '100vh'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'column',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}>
                {/* <p style={{ margin: 0, marginBottom: 0, fontWeight: 'bold', fontSize: 20 }}>{ticker}</p> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isEditing ? (
                        <input
                            type="text"
                            value={tickerText}
                            onBlur={toggleEdit}
                            autoFocus
                            onChange={(e) => {
                                const lettersOnly = e.target.value.replace(/[^a-zA-Z]/g, '');
                                handleTextChange({ target: { value: lettersOnly.toUpperCase() } });
                            }}
                            maxLength={5}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    toggleEdit();
                                }
                            }}
                            style={{
                                background: '#271f3d',
                                color: 'white', 
                                cursor: 'text', 
                                width: '70px', 
                                marginRight: '10px'
                            }}
                        />) : (
                        <p
                            style={{ cursor: 'pointer', margin: 0, marginBottom: 0, fontWeight: 'bold', fontSize: 20 }}
                            onClick={toggleEdit} // Adding the onClick handler here
                        >
                            {tickerForSearch}
                        </p>)}
                    <SearchOutlined
                        onClick={toggleEdit}
                        style={{
                            cursor: 'pointer',
                            fontSize: '11.5px', // Adjust this value as needed for size
                            color: 'gray', // Set the color to gray
                            marginLeft: "6px"
                        }}
                    />
                </div>

                <p style={{ marginLeft: 0, marginBottom: 0, marginTop: 5, fontWeight: 'bold', fontSize: 16 }}>
                    ${tickerPrice} <span style={arrowStyle}>{tickerChangeFromPrev >= 0.0 ? '↑' : '↓'}{tickerChangeFromPrev}%</span>
                </p>

                <span onClick={swapMarket} style={{ cursor: 'pointer' }}>
                    <p style={{ color: '#4573ff', fontWeight: 'light', fontSize: '0.5em' }}>Overall Market</p>
                </span>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}>
                <p style={{ margin: 0, marginBottom: 0, fontWeight: 'bold', fontSize: '0.85em' }}>Flow Sentiment</p>
                {/* <Tooltip title="The sentiment of the ticker as seen in the sentiment column"> */}
                    <CircleProgress
                        percentage={bullishFlow < bearishFlow ? bearishSentimentPercent : bullishSentimentPercent}
                        strokeWidth={6}
                        secondaryColor="#19192f"
                        width={60}
                        fontSize={10}
                        hidePercentageText
                        primaryColor={bullishFlow > bearishFlow ? ['#8cfc03', '#03fc2c'] : ['red', '#e60050']}
                        margin="0px"
                    />
                    <p style={{
                        color: bullishFlow > bearishFlow ? '#8cfc03' : '#e60050', fontWeight: 'bold', fontSize: '0.80em', margin: '0px'
                    }}>{determineMarketSentiment(bullishFlow, bearishFlow)}</p>
                {/* </Tooltip> */}
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}>
                {/* <p style={{ color: 'white', fontSize: '0.65em', marginTop: -5, fontWeight: 'bold' }}>{"Call Flow"}</p> */}
                <p style={{ margin: 0, marginBottom: 0, fontWeight: 'bold', fontSize: '0.85em' }}>Call Flow</p>

                <CircleProgress
                    percentage={callsPercentage}
                    strokeWidth={4}
                    secondaryColor="#19192f"
                    width={40}
                    fontSize={10}
                    hidePercentageText
                    primaryColor={['#8cfc03', '#03fc2c']}
                    margin="0px"
                />
                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', margin: '0px',
                    textShadow: '0 0 0px #02c278, 0 0 0px #02c278, 0 0 0px #02c278, 0 0 10px #02c278'
                }}>Calls: {callFlow}</p>


                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', marginTop: '5px', marginBottom: '0px'
                }}>OTM Average: {callOtmAverage}%</p>

                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', marginTop: '5px'
                }}>Premium Total: ${formatMoney(callPremiumTotal)}</p>
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}>
                {/* <p style={{ color: 'white', fontSize: '0.65em', marginTop: -5, fontWeight: 'bold' }}>{"Call Flow"}</p> */}
                <p style={{ margin: 0, marginBottom: 0, fontWeight: 'bold', fontSize: '0.85em' }}>Put Flow</p>

                <CircleProgress
                    percentage={putsPercentage}
                    strokeWidth={4}
                    secondaryColor="#19192f"
                    width={40}
                    fontSize={10}
                    hidePercentageText
                    primaryColor={false ? ['green', '#02c278'] : ['red', '#e60050']}
                    margin="0px"
                />
                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', margin: '0px',
                    textShadow: '0 0 0px #e60050, 0 0 0px #e60050, 0 0 10px #e60050, 0 0 10px #e60050'
                }}>Puts: {putFlow}</p>
                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', marginTop: '5px', marginBottom: '0px'
                }}>OTM Average: {putOtmAverage}%</p>

                <p style={{
                    color: 'white', fontWeight: 'bold', fontSize: '0.75em', marginTop: '5px'
                }}>Premium Total: ${formatMoney(putPremiumTotal)}</p>
            </div>

        </div>
    );
};

export default TickerDataSection;
