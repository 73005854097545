import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firestore } from './firebase';

const addEventToFirestore = async (eventName, email = 'N/A') => {
    try {
        const docRef = await addDoc(collection(firestore, "user_events"), {
            name: eventName,
            userEmail: email,
            timestamp: serverTimestamp(),
        });

        // console.log("Document written with ID: ", docRef.id);
    } catch (error) {
        console.error("Error adding document: ", error);
    }
};
export default addEventToFirestore;
