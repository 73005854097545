import { useState, useEffect } from 'react';
import { Slider } from 'antd';

function MarketCapFields({ minMarketCapProp, maxMarketCapProp, handleFilterChange }) {
    const [minMarketCap, setMinMarketCap] = useState(minMarketCapProp);
    const [maxMarketCap, setMaxMarketCap] = useState(maxMarketCapProp);
    const handleChange = (values) => {
        const newMaxMarketCap = values[1] === 1000000000000 ? 99999999999999 : values[1];
        setMinMarketCap(values[0]);
        setMaxMarketCap(newMaxMarketCap);
        handleFilterChange('min market cap', values[0]);
        handleFilterChange('max market cap', newMaxMarketCap);
    };

    useEffect(() => {
        setMinMarketCap(minMarketCapProp);
    }, [minMarketCapProp]);

    useEffect(() => {
        setMaxMarketCap(maxMarketCapProp);
    }, [maxMarketCapProp]);

    const formatTooltip = (value) => {
        if (value === 1000000000000) {
          return 'Infinite';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
    

    return (
        <div style={{ width: '90%', marginBottom: '0px' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    fontWeight: 600,
                    fontSize: '0.8em',
                }}
            >
                <p style={{ marginBottom: '0px' }}>Market Cap</p>
            </div>
            <div style={{ marginTop: '0px', marginBottom: '0px' }}>
                <Slider
                    range
                    min={0}
                    max={1000000000000}
                    value={[minMarketCap, maxMarketCap === 99999999999999 ? 1000000000000 : maxMarketCap]}
                    onChange={handleChange}
                    tipFormatter={formatTooltip}
                    style={{ color: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '0px',
                    marginTop: '0px',
                }}
            >
                <p
                    style={{
                        fontSize: '0.7em',
                        fontWeight: 600,
                        color: 'GrayText',
                        marginTop: '0px',
                        marginBottom: '0px',
                    }}
                >
                    0
                </p>
                <p
                    style={{
                        fontSize: '0.7em',
                        fontWeight: 600,
                        color: 'GrayText',
                        marginTop: '0px',
                        marginBottom: '0px',
                    }}
                >
                    ∞
                </p>
            </div>
        </div>
    );
}

export default MarketCapFields;
