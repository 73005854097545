import React, { useState } from 'react';
import WideBorderMagicButton from '../LandingPageComponents/WideBorderMagicButton';
import { firestore } from '../firebase';
import { collection, addDoc, query, getDocs, where } from "firebase/firestore";

const APIEmailCollectionComponent = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      // Check if email already exists
      const q = query(collection(firestore, "_API_WAITLIST"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMessage('This email is already on the waitlist.');
        return;
      }

      // Add email to _API_WAITLIST collection
      await addDoc(collection(firestore, "_API_WAITLIST"), {
        email: email,
        timestamp: new Date()
      });

      setMessage('Thank you! We\'ll contact you when the API is in beta testing.');
      setEmail('');
    } catch (error) {
      console.error("Error adding email to waitlist: ", error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen w-screen flex flex-col items-center bg-black pt-10">
      <div className="w-full max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="w-full p-6 bg-black rounded-lg shadow-md border border-gray-800">
          <h1 className="text-2xl font-bold mb-2 text-center" style={{
            background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent'
          }}>
            Unusual Options Flow API - $99/month
          </h1>
          <p className="text-gray-400 mb-6 text-center">Gain access to real-time unusual options trades via the Bullflow websocket feed and start working on your next big idea.</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-4 py-2 bg-gray-900 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <WideBorderMagicButton text="Join Waitlist" onClick={handleSubmit} fillParentWidth={true}/>
          </form>
          {message && <p className="mt-4 text-center text-sm text-gray-400">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default APIEmailCollectionComponent;