import { useState, useEffect } from 'react';
import { Slider } from 'antd';
import './antdesignslider.css';
function PremiumFilter({ handleFilterChange, minPremium, maxPremium }) {
  const firstSegmentValue = 5000000; // End of the first segment
  const minScrollValue = 9999;
  const maxScrollValue = 300000000;
  const firstSegmentPoint = 850; // 75% for the first segment, scaled to 1000
  const [value, setValue] = useState([minPremium, maxPremium]);

  useEffect(() => {
    if (value[0] !== minPremium || value[1] !== maxPremium) {
      setValue([minPremium, maxPremium]);
    }
  }, [minPremium, maxPremium, value]);

  const mapSliderValueToRange = (sliderValue) => {
    if (sliderValue <= firstSegmentPoint) {
      return minScrollValue + (firstSegmentValue - minScrollValue) * (sliderValue / firstSegmentPoint);
    } else {
      return firstSegmentValue + (maxScrollValue - firstSegmentValue) * ((sliderValue - firstSegmentPoint) / (1000 - firstSegmentPoint));
    }
  };

  const mapValueToSlider = (val) => {
    if (val <= firstSegmentValue) {
      return firstSegmentPoint * (val - minScrollValue) / (firstSegmentValue - minScrollValue);
    } else {
      return firstSegmentPoint + (1000 - firstSegmentPoint) * (val - firstSegmentValue) / (maxScrollValue - firstSegmentValue);
    }
  };

  const onSliderChange = (sliderValue) => {
    const mappedValue = sliderValue.map(mapSliderValueToRange);
    setValue(mappedValue);
    handleFilterChange('min', mappedValue[0]);
    handleFilterChange('max', mappedValue[1]);
  };

  const tipFormatter = (sliderValue) => {
    const actualValue = Math.round(mapSliderValueToRange(sliderValue));
    return `$${actualValue.toLocaleString()}`;
  };

  const sliderValue = value.map(mapValueToSlider);

  return (
    <div style={{ width: '90%', marginBottom: '0px' }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 600,
        fontSize: '0.8em',
      }}>
        <p style={{ marginBottom: '0px' }}>Filter by Value</p>
      </div>
      <div style={{ marginTop: '0px' }}>
        <Slider
          range
          min={0} // Set your minimum value
          max={1000} // Set your maximum value
          // step={5000}
          value={sliderValue}
          onChange={onSliderChange}
          tooltip={{
            formatter: tipFormatter,
            visible: true,
            zIndex: 15001 
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between', marginBottom: '0px'
      }}>
        <p style={{ fontSize: '0.7em', fontWeight: 600, color: 'GrayText', marginTop: '0px', marginBottom: '0px' }}>$9,999</p>
        <p style={{ fontSize: '0.7em', fontWeight: 600, color: 'GrayText', marginTop: '0px', marginBottom: '0px' }}>$500,000,000</p>
      </div>
    </div>
  );

}

export default PremiumFilter;
