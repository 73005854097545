import { formatMoneyNumber, formatNumberWithCommas, optionsSymbolToJustTicker } from "../Utils";

const VolumeSpikeMeter = ({
  gradientLength = 0.5,
  symbol = "TSLA 350C 10/15/24",
  putsOrCalls = "calls",
  thirtyDayAvg = 1000,
  currentVolume = 1800,
  percentChange = 10000,
  formattedTicker = "SPY"
}) => {
  const gradientColor = putsOrCalls === 'calls' ? 'rgba(75, 183, 144, 0.5)' : 'rgba(204, 55, 83, 0.5)';
  const fadeEndPoint = gradientLength * 100;
  const tickerColor = putsOrCalls === 'calls' ? '#20d46b' : '#cc3753';
  // const formattedVolume = formatMoneyNumber(currentVolume);
  const formattedThirtyDayAvg = formatMoneyNumber(thirtyDayAvg);

  const handleTickerClick = () => {
    window.open(`http://bullflow.io/flow?ticker=${formattedTicker}`, '_blank');
  };
  return (
    <div style={{
      backgroundColor: 'black', borderRadius: '10px',
      padding: '0px', position: 'relative', cursor:'pointer'
    }}
      onClick={handleTickerClick}>
      <div
        style={{
          width: '100%',
          height: '30px',
          borderRadius: '5px',
          backgroundImage: `linear-gradient(to left, ${gradientColor} 0%, ${gradientColor.replace(
            '0.5',
            '0'
          )} ${fadeEndPoint}%)`,
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: '4px',
          fontWeight: 550,
          color: tickerColor,
          fontSize: '12px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        +{formatNumberWithCommas(percentChange)}%
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '4px',
          color: '#A9A9A9',
          fontSize: '12px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {symbol}
      </div>
    </div>
  );
};

export default VolumeSpikeMeter;