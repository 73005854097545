import { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from './firebase';
import { Spinner } from '@nextui-org/react';



const AlertsListView = ({ email, deleteAlert }) => {
    const [alerts, setAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state
    const [error, setError] = useState(null); // Add state for error

    useEffect(() => {
        const q = query(collection(firestore, '_custom_alerts'), where('userEmail', '==', email));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedAlerts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                isDeleting: false
            }));
            setAlerts(fetchedAlerts);
            setIsLoading(false);
        }, (error) => {
            // Handle any errors here
            setError(error.message); // Set the error message
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, [email]); // Include email in dependency array

    const toggleDeleteState = (id) => {
        setAlerts(alerts.map(alert =>
            alert.id === id ? { ...alert, isDeleting: !alert.isDeleting } : alert
        ));
    };

    const handleDeleteAlert = async (id, alertName) => {
        await deleteDoc(doc(firestore, '_custom_alerts', id));
        deleteAlert(alertName);
    };


    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spinner />
                </div>
            ) : error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    {error}
                </div>
            ) : (
                alerts.map(alert => (
                    <div key={alert.id} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '10px',
                        margin: '5px 0',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                        transition: 'transform 0.3s ease',
                        backgroundColor: '#000',
                    }}>
                        <div style={{ flex: 1 }}>{alert.alertName}</div>
                        <div onClick={() => toggleDeleteState(alert.id)} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                            {alert.isDeleting ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cc3753" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#cc3753" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                            }
                        </div>
                        {alert.isDeleting && (
                            <div onClick={() => handleDeleteAlert(alert.id, alert.alertName)} style={{ cursor: 'pointer', marginLeft: '10px', color:'#cc3753' }}>
                                Confirm Delete
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default AlertsListView;
